<ng-container *ngIf="routeStation; else noActiveRouteStation">
    <div class="mt-3">
        <div class="card shadow rounded-0 p-3">
            <avs-bilderkatalog [title]="'Bilder'" [images]="unassignedImages"
                (myClick)="onUnassignedProjectImageClicked($event)"></avs-bilderkatalog>
        </div>
    </div>
    <div class="mt-3">
        <div class="card shadow rounded-0 p-3">
            <avs-bilderkatalog [title]="'Gesetzte Bilder'" [images]="stationImages"
                (myClick)="onProjectImageClicked($event)"
                [methods]="[{name: 'onGenerateAnimatedImageClicked', src: 'assets/icons/images.svg'}]"
                (genericMethod)="genericMethod($event)"></avs-bilderkatalog>
        </div>
    </div>
</ng-container>
<ng-template #noActiveRouteStation>
    <p class="mt-3 fw-semibold text-secondary">Keine Station ausgewählt!</p>
</ng-template>