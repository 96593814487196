import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BrennstoffZelle } from 'src/app/device/shared/interfaces/brennstoff-zelle';
import { DeviceService } from 'src/app/device/shared/services/device.service';
import { FuelcellService } from 'src/app/project/shared/services/fuelcell.service';
import { BrennstoffzellenDetails } from 'src/app/project/shared/interfaces/brennstoffzellen-details';
import { getFuellCellSystemStateString } from 'src/app/project/shared/enums/fuell-cell-system-state.enum';
interface Systemstatus {
  value: number;
  asText: string;
}

interface Patronen {
  value: number;
  asText: string;
}
@Component({
  selector: 'avs-brennstoffzellen-details',
  templateUrl: './brennstoffzellen-details.component.html',
  styleUrls: ['./brennstoffzellen-details.component.css']
})
export class BrennstoffzellenDetailsComponent {
  public samId?: number;
  public isEditActive = false;
  public detailObj?: BrennstoffZelle;
  public details?: BrennstoffzellenDetails;
  public selectedStatus: number = 0;
  public selectedPatrone: number = 0;
  /*   public patronenNo: number = 0; */
  public systemstatus: Systemstatus[] = [
    { value: 1, asText: 'Transportlock entfernen' },
    { value: 2, asText: 'Laden' },
    { value: 3, asText: 'Ausschalten' },
    { value: 4, asText: 'Automatik' },
    { value: 255, asText: 'Reset' }]
  public fuellgradPatrone: number = 100;
  protected readonly getFuellCellSystemStateString = getFuellCellSystemStateString;

  public patronenNo: Patronen[] = [
    { value: 1, asText: 'Patrone 1' },
    { value: 2, asText: 'Patrone 2' }
  ]

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private deviceService: DeviceService,
    private dialogRef: MatDialogRef<BrennstoffzellenDetailsComponent>,
    private fuellcellService: FuelcellService
  ) { }

  public ngOnInit(): void {
    this.samId = this.data.data.routeStation.samId;
    if (this.samId) {
      this.deviceService.getDeviceParameter(this.samId).subscribe(element => {
        this.detailObj = element.brennstoffZelle;
      })
    }
    this.deviceService.getFuellCellDetails(this.samId!).subscribe((response) => {
      this.details = response;
    })
  }

  public onEditClicked(): void {
    this.isEditActive = !this.isEditActive;
  }

  public getSystemzustandString(systemzustand: number): string {
    if (systemzustand === 0) {
      return 'Ok';
    }
    if (systemzustand === 1) {
      return 'Warnung';
    }
    if (systemzustand === 2) {
      return 'Fehler';
    }
    else return 'k.A.'
  }
  
  public getArbeitsmodusString(arbeitsmodus: number): string {
    if (arbeitsmodus === 0) {
      return 'Automatik';
    }
    if (arbeitsmodus === 1) {
      return 'Aus';
    }
    else return 'k.A.'
  }
  public onSaveStatusClicked(): void {

    this.fuellcellService.setSystemStatus(this.selectedStatus, 32, this.samId!).subscribe(
      (response) => {
        this.isEditActive = false;
        this.dialogRef.close();
      }

    );
  }

  public onSaveFuellClicked(): void {

    this.fuellcellService.setFillingLevel(this.fuellgradPatrone, this.samId!, 32, this.selectedPatrone).subscribe((response) => {
      this.isEditActive = false;
      this.dialogRef.close();
    })
  }


  lastSunday(year: number, month: number) {
    let date = new Date(year, month, 1, 12);
    let weekday = date.getDay();
    let dayDiff = weekday === 0 ? 7 : weekday;
    let lastSunday = date.setDate(date.getDate() - dayDiff);

    return date;
  }

  isCEST(d: Date): boolean {
    let testD = new Date(d);
    let year = testD.getFullYear();
    let startCET: Date = this.lastSunday(year, 3);
    let endCET: Date = this.lastSunday(year, 10);
    return !(startCET < testD && testD > endCET);
  }

  offset(d: Date): string {
    return this.isCEST(d) ? 'UTC+2' : 'UTC+1'
  }


  public onCloseClicked(): void {
    this.dialogRef.close();
  }
}