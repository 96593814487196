<table mat-table [dataSource]="dataSource!" matSort class="mat-elevation-z8">

  <ng-container matColumnDef="metaName">
    <th mat-header-cell *matHeaderCellDef>Standort </th>
    <td mat-cell *matCellDef="let element"> {{element.metaName}} </td>
  </ng-container>

  <ng-container matColumnDef="deviceName">
    <th mat-header-cell *matHeaderCellDef> SST </th>
    <td mat-cell *matCellDef="let element"> {{element.deviceName}} </td>
  </ng-container>
  <ng-container matColumnDef="timestampFrom">
    <th mat-header-cell *matHeaderCellDef> Zeit (von) </th>
    <td mat-cell *matCellDef="let element"> {{element.timestampFrom | date: 'dd.MM.yyyy - H:mm':
      offset(element.timestampFrom) }} </td>
  </ng-container>


  <ng-container matColumnDef="deciderName">
    <th mat-header-cell *matHeaderCellDef> Auslöser </th>
    <td mat-cell *matCellDef="let element"> {{element.deciderName}} </td>
  </ng-container>

  <ng-container matColumnDef="stellcode">
    <th mat-header-cell *matHeaderCellDef> Stellcode </th>
    <td mat-cell *matCellDef="let element"> {{element.stellcode }} | {{ element.ledIndicator	}} </td>
  </ng-container>

  <ng-container matColumnDef="brightness">
    <th mat-header-cell *matHeaderCellDef> Helligkeit </th>
    <td mat-cell *matCellDef="let element"> {{element.brightness}} </td>
  </ng-container>

  <ng-container matColumnDef="blinkerIndicator">
    <th mat-header-cell *matHeaderCellDef> Blinker </th>
    <td mat-cell *matCellDef="let element"> {{element.blinkerIndicator}} </td>
  </ng-container>

  <ng-container matColumnDef="batteryVoltage">
    <th mat-header-cell *matHeaderCellDef> Akkuspannung </th>
    <td mat-cell *matCellDef="let element"> {{element.batteryVoltage }} </td>
  </ng-container>
  <ng-container matColumnDef="timestampTo">
    <th mat-header-cell *matHeaderCellDef> Zeit (bis) </th>
    <td mat-cell *matCellDef="let element"> {{element.timestampTo | date: 'dd.MM.yyyy - H:mm':
      offset(element.timestampTo) }} </td>
  </ng-container>

  <ng-container matColumnDef="duration">
    <th mat-header-cell *matHeaderCellDef> Dauer </th>
    <td mat-cell *matCellDef="let element"> {{element.duration}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

