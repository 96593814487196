import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ChangelogMqReport } from '../../interfaces/changelog-mq-report.interface';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ReportService } from 'src/app/dashboard/shared/services/report.service';
import { ChangelogMqFilter } from '../../interfaces/changelog-mq-filter.interface';
import { PDFDocument, StandardFonts, rgb, degrees } from 'pdf-lib'
/* import * as fontkit from '@btielen/pdf-lib-fontkit'; */
import fontkit from '@pdf-lib/fontkit';
import * as fs from 'fs';


@Component({
  selector: 'avs-changelog-mq-report',
  templateUrl: './changelog-mq-report.component.html',
  styleUrls: ['./changelog-mq-report.component.css']
})
export class ChangelogMqReportComponent {
  displayedColumns: string[] = ['measuredAt', 'pkwTrafficVolume', 'lkwTrafficVolume', 'kfzTrafficVolume', 'pkwAverageSpeed', 'lkwAverageSpeed', 'actions'];
  dataSource?: MatTableDataSource<ChangelogMqReport>;
  base64String: string = '';
  @Input() mqReports: ChangelogMqReport[] = []; /* ggf doch schon die collection?  */
  @Input() samId: number = 0;
  @Input() index: number = 0;
  @Input() projectId: number = 0;
  @Input() fromDate: string = '';
  @Input() toDate: string = '';
  @Input() jpgUrl: string = '';

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  @Output() generatedPDFURL = new EventEmitter<string>();


  constructor(private reportService: ReportService) { }
  public ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.mqReports);
    if (this.paginator)
      this.dataSource!.paginator = this.paginator;
    if (this.sort)
      this.dataSource!.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource!.filter = filterValue.trim().toLowerCase();

    if (this.dataSource!.paginator) {
      this.dataSource!.paginator.firstPage();
    }
  }


  lastSunday(year: number, month: number) {
    let date = new Date(year, month, 1, 12);
    let weekday = date.getDay();
    let dayDiff = weekday === 0 ? 7 : weekday;
    let lastSunday = date.setDate(date.getDate() - dayDiff);

    return date;
  }

  isCEST(d: Date): boolean {
    let testD = new Date(d);
    let year = testD.getFullYear();
    let startCET: Date = this.lastSunday(year, 3);
    let endCET: Date = this.lastSunday(year, 10);
    return !(startCET < testD && testD > endCET);
  }

  offset(d: Date): string {
    return this.isCEST(d) ? 'UTC+2' : 'UTC+1'
  }

  public downloadMQReport(type: string) {
    let dateFromDate = new Date(this.fromDate);
    let dateToDate = new Date(this.toDate);
    let filter: ChangelogMqFilter = {
      id: this.samId,
      dateFrom: this.fromDate,
      dateTo: this.toDate,
      minutes: 60,
      deKanal: this.index + 1,
      projectId: this.projectId,
      filter: type
    };
    this.startDownload(filter);
  }

  compinedPDF() {
    let filter: ChangelogMqFilter = {
      id: this.samId,
      dateFrom: this.fromDate,
      dateTo: this.toDate,
      minutes: 60,
      deKanal: this.index + 1,
      projectId: this.projectId,
      filter: 'pdf'
    };
    this.reportService.getRouteStationsSpeedWithFilterDownloadNew(filter.id, filter.dateFrom, filter.dateTo, filter.minutes, filter.deKanal, filter.filter!, filter.projectId).subscribe((data) => {
      const blob = new Blob([data], { type: data.type });
      const url = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      let base64String = this.blobToBase64(blob).then((base64String) => {
        this.base64String = base64String
        this.mergePdf(this.base64String, this.jpgUrl)
      });
    });
  }


  /*  */

  private async mergePdf(pdfUrl: string, jpgUrl: string) {
    const url = window.location.origin
    const fontUrl = url + '/assets/fonts/arial.ttf';



    const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer())

    const pdfDocForIMG = await PDFDocument.create();

    pdfDocForIMG.registerFontkit(fontkit)
    const logoUrl = url + '/assets/logo/avs-logo.png';

    const arial = await pdfDocForIMG.embedFont(fontBytes)
    const existingPdfBytes = await fetch(logoUrl).then(res => res.arrayBuffer());
    const logoImage = await pdfDocForIMG.embedPng(existingPdfBytes)
    const pngImage = await pdfDocForIMG.embedPng(this.jpgUrl)
    const page = pdfDocForIMG.addPage()
    page.setRotation(degrees(90))
    const { width, height } = page.getSize()
    const fontSize = 6

    /* left side */
    page.drawText('AVS Verkehrssicherung GmbH', {
      rotate: degrees(90),
      x: 30,
      y: 40,
      size: fontSize,
      font: arial,
      color: rgb(0, 0, 0),
    })

    page.drawText('Niederlassung Senden', {

      rotate: degrees(90),
      x: 38,
      y: 40,
      size: fontSize,
      font: arial,
      color: rgb(0, 0, 0),
    })

    page.drawText('Daimlerstraße 37 - 48308 Senden', {
      rotate: degrees(90),
      x: 46,
      y: 40,
      size: fontSize,
      font: arial,
      color: rgb(0, 0, 0),
    })
    let samID = this.samId
    /* right side */
    page.drawText(`Bericht für den Messquerschnitt mit der ID: ${samID}`, {
      rotate: degrees(90),
      x: 30,
      y: height - arial.widthOfTextAtSize(`Bericht für den Messquerschnitt mit der ID: ${samID}`, fontSize) - 40,
      size: fontSize,
      font: arial,
      color: rgb(0, 0, 0),

    })

    page.drawText('für den Zeitraum', {
      rotate: degrees(90),
      x: 38,
      y: height - arial.widthOfTextAtSize('für den Zeitraum', fontSize) - 40,
      size: fontSize,
      font: arial,
      color: rgb(0, 0, 0),
    })
    let yearFrom = new Date(this.fromDate).getFullYear()
    let yearTo = new Date(this.toDate).getFullYear()
    let monthFrom = new Date(this.fromDate).getMonth() + 1
    let monthTo = new Date(this.toDate).getMonth() + 1
    let dayFrom = new Date(this.fromDate).getDate()
    let dayTo = new Date(this.toDate).getDate()

    let dateFrom = `${dayFrom}.${monthFrom}.${yearFrom} 00:00:00`
    
    let dateTo = `${dayTo}.${monthTo}.${yearTo} 23:59:59`

    page.drawText(`${dateFrom} bis ${dateTo}`, {
      rotate: degrees(90),
      x: 46,
      y: height - arial.widthOfTextAtSize(`${dateFrom} bis ${dateTo}`, fontSize) - 40,
      size: fontSize,
      font: arial,
      color: rgb(0, 0, 0),
    })

    /* Center */
    page.drawImage(logoImage, {
      rotate: degrees(90),
      x: 50,
      y: 380,
      width: 100,
      height: 25,
    })

    page.drawImage(pngImage, {
      rotate: degrees(90),
      x: 430,
      y: 25,
      width: 780,
      height: 300,
    })


    const pdfBytesForIMG = await pdfDocForIMG.saveAsBase64()
    const pdf1 = await PDFDocument.load(pdfBytesForIMG);
    const pdf2 = await PDFDocument.load(pdfUrl);

    const mergedPdf = await PDFDocument.create();
    const copiedPagesA = await mergedPdf.copyPages(pdf1, pdf1.getPageIndices());
    copiedPagesA.forEach((page) => mergedPdf.addPage(page));
    const copiedPagesB = await mergedPdf.copyPages(pdf2, pdf2.getPageIndices());
    copiedPagesB.forEach((page) => mergedPdf.addPage(page));
    const mergedPdfFile = await mergedPdf.saveAsBase64();

    const src = 'data:application/pdf;base64,' + mergedPdfFile

    let link = document.createElement('a');
    link.href = src;
    link.download = `Verkehrsdatenbericht_${this.samId}_${dayFrom}.${monthFrom}.${yearFrom}_${this.toDate.split('T')[0]}.pdf`;
    link.click()
  }

  private startDownload(filter: ChangelogMqFilter) {
    let filename = this.generateFileNames(filter);

    this.reportService.getRouteStationsSpeedWithFilterDownloadNew(filter.id, filter.dateFrom, filter.dateTo, filter.minutes, filter.deKanal, filter.filter!, filter.projectId).subscribe((data) => {
      const blob = new Blob([data], { type: data.type });
      const url = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.download = filename;
      a.click();
      let base64String = this.blobToBase64(blob).then((base64String) => {
        this.generatedPDFURL.emit(base64String);
        this.base64String = base64String
      });
    }
    );
  }

  public blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  private generateFileNames(filter: ChangelogMqFilter): string {
    if (filter.filter === 'csv') {
      let filename = 'MQReport_' + filter.id + '_' + filter.dateFrom.split('T')[0] + '_' + filter.dateTo.split('T')[0] + '.csv';
      return filename;
    }
    if (filter.filter === 'ascii') {
      let filename = 'MQReport_' + filter.id + '_' + filter.dateFrom.split('T')[0] + '_' + filter.dateTo.split('T')[0] + '.txt';
      return filename;
    }
    else {
      let filename = 'MQReport_' + filter.id + '_' + filter.dateFrom.split('T')[0] + '_' + filter.dateTo.split('T')[0] + '.pdf';
      return filename;
    }
  }
}
