<mat-dialog-content>
        <div class="d-flex justify-content-between">
                <h5 class="fw-semibold">Brennstoffzellendetails </h5>
                <div class="d-flex">
                        <img class="ms-3 avs-modal-close-btn mb-1" width="20" src="assets/icons/pencil.svg"
                                alt="close-modal-icon" (click)="onEditClicked()">
                        <img class="ms-3 avs-modal-close-btn mb-1" width="30" src="assets/icons/x.svg"
                                alt="close-modal-icon" (click)="onCloseClicked()">
                </div>
        </div>
        <div class="d-flex flex-column ">
                <div *ngIf="!isEditActive">
                        <div class="w-100 d-flex justify-content-between"> <span> Systemzustand: </span>
                                <span>{{
                                        getSystemzustandString(detailObj?.systemzustand! ) }} </span>
                        </div>
                        <div class="w-100 d-flex justify-content-between"> <span> Ladezustand Batterie:</span>
                                <span> {{
                                        detailObj?.ladezustandBatterie}}% </span>
                        </div>
                        <div class="w-100 d-flex justify-content-between"> <span> Batteriespannung:</span>
                                <span> {{
                                        detailObj?.batteriespannung}} V</span>
                        </div>
                        <div class="w-100 d-flex justify-content-between"> <span> Generatorleistung:</span>
                                <span> {{
                                        detailObj?.generatorleistung}} W</span>
                        </div>
                        <div class="w-100 d-flex justify-content-between"> <span> Generatorspannung: </span>
                                <span>{{
                                        detailObj?.generatorspannung}} V</span>
                        </div>
                        <div class="w-100 d-flex justify-content-between"> <span> Generatorstrom:</span> <span>
                                        {{
                                        detailObj?.generatorstrom }} A</span></div>
                        <div class="w-100 d-flex justify-content-between"> <span> Tanksensor:</span> <span> {{
                                        detailObj?.meldungExternTankSensor }}</span></div>

                        <div class="w-100 d-flex justify-content-between"> <span> Aktive Patrone:</span> <span>
                                        {{
                                        detailObj?.nummerAktiverPatrone }}</span></div>

                        <div class="w-100 d-flex justify-content-between"> <span> Füllstand Patrone 1:</span>
                                <span> {{
                                        detailObj?.fuellgradPatrone1 }}%</span>
                        </div>

                        <div class="w-100 d-flex justify-content-between"> <span> Füllstand Patrone 2:</span>
                                <span> {{
                                        detailObj?.fuellgradPatrone2 }}%</span>
                        </div>

                        <div *ngIf="details !== undefined">
                                <div class="w-100 d-flex justify-content-between"> <span> Systemzustand
                                                Detailliert:</span>
                                        <span> {{ getFuellCellSystemStateString(details.systemzustand) }}</span>
                                </div>
                                <div class="w-100 d-flex justify-content-between"> <span> Arbeitsmodus:</span>
                                        <span> {{ getArbeitsmodusString(details.arbeitsmodus) }}</span>
                                </div>
                                <div class="w-100 d-flex justify-content-between"> <span> Temperatur:</span>
                                        <span> {{ details.temperatur }}°C</span>
                                </div>
                                <div class="w-100 d-flex justify-content-between"> <span>
                                                ManuellesSchaltenAnMoeglich:</span>
                                        <span> {{ details.manuellesSchaltenAnMoeglich }}</span>
                                </div>
                                <div class="w-100 d-flex justify-content-between"> <span>
                                                ManuellesSchaltenAusMoeglich:</span>
                                        <span> {{ details.manuellesSchaltenAusMoeglich }}</span>
                                </div>
                                <div class="w-100 d-flex justify-content-between"> <span>
                                                GrundFuerAusschalten:</span>
                                        <span> {{ details.grundFuerAusschalten }}</span>
                                </div>
                                <div class="w-100 d-flex justify-content-between"> <span>
                                                GrundFuerEinschalten:</span>
                                        <span> {{ details.grundFuerEinschalten }}</span>
                                </div>
                                <div class="w-100 d-flex justify-content-between"> <span>
                                                FehlerzustandZelle:</span>
                                        <span> {{ details.fehlerzustandZelle }}</span>
                                </div>
                                <div class="w-100 d-flex justify-content-between"> <span> Fehlercode:</span>
                                        <span> {{ details.fehlercode }}</span>
                                </div>
                                <div class="w-100 d-flex justify-content-between"> <span>
                                                FehlerzustandFM:</span>
                                        <span> {{ details.fehlerzustandFM }}</span>
                                </div>
                                <div class="w-100 d-flex justify-content-between"> <span> FmFehlercode:</span>
                                        <span> {{ details.fmFehlercode }}</span>
                                </div>
                                <div class="w-100 d-flex justify-content-between"> <span>
                                                WarnzustandZelle:</span>
                                        <span> {{ details.warnzustandZelle }}</span>
                                </div>
                                <div class="w-100 d-flex justify-content-between"> <span> Warncode:</span>
                                        <span> {{ details.warncode }}</span>
                                </div>
                                <div class="w-100 d-flex justify-content-between"> <span>
                                                Betriebsstunden:</span>
                                        <span> {{ details.betriebsstunden }} Std.</span>
                                </div>
                                <div class="w-100 d-flex justify-content-between"> <span> Letzte Messung:</span>
                                        <span> {{ details.dateTime | date: 'dd.MM.yyyy - H:mm':
                                                offset(details.dateTime)
                                                }} </span>
                                </div>
                        </div>
                </div>
                <div *ngIf="isEditActive">
                        <div>
                                <mat-form-field>
                                        <mat-label>Systemstatus</mat-label>
                                        <mat-select [(ngModel)]="selectedStatus" name="status">
                                                <mat-option *ngFor="let status of systemstatus" [value]="status.value">
                                                        {{status.asText}}
                                                </mat-option>
                                        </mat-select>
                                </mat-form-field>
                                <button mat-raised-button color="primary" class="ms-2"
                                        (click)="onSaveStatusClicked()">Speichern</button>
                        </div>
                        <div>
                                <mat-form-field>
                                        <mat-label>Patrone</mat-label>
                                        <mat-select [(ngModel)]="selectedPatrone" name="patrone">
                                                <mat-option *ngFor="let patrone of patronenNo" [value]="patrone.value">
                                                        {{patrone.asText}}
                                                </mat-option>
                                        </mat-select>
                                </mat-form-field>
                                <mat-form-field class="ms-2">
                                        <mat-label>Patrone</mat-label>
                                        <input matInput type="number" [(ngModel)]="fuellgradPatrone">
                                        <button *ngIf="fuellgradPatrone" matSuffix mat-icon-button aria-label="Clear"
                                                (click)="fuellgradPatrone=0">
                                                <mat-icon>close</mat-icon>
                                        </button>
                                </mat-form-field>
                                <button mat-raised-button color="primary" class="ms-2"
                                        (click)="onSaveFuellClicked()">Speichern</button>
                        </div>
                </div>
        </div>
        

</mat-dialog-content>