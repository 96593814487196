import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Chart } from 'chart.js';
import { ReportService } from 'src/app/dashboard/shared/services/report.service';

@Component({
  selector: 'avs-stm-chart',
  templateUrl: './stm-chart.component.html',
  styleUrls: ['./stm-chart.component.css']
})
export class StmChartComponent {

  public chart: any; public chart2: any;
  public samId: number;
  public now = new Date();
  public nowAsISOString: string;
  public twoDaysAgoAsString: string;
  public chartData: any;
  public chartData2: any;
  public labelCollection: string[] = [];
  public resultCollection: number[] = [];
  public labelCollection2: string[] = [];
  public resultCollection2: number[] = [];
  public fromDate: number = 2;
  public toDate: number = 0;
  public noData1?: boolean;
  public noData2?: boolean;
  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    private reportService: ReportService,
    private dialogRef: MatDialogRef<StmChartComponent>) {
    if (this.data.routeStation.daysOld !== undefined) { this.toDate = Math.round(this.data.routeStation.daysOld); this.fromDate = Math.round(this.data.routeStation.daysOld) + 2 }
    this.samId = this.data.routeStation.samId
    this.nowAsISOString = this.now.toISOString()
    let d = new Date();
    d.setDate(d.getDate() - 2);
    this.twoDaysAgoAsString = d.toISOString()
  }

  ngOnInit(): void {
    this.getData();
  }

  private getData(): void {
    this.labelCollection = [];
    this.resultCollection = [];
    this.reportService.getRouteStationsSTMWithFilterNew(this.samId, this.twoDaysAgoAsString, this.nowAsISOString, 1).subscribe(x => {
      if (x === null) { this.noData1 = true; return; }
      this.chartData = x;
      this.chartData.forEach((element: { measuredAt: string, statusAsNumber: number, status: string }) => {
        element.statusAsNumber = this.getStatusAsNumber(element.status)
        let date = element.measuredAt.split('T')
        let splittedDate = date[0].split('-')
        let time = date[1].split(':')
        let hours = time[0]
        let mins = time[1]
        let dateTime = '  ' + splittedDate[2] + '.' + splittedDate[1] + '.' + splittedDate[0].substring(2) + ' ' + hours + ':' + mins
        this.labelCollection.push(dateTime)
      })
      this.chartData.forEach((element: { statusAsNumber: number; }) => {

        this.resultCollection.push(element.statusAsNumber)
      });
      this.createChart1();
    })
    this.labelCollection2 = [];
    this.resultCollection2 = [];
    this.reportService.getRouteStationsSTMWithFilterNew(this.samId, this.twoDaysAgoAsString, this.nowAsISOString, 2).subscribe(x => {
      if (x === null) { this.noData2 = true; return; }
      this.chartData2 = x;
      this.chartData2.forEach((element: { measuredAt: string, statusAsNumber: number, status: string }) => {
        element.statusAsNumber = this.getStatusAsNumber(element.status)
        let date = element.measuredAt.split('T')
        let splittedDate = date[0].split('-')
        let time = date[1].split(':')
        let hours = time[0]
        let mins = time[1]
        let dateTime = '  ' + splittedDate[2] + '.' + splittedDate[1] + '.' + splittedDate[0].substring(2) + ' ' + hours + ':' + mins
        this.labelCollection2.push(dateTime)
      })
      this.chartData2.forEach((element: { statusAsNumber: number; }) => {

        this.resultCollection2.push(element.statusAsNumber)
      });
      this.createChart2();
    })
  }

  public getStatusAsNumber(status: string) {
    if (status == 'Low') {
      return 0;
    }
    else if (status == 'High') {
      return 1;
    }
    return -1; // Default return value if status does not match any condition
  }

  public removeData(chart: any): void {
    chart.destroy()
    this.getData();
  }

  public setFromDate(date: number): void {
    this.fromDate = date
    let d = new Date();
    d.setDate(d.getDate() - this.fromDate);
    this.twoDaysAgoAsString = d.toISOString()
  }

  public setToDate(date: number): void {
    this.toDate = date
    let d = new Date();
    d.setDate(d.getDate() - this.toDate);
    this.nowAsISOString = d.toISOString()
  }

  public onCloseAddTimeSwitchClicked(): void {
    this.dialogRef.close();
  }

  private createChart1(): void {
    this.chart = new Chart("MyChart", {
      type: 'line',

      data: {
        labels: this.labelCollection,
        datasets: [
          {
            label: "STM Status 1",
            data: this.resultCollection,
            backgroundColor: 'red',
            fill: false,
            borderColor: 'rgba(0, 0, 0, 0.198)',
            tension: 0.1,
            pointRadius: 2
          },
        ]
      },
      options: {
        aspectRatio: 2.5
      }

    });
  }

  private createChart2(): void {
    this.chart2 = new Chart("MyChart2", {
      type: 'line',

      data: {
        labels: this.labelCollection2,
        datasets: [
          {
            label: "STM Status 2",
            data: this.resultCollection2,
            backgroundColor: 'blue',
            fill: false,
            borderColor: 'rgba(0, 0, 0, 0.198)',
            tension: 0.1,
            pointRadius: 2
          },
        ]
      },
      options: {
        aspectRatio: 2.5
      }

    });
  }
}