import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProjectImageData } from '../../interfaces/project-image-data.interface';
import { ImageDataService } from '../../services/image-data.service';
import { ModalService } from 'src/app/shared/modal/shared/modal.service';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { BilderkatalogMethodInterface } from '../../interfaces/bilderkatalog-method.interface';

@Component({
  selector: 'avs-bilderkatalog',
  templateUrl: './bilderkatalog.component.html',
  styleUrls: ['./bilderkatalog.component.css']
})
export class BilderkatalogComponent {
  protected readonly environment = environment;


  @Input() public title: string = '';
  @Input() public images: ProjectImageData[] = [];
  @Input() public methods: BilderkatalogMethodInterface[] = [];
  @Output() myClick = new EventEmitter();
  @Output() genericMethod = new EventEmitter();
  constructor(private imageDataService: ImageDataService,
    private modalService: ModalService,
    private dialog: MatDialog,
  ) { }



  onImageClicked(image: ProjectImageData) {
    this.myClick.emit(image);
  }

  genericTitleMethod(name: string) {
    console.log('genericTitleMethod');
    this.genericMethod.emit(name);
  }



}
