import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { UserDataService } from "./services/user-data.service";
import { UserDataResponse } from "./interfaces/login-data-response.interface";
import { RoleTypes } from "./enums/role.enum";

@Injectable({
    providedIn: 'root'
})
export class RoleGuard implements CanActivate {
    private userData?: UserDataResponse
    /* RolqGuard und LoginGuard müssen überabeitet werden -> https://angular.io/api/router/CanActivateFn */
    constructor(private readonly UserDataService: UserDataService) {
        this.UserDataService.userData.subscribe(userData => {
            this.userData = userData;
        });
    }

    public canActivate(routeSnapshot: ActivatedRouteSnapshot, routerSnapshot: RouterStateSnapshot): boolean {
        const url = encodeURI(routerSnapshot.url);
        if (this.userData) {
            return this.checkUrl(url, this.userData.role);
        } else {
            return this.checkUrl(url, this.getUserRoleFromLocalStorage());
        }
    }

    private getUserRoleFromLocalStorage(): RoleTypes {
        let role = localStorage.getItem('role');
        if (!role) { return RoleTypes.none; }
        if (role.includes('jh6s9g123kajhsvbci9jhkj')) {
            return RoleTypes.user;
        } else if (role.includes('8shjk29jkalkjjücgqgg6jh')) {
            return RoleTypes.streetworker;
        } else if (role.includes('mmvgfg11trztr1fgpoo7dhg')) {
            return RoleTypes.extern;
        } else if (role.includes('4rrztsxlöaqggfhhsttwiq8')) {
            return RoleTypes.adminReadOnly;
        } else if (role.includes('fferrrtsgloo75agf3gahga')) {
            return RoleTypes.admin;
        } else if (role.includes('aospudhasiodasdjind123e')) {
            return RoleTypes.userReadOnly;
        } else {
            return RoleTypes.none;
        }
    }

    private checkUrl(url: string, userRole: RoleTypes): boolean {
        switch (url) {
            case '/dashboard':
                return checkAccessAuthorization([1, 5], userRole);
            case '/project':
                return checkAccessAuthorization([1, 2, 3, 4, 5, 7], userRole);
            case '/project/map':
                return checkAccessAuthorization([1, 2, 3, 4, 5, 7], userRole);
            case '/project/newMap':
                return checkAccessAuthorization([1, 2, 3, 4, 5, 7], userRole);
            case '/project/settings':
                return checkAccessAuthorization([1], userRole);
            case '/project/schema':
                return checkAccessAuthorization([1, 2, 3, 4, 5, 7], userRole);
            case '/project/test-area':
                return checkAccessAuthorization([1], userRole);
            case '/project/station-overview':
                return checkAccessAuthorization([1, 2, 3, 4, 5, 7], userRole);
            case '/project/images':
                return checkAccessAuthorization([1], userRole);
            case '/project/lisa':
                return checkAccessAuthorization([1], userRole);
            case '/project/notes':
                return checkAccessAuthorization([1], userRole);
            case '/project/documents':
                return checkAccessAuthorization([1], userRole);
            case '/project/notes':
                return checkAccessAuthorization([1], userRole);
            case '/project/time-switch':
                return checkAccessAuthorization([1], userRole);
            case '/project/swa-config':
                return checkAccessAuthorization([1], userRole);
            case '/project/scenario':
                return checkAccessAuthorization([1, 2, 3, 4, 5, 7], userRole);
            case '/device':
                return checkAccessAuthorization([1], userRole);
            case '/device/settings':
                return checkAccessAuthorization([1], userRole);
            case '/device/test-area':
                return checkAccessAuthorization([1], userRole);
            case '/device/route-station':
                return checkAccessAuthorization([1], userRole);
            case '/device/notes':
                return checkAccessAuthorization([1], userRole);
            case '/report':
                return checkAccessAuthorization([1, 2, 3, 4, 5, 7], userRole);
            case '/report/detailed':
                return checkAccessAuthorization([1, 2, 3, 4, 5, 7], userRole);
            case '/report/diagram':
                return checkAccessAuthorization([1, 2, 3, 4, 5, 7], userRole);
            case '/user':
                return checkAccessAuthorization([1, 2, 3, 4, 5, 7], userRole);
            case '/user/user-data-settings':
                return checkAccessAuthorization([1, 2, 3, 4, 5, 7], userRole);
            case '/user/user-overview':
                return checkAccessAuthorization([1], userRole);
            case '/user/edit/:id':
                return checkAccessAuthorization([1], userRole);
            case '/user/create':
                return checkAccessAuthorization([1], userRole);
            case '/company':
                return checkAccessAuthorization([1], userRole);
            case '/company/company-overview':
                return checkAccessAuthorization([1], userRole);
            case '/company/edit/:id':
                return checkAccessAuthorization([1], userRole);
            case '/company/create':
                return checkAccessAuthorization([1], userRole);
            case '/faq':
                return checkAccessAuthorization([1, 2, 3, 4, 5, 7], userRole);
            case '/faq/faq':
                return checkAccessAuthorization([1, 2, 3, 4, 5, 7], userRole);
            case '/faq/account':
                return checkAccessAuthorization([1, 2, 3, 4, 5, 7], userRole);
            case '/faq/overview':
                return checkAccessAuthorization([1, 2, 3, 4, 5, 7], userRole);
            case '/faq/project':
                return checkAccessAuthorization([1, 2, 3, 4, 5, 7], userRole);
            case '/faq/report':
                return checkAccessAuthorization([1, 2, 3, 4, 5, 7], userRole);
            case '/faq/bilder':
                return checkAccessAuthorization([1], userRole);
            case '/faq/swa':
                return checkAccessAuthorization([1], userRole);
            case '/faq/timer-switch':
                return checkAccessAuthorization([1], userRole);
            default:
                return false;
        }
    }

}
export function checkAccessAuthorization(releasLevel: number[], userRole: RoleTypes): boolean {
    for (let releas of releasLevel) {
        if (releas === userRole) {
            return true;
        }
    }
    return false;
}
