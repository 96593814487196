import { SelectInput } from "../../../shared/dropdown/shared/select-input.class";

export enum DataEndChannelTypeEnum {
    Unknown,
    LED,
    PW,
    BLINKER,
    RADAR,
    CLUSTER,
    FUELCELL,
    DIGITALINPUT,
    DIGITALOUPUT,
    RADARPOWERSWITCH,
    LEDPOWERSWITCH,
    ROUTER,
    SOLAR,
    EVAVOLTAGE
}

export function getDataEndChannelTypeString(type: DataEndChannelTypeEnum): string {
    switch (type) {
        case DataEndChannelTypeEnum.RADAR:
            return 'Radar';
        case DataEndChannelTypeEnum.LED:
            return 'LED-Anzeige';
        case DataEndChannelTypeEnum.CLUSTER:
            return 'Cluster';
        case DataEndChannelTypeEnum.BLINKER:
            return 'Blinker';
        case DataEndChannelTypeEnum.PW:
            return 'Prismenwender';
        case DataEndChannelTypeEnum.FUELCELL:
            return 'Brennstoffzelle';
        case DataEndChannelTypeEnum.DIGITALINPUT:
            return 'Digitaler Eingang';
        case DataEndChannelTypeEnum.DIGITALOUPUT:
            return 'Digitaler Ausgang';
        case DataEndChannelTypeEnum.RADARPOWERSWITCH:
            return 'Radar-Powerswitch';
        case DataEndChannelTypeEnum.LEDPOWERSWITCH:
            return 'LED-Powerswitch';
        case DataEndChannelTypeEnum.ROUTER:
            return 'Router';
        case DataEndChannelTypeEnum.SOLAR:
            return 'Solar';
        case DataEndChannelTypeEnum.EVAVOLTAGE:
            return 'EVA Spannung';






        case DataEndChannelTypeEnum.Unknown:
            return 'Sonstiges';
        default:
            return 'Unbekannt';
    }
}

export function getDataEndChannelTypeSelect(): SelectInput[] {
    return [
        { name: getDataEndChannelTypeString(DataEndChannelTypeEnum.LED), id: 1 },
        { name: getDataEndChannelTypeString(DataEndChannelTypeEnum.PW), id: 2 },
        { name: getDataEndChannelTypeString(DataEndChannelTypeEnum.RADAR), id: 4 },
        { name: getDataEndChannelTypeString(DataEndChannelTypeEnum.BLINKER), id: 3 },
        { name: getDataEndChannelTypeString(DataEndChannelTypeEnum.CLUSTER), id: 5 },
        { name: getDataEndChannelTypeString(DataEndChannelTypeEnum.Unknown), id: 0 },
    ];
}