<mat-dialog-content class="rounded-0">

    <div class="d-flex justify-content-between">
        <h5 class="fw-semibold">Sonderprogramm erstellen</h5>
        <img class="ms-3 avs-modal-close-btn mb-1" width="30" src="assets/icons/x.svg" alt="close-modal-icon"
            (click)="onCloseDialogClicked()">
    </div>



    <!-- MQ Start -->
    <ng-container>
        <!-- Smart Micro Smart Micro Smart Micro Smart Micro Smart Micro Smart Micro Smart Micro Smart Micro Smart Micro -->

        <div *ngIf="data.kanal.fg === 1 && data.kanal.localbusAddress === 11">
            <span class="fw-semibold ">Smart Micro konfigurieren</span>
            <!-- Traffic Auswahl -->
            <div class="w-100 d-flex justify-content-center mb-2">
                <div class="d-flex justify-content-center mb-2 mt-2" style="width: 240px !important">
                    <div class="w-100 avs-route-station-settings-dropdown-container justify-content-between">
                        <span class="ms-1 fw-semibold text-secondary">Verkehrsstufe</span>
                        <div [ngClass]="isTrafficDropdownOpen ? 'border-danger' : ''"
                            class="border d-flex justify-content-between" role="button"
                            (click)="onTrafficDropdownClicked()">
                            <div class="ms-2 p-1">{{ selectedTraffic.name }}</div>
                            <img *ngIf="!isTrafficDropdownOpen" class="me-2" src="assets/icons/caret-down.svg"
                                alt="caret-down open select">
                            <img *ngIf="isTrafficDropdownOpen" class="me-2" src="assets/icons/caret-up.svg"
                                alt="caret-up close select">
                        </div>
                        <avs-dropdown [selectInput]="trafficSelect" [activeItem]="selectedTraffic"
                            (onItemClicked)="onTrafficItemClicked($event)" [isHeaderActive]="false"
                            *ngIf="isTrafficDropdownOpen" class="avs-route-station-settings-dropdown-container">
                        </avs-dropdown>
                    </div>
                </div>
            </div>

            <!-- Verkehrssituation Auswahl -->
            <div class="w-100 d-flex justify-content-center mb-2">
                <div class="d-flex justify-content-center mb-2 mt-2" style="width: 240px !important">
                    <div class="w-100 avs-route-station-settings-dropdown-container justify-content-between">
                        <span class="ms-1 fw-semibold text-secondary">Verkehrssituation</span>
                        <div [ngClass]="isVerkehrszustandDropdownOpen ? 'border-danger' : ''"
                            class="border d-flex justify-content-between" role="button"
                            (click)="onVerkehrszustandDropdownClicked()">
                            <div class="ms-2 p-1">{{ selectedVerkehrszustand.name }}</div>
                            <img *ngIf="!isVerkehrszustandDropdownOpen" class="me-2" src="assets/icons/caret-down.svg"
                                alt="caret-down open select">
                            <img *ngIf="isVerkehrszustandDropdownOpen" class="me-2" src="assets/icons/caret-up.svg"
                                alt="caret-up close select">
                        </div>
                        <avs-dropdown [selectInput]="verkehrszustandSelect" [activeItem]="selectedVerkehrszustand"
                            (onItemClicked)="onVerkehrszustandItemClicked($event)" [isHeaderActive]="false"
                            *ngIf="isVerkehrszustandDropdownOpen" class="avs-route-station-settings-dropdown-container">
                        </avs-dropdown>
                    </div>
                </div>
            </div>
        </div>
        <!-- Smart Micro Smart Micro Smart Micro Smart Micro Smart Micro Smart Micro Smart Micro Smart Micro Smart Micro -->

        <!-- ADEC ASIM VIAFALCON ADEC ASIM VIAFALCON ADEC ASIM VIAFALCON ADEC ASIM VIAFALCON ADEC ASIM VIAFALCON ADEC ASIM VIAFALCON -->

        <div *ngIf="data.kanal.fg === 1 && data.kanal.localbusAddress === 3">
            <span class="fw-semibold ">ADEC/ASIM/VIAFALCON</span>
            <!-- Traffic Auswahl -->
            <div class="w-100 d-flex justify-content-center mb-2">
                <div class="d-flex justify-content-center mb-2 mt-2" style="width: 240px !important">
                    <div class="w-100 avs-route-station-settings-dropdown-container justify-content-between">
                        <span class="ms-1 fw-semibold text-secondary">Verkehrsstufe</span>
                        <div [ngClass]="isTrafficDropdownOpen ? 'border-danger' : ''"
                            class="border d-flex justify-content-between" role="button"
                            (click)="onTrafficDropdownClicked()">
                            <div class="ms-2 p-1">{{ selectedTraffic.name }}</div>
                            <img *ngIf="!isTrafficDropdownOpen" class="me-2" src="assets/icons/caret-down.svg"
                                alt="caret-down open select">
                            <img *ngIf="isTrafficDropdownOpen" class="me-2" src="assets/icons/caret-up.svg"
                                alt="caret-up close select">
                        </div>
                        <avs-dropdown [selectInput]="trafficSelect" [activeItem]="selectedTraffic"
                            (onItemClicked)="onTrafficItemClicked($event)" [isHeaderActive]="false"
                            *ngIf="isTrafficDropdownOpen" class="avs-route-station-settings-dropdown-container">
                        </avs-dropdown>
                    </div>
                </div>
            </div>

            <!-- Verkehrssituation Auswahl -->
            <div class="w-100 d-flex justify-content-center mb-2">
                <div class="d-flex justify-content-center mb-2 mt-2" style="width: 240px !important">
                    <div class="w-100 avs-route-station-settings-dropdown-container justify-content-between">
                        <span class="ms-1 fw-semibold text-secondary">Verkehrssituation</span>
                        <div [ngClass]="isVerkehrszustandDropdownOpen ? 'border-danger' : ''"
                            class="border d-flex justify-content-between" role="button"
                            (click)="onVerkehrszustandDropdownClicked()">
                            <div class="ms-2 p-1">{{ selectedVerkehrszustand.name }}</div>
                            <img *ngIf="!isVerkehrszustandDropdownOpen" class="me-2" src="assets/icons/caret-down.svg"
                                alt="caret-down open select">
                            <img *ngIf="isVerkehrszustandDropdownOpen" class="me-2" src="assets/icons/caret-up.svg"
                                alt="caret-up close select">
                        </div>
                        <avs-dropdown [selectInput]="verkehrszustandSelect" [activeItem]="selectedVerkehrszustand"
                            (onItemClicked)="onVerkehrszustandItemClicked($event)" [isHeaderActive]="false"
                            *ngIf="isVerkehrszustandDropdownOpen" class="avs-route-station-settings-dropdown-container">
                        </avs-dropdown>
                    </div>
                </div>
            </div>
        </div>
        <!-- ADEC ASIM VIAFALCON ADEC ASIM VIAFALCON ADEC ASIM VIAFALCON ADEC ASIM VIAFALCON ADEC ASIM VIAFALCON ADEC ASIM VIAFALCON -->
    </ng-container>
    <!-- MQ Ende -->

    <!-- AQ START -->
    <ng-container>
        <!-- LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED  -->
        <div *ngIf="data.kanal.fg === 4 && (data.kanal.localbusAddress === 2 || data.kanal.localbusAddress === 1)">
                <avs-bilderkatalog [title]="'LED-Anzeige'" [images]="images"
                    (myClick)="onProjectImageClicked($event)"></avs-bilderkatalog>
            <div class="w-100 d-flex justify-content-center mb-2">
                <div class="avs-route-station-settings-dropdown-container justify-content-between"
                    style="width: 240px !important">
                    <span class="ms-1 fw-semibold text-secondary">Funktion</span>
                    <div [ngClass]="isFunctionDropdownOpen ? 'border-danger' : ''"
                        class="border d-flex justify-content-between" role="button"
                        (click)="onFunctionDropdownClicked()">
                        <div class="ms-2 p-1">{{ selectedFunction.name }}</div>
                        <img *ngIf="!isFunctionDropdownOpen" class="me-2" src="assets/icons/caret-down.svg"
                            alt="caret-down open select">
                        <img *ngIf="isFunctionDropdownOpen" class="me-2" src="assets/icons/caret-up.svg"
                            alt="caret-up close select">
                    </div>
                    <avs-dropdown [selectInput]="functionSelect" [activeItem]="selectedFunction"
                        (onItemClicked)="onFunctionItemClicked($event)" [isHeaderActive]="false"
                        *ngIf="isFunctionDropdownOpen" class="avs-route-station-settings-dropdown-container">
                    </avs-dropdown>
                </div>
            </div>

            <div *ngIf="selectedFunction.id == 2" class="w-100 d-flex justify-content-center mb-2">
                <input type="number" placeholder="Intervall in ms angeben" *ngIf="selectedFunction.id === 2"
                    [required]="true" label="Blinker-Intervall in ms" [(ngModel)]="tlS_Time">
            </div>



            <!-- LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED LED  -->
        </div> <!-- TODO: beim start der component nur die BIlder laden, WENN conditions übereinstimmen -->
    </ng-container>
    <!-- AQ Ende -->
    <!--Erstmal Skippen ->
        

          "settings": "{\"AQ\": null, \"MQ\": {\"VS\": 2, \"Active\": true, \"Traffic\": 1}}", x
        https://ramuddenglobal.atlassian.net/browse/RPA-318


        Smart Micro
        EAkanal 1 = rechte spurt
        EAkanal 1++ = 1. Überholspur
        etc. pp

        ADEC ++ // ASIMC ++ 
        indexbase 160++
        
        viafalcon ea 1, ganzer abschnitt 
        Gibt noch weitere Sensoren auf anderen localbus adressen -->
    <ng-container>
        <div *ngIf="data.kanal.fg === 4 && data.kanal.localbusAddress === 6">
            <span class="fw-semibold ">Blinker konfigurieren</span>
            <div class="w-100 d-flex justify-content-center mb-2">
                <div class="avs-route-station-settings-dropdown-container justify-content-between"
                    style="width: 240px !important">
                    <span class="ms-1 fw-semibold text-secondary">Funktion</span>
                    <div [ngClass]="isFunctionDropdownOpen ? 'border-danger' : ''"
                        class="border d-flex justify-content-between" role="button"
                        (click)="onFunctionDropdownClicked()">
                        <div class="ms-2 p-1">{{ selectedFunction.name }}</div>
                        <img *ngIf="!isFunctionDropdownOpen" class="me-2" src="assets/icons/caret-down.svg"
                            alt="caret-down open select">
                        <img *ngIf="isFunctionDropdownOpen" class="me-2" src="assets/icons/caret-up.svg"
                            alt="caret-up close select">
                    </div>
                    <avs-dropdown [selectInput]="functionSelect" [activeItem]="selectedFunction"
                        (onItemClicked)="onFunctionItemClicked($event)" [isHeaderActive]="false"
                        *ngIf="isFunctionDropdownOpen" class="avs-route-station-settings-dropdown-container">
                    </avs-dropdown>
                </div>
            </div>

            <div *ngIf="selectedFunction.id == 2" class="w-100 d-flex justify-content-center mb-2">
                <input type="number" placeholder="Intervall in ms angeben" *ngIf="selectedFunction.id === 2"
                    [required]="true" label="Blinker-Intervall in ms" [(ngModel)]="tlS_Time">
            </div>



        </div>
    </ng-container>



    <div *ngIf="data.kanal.fg === 4 && data.kanal.localbusAddress === 8">

        <span class="fw-semibold ">Prismenwender</span>

        <div class="row avs-project-images-container d-flex justify-content-center mb-2">
            <ng-container *ngFor="let image of imagesPW">
                <div class="mb-3 avs-project-images " (click)="onProjectPWImageClicked(image)">
                    <div class="d-flex justify-content-center w-100 position-relative">
                        <img [src]="environment.apiAddress + image.path" alt="Bilderkatalog">
                        <img *ngIf="image.isAnimated" src="assets/icons/shuffle.svg" alt="animatedImage"
                            class="position-absolute bg-white rounded-5 p-1 "
                            style="top: 1px; right: 12px; height: 16px">
                    </div>
                    <div class="small text-center">Stellcode: {{image.stellcode}}</div>
                </div>
            </ng-container>
        </div>

        <div class="w-100 d-flex justify-content-center mb-2">
            <div class="avs-route-station-settings-dropdown-container justify-content-between"
                style="width: 240px !important">
                <span class="ms-1 fw-semibold text-secondary">Funktion</span>
                <div [ngClass]="isPWFunctionDropdownOpen ? 'border-danger' : ''"
                    class="border d-flex justify-content-between" role="button" (click)="onPWFunctionDropdownClicked()">
                    <div class="ms-2 p-1">{{ selectedFunction.name }}</div>
                    <img *ngIf="!isPWFunctionDropdownOpen" class="me-2" src="assets/icons/caret-down.svg"
                        alt="caret-down open select">
                    <img *ngIf="isPWFunctionDropdownOpen" class="me-2" src="assets/icons/caret-up.svg"
                        alt="caret-up close select">
                </div>
                <avs-dropdown [selectInput]="PWFunctionSelect" [activeItem]="selectedFunction"
                    (onItemClicked)="onPWFunctionItemClicked($event)" [isHeaderActive]="false"
                    *ngIf="isPWFunctionDropdownOpen" class="avs-route-station-settings-dropdown-container">
                </avs-dropdown>
            </div>
        </div>
        <!-- Funktionen  auf den eines pws beschränken   an aus
    ----blinkend fällt weg -->


    </div>



    <mat-checkbox class="d-flex justify-content-center me-2" [(ngModel)]="highPriority">Hohe Priorität</mat-checkbox>

    <div *ngFor="let manualControlObj of data.kanal.manualControlObj" class="d-flex justify-content-center ">
        <button *ngIf="manualControlObj.scenarioId === this.data.scenarioId"
            class="btn btn-sm btn-danger rounded-0 mt-2" (click)="onDeleConfigClicked()"> Konfig löschen</button>
        <!-- TODO: Hier ist irgendwas sehr falsch  -->
    </div>

    <div class="d-flex justify-content-between mt-5 border-top pt-3">
        <button class="btn btn-sm btn-outline-danger rounded-0" (click)="onCloseDialogClicked()">
            Abbrechen
        </button>
        <button *ngIf="data.kanal.fg === 4 && (data.kanal.localbusAddress === 2 || data.kanal.localbusAddress === 1)"
            class="btn btn-sm btn-outline-success rounded-0" (click)="onSubmitAQClicked()">
            Speichern
        </button>
        <button
            *ngIf="(data.kanal.fg === 1 && data.kanal.localbusAddress === 3) || (data.kanal.fg === 1 && data.kanal.localbusAddress === 11)"
            class="btn btn-sm btn-outline-success rounded-0" (click)="onSubmitMQClicked()">
            Speichern
        </button>
        <button *ngIf="data.kanal.fg === 4 && data.kanal.localbusAddress === 6"
            class="btn btn-sm btn-outline-success rounded-0" (click)="onSubmitFlasherClicked()">
            Speichern
        </button>
        <button *ngIf="data.kanal.fg === 4 && data.kanal.localbusAddress === 8"
            class="btn btn-sm btn-outline-success rounded-0" (click)="onSubmitAQClicked()">
            Speichern
        </button>



    </div>
</mat-dialog-content>