import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReportService } from 'src/app/dashboard/shared/services/report.service';
import { DataPointService } from 'src/app/project/shared/services/datapoint.service';
import { SelectInput } from 'src/app/shared/dropdown/shared/select-input.class';
import { Chart } from 'chart.js/auto';

@Component({
  selector: 'avs-brennstoffzellen-history',
  templateUrl: './brennstoffzellen-history.component.html',
  styleUrls: ['./brennstoffzellen-history.component.css']
})
export class BrennstoffzellenHistoryComponent implements OnInit {
  public chart: any;
  public samId: number;
  public now = new Date();
  public nowAsISOString: string;
  public twoDaysAgoAsString: string;
  public chartData: any;
  public labelCollection: string[] = [];
  public resultCollection: number[] = [];

  public generatorleistung: number[] = [];
  public generatorspannung: number[] = [];
  public generatorstrom: number[] = [];
  public ladezustandBatterie: number[] = [];
  public temperatur: number[] = [];
  public verbleibenderGesamtkraftstoff: number[] = [];
  public fehlerWarnungEreignis: boolean[] = [];
  public fehlerWarnungEreignisInteger: number[] = [];
  public fehlercode: string[] = [];
  public fmFehlercode: string[] = [];
  public warncode: string[] = [];

  public isChannelDropdownOpen: boolean = false;
  public channelSelect: SelectInput[] = [{ name: 'Kanal 1', id: 1 }];
  public selectedChannel: SelectInput = { name: 'Kanal 1', id: 1 };

  public isIntervallDropdownOpen: boolean = false;
  public intervallSelect: SelectInput[] = [{ name: '5 Minuten', id: 5 }, { name: '15 Minuten', id: 15 }, { name: '30 Minuten', id: 30 }, { name: '60 Minuten', id: 60 }];
  public selectedIntervall: SelectInput = { name: '5 Minuten', id: 5 };

  public fromDate: number = 2;
  public toDate: number = 0;
  public selectedMinutes: number = 60;
  public minutes: number[] = [5, 15, 30, 60];
  public selectedKanal: number = 1;
  public kanals: number[] = [1];
  public efoyBaseURL: string = `https://www.efoy-pro.com/en/service/servicetool/?product=efoy-pro-2800&errorcode=`

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    private dataPointService: DataPointService,
    private reportService: ReportService,
    private dialogRef: MatDialogRef<BrennstoffzellenHistoryComponent>) {
    this.samId = this.data.data.routeStation.samId
    this.nowAsISOString = this.now.toISOString()
    let d = new Date();
    d.setDate(d.getDate() - 2);
    this.twoDaysAgoAsString = d.toISOString()
    if (this.data.data.deviceParameter.radar.length > 1) {
      let length = this.data.data.deviceParameter.radar.length
      for (let i = 2; i <= length; i++) {
        this.channelSelect.push({ name: 'Kanal ' + i, id: i })
      }
    }
  }

  ngOnInit(): void {
    this.getData();
  }

  public exportChart(): void {
    let canvas = document.getElementById('MyChart') as HTMLCanvasElement;
    let dataURL = canvas.toDataURL('image/png');
    let link = document.createElement('a');
    link.href = dataURL;
    link.download = 'chart.png';
    link.click();
  }

  private getData(): void {
    this.labelCollection = [];
    this.resultCollection = [];
    this.generatorleistung = [];
    this.generatorspannung = [];
    this.generatorstrom = [];
    this.ladezustandBatterie = [];
    this.temperatur = [];
    this.verbleibenderGesamtkraftstoff = [];
    this.fehlerWarnungEreignis = [];



    this.reportService.getFuelCellReport(this.twoDaysAgoAsString, this.nowAsISOString, 'json', this.samId, this.data.data.routeStation.groupId).subscribe((data: any) => {
      this.chartData = data
      let controlle: any[] = [];
      data.forEach((xy: any) => { if (xy.fehlerWarnungEreignis === true) { controlle.push(xy) } else { return } })
      this.chartData.forEach((element: { measuredAt: string, generatorleistung: number, generatorspannung: number, generatorstrom: number, ladezustandBatterie: number, temperatur: number, verbleibenderGesamtkraftstoff: number, fehlerWarnungEreignis: boolean, fehlercode: string, fmFehlercode: string, warncode: string }) => {

        let date = element.measuredAt.split('T')
        let splittedDate = date[0].split('-')
        let time = date[1].split(':')
        let hours = time[0]
        let mins = time[1]
        let dateTime = '  ' + splittedDate[2] + '.' + splittedDate[1] + '.' + splittedDate[0].substring(2) + ' ' + hours + ':' + mins
        this.labelCollection.push(dateTime)
      });
      this.chartData.forEach((element: { generatorleistung: number, generatorspannung: number, generatorstrom: number, ladezustandBatterie: number, temperatur: number, verbleibenderGesamtkraftstoff: number, fehlerWarnungEreignis: boolean, fehlercode: string, fmFehlercode: string, warncode: string }) => {

        this.generatorleistung.push(element.generatorleistung)
        this.generatorspannung.push(element.generatorspannung)
        this.generatorstrom.push(element.generatorstrom)
        this.ladezustandBatterie.push(element.ladezustandBatterie)
        this.temperatur.push(element.temperatur)
        this.verbleibenderGesamtkraftstoff.push(element.verbleibenderGesamtkraftstoff)

        this.fehlerWarnungEreignis.push(element.fehlerWarnungEreignis)

        this.fehlercode.push(element.fehlercode)

        this.fmFehlercode.push(element.fmFehlercode)
        this.warncode.push(element.warncode)
        if (element.fehlerWarnungEreignis === true) {
          if (element.fehlercode !== '000.000') { this.fehlerWarnungEreignisInteger.push(80); return }
          if (element.fmFehlercode !== '000.000') { this.fehlerWarnungEreignisInteger.push(60); return }
          if (element.warncode !== '000.000') { this.fehlerWarnungEreignisInteger.push(40); return }

        }
        else this.fehlerWarnungEreignisInteger.push(0)
      });
      this.createChart();
    })
  }

  public onChannelItemClicked(item: SelectInput): void {
    this.isChannelDropdownOpen = false;
    this.selectedChannel.name = item.name
    this.selectedChannel.id = item.id
  }

  public onChannelDropdownClicked() {
    this.isChannelDropdownOpen = !this.isChannelDropdownOpen;
  }

  public onIntervallItemClicked(item: SelectInput): void {
    this.isIntervallDropdownOpen = false;
    this.selectedIntervall.name = item.name
    this.selectedIntervall.id = item.id
  }

  public onIntervallDropdownClicked() {
    this.isIntervallDropdownOpen = !this.isIntervallDropdownOpen;
  }

  public removeData(chart: any): void {
    chart.destroy()
    this.getData();
  }

  public setFromDate(date: number): void {
    this.fromDate = date
    let d = new Date();
    d.setDate(d.getDate() - this.fromDate);
    this.twoDaysAgoAsString = d.toISOString()
  }

  public setToDate(date: number): void {
    this.toDate = date
    let d = new Date();
    d.setDate(d.getDate() - this.toDate);
    this.nowAsISOString = d.toISOString()
  }

  public onCloseDialogClicked(): void {
    this.dialogRef.close();
  }

  public checkForAllErrors(index: number): string {
    let response: string = ''
    if (this.fehlercode[index] !== '000.000') { response += ' Fehlercode: ' + this.fehlercode[index] }
    if (this.fmFehlercode[index] !== '000.000') { response += ' FM Fehlercode: ' + this.fmFehlercode[index] }
    if (this.warncode[index] !== '000.000') { response += ' Warncode: ' + this.warncode[index] }
    return response
  }

  private createChart(): void {
    this.chart = new Chart("MyChart", {
      data: {
        labels: this.labelCollection,
        datasets: [
          {
            label: "Generatorleistung in Watt",
            data: this.generatorleistung,
            backgroundColor: 'blue',
            type: 'line',
            fill: false,
            borderColor: 'rgba(0, 0, 255, 0.198)',
            tension: 0.1,
            pointRadius: 2
          },
          {
            label: "Generatorspannung in V",
            data: this.generatorspannung,
            backgroundColor: 'red',
            fill: false,
            borderColor: 'rgba(255, 0, 0, 0.198)',
            tension: 0.1,
            pointRadius: 2,
            type: 'line'
          },
          {
            label: "Generatorstrom in A",
            data: this.generatorstrom,
            backgroundColor: 'green',
            fill: false,
            borderColor: 'rgba(0, 255, 255, 0.198)',
            tension: 0.1,
            pointRadius: 2,
            type: 'line'
          },
          {
            label: "Ladezustand Batterie in %",
            data: this.ladezustandBatterie,
            backgroundColor: 'yellow',
            fill: false,
            borderColor: 'rgba(0, 255, 0, 0.198)',
            tension: 0.1,
            pointRadius: 2,
            type: 'line'
          },
          {
            label: "Temperatur in °C",
            data: this.temperatur,
            backgroundColor: 'black',
            fill: false,
            borderColor: 'rgba(0, 0, 0, 0.198)',
            tension: 0.1,
            pointRadius: 2,
            type: 'line'
          },
          {
            label: "Verbleibender Gesamtkraftstoff in %",
            data: this.verbleibenderGesamtkraftstoff,
            backgroundColor: 'brown',
            fill: false,
            borderColor: 'rgba(0, 0, 0, 0.198)',
            tension: 0.1,
            pointRadius: 2,
            type: 'line'
          },
          {
            label: "Fehler/Warnung ",
            data: this.fehlerWarnungEreignisInteger,
            type: 'bar',
            backgroundColor: 'rgba(221, 26, 0, 0.2)',
            hoverBackgroundColor: 'rgba(221, 26, 0, 0.75)',
          }
        ]
      },
      options: {
        aspectRatio: 2.5,
        onClick: (evt: any) => {
          let error = evt.chart.tooltip.dataPoints[0].dataIndex;
          let raw = evt.chart.tooltip.dataPoints[0].raw;
          if ((this.fehlercode[error] !== '000.000')) { window.open(this.efoyBaseURL + this.fehlercode[error], '_blank') }
          if ((this.fmFehlercode[error] !== '000.000')) { window.open(this.efoyBaseURL + this.fmFehlercode[error], '_blank') }
          if ((this.warncode[error] !== '000.000')) { window.open(this.efoyBaseURL + this.warncode[error], '_blank') }

        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (context) => {
                if (context.dataset.label === 'Fehler/Warnung ') {
                  let label = context.dataset.label || '';
                  if (context) {
                    if (context.raw === 40 || context.raw === 60 || context.raw === 80) { label += this.checkForAllErrors(context.dataIndex) }

                  }
                  return label;
                } else return
              }
            }
          }
        }
      },
      plugins: [

        {
          id: 'customCanvasBackgroundColor',
          beforeDraw: function (chart: any) {
            const ctx = chart.ctx;
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
          }
        }

      ]
    });
  }
}