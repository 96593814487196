import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ProjectImageData } from 'src/app/project/shared/interfaces/project-image-data.interface';
import { SelectInput } from 'src/app/shared/dropdown/shared/select-input.class';
import { getPrioritySelect } from '../../shared/enum/priority.enum';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { ProjectService } from 'src/app/project/shared/services/project.service';

@Component({
  selector: 'avs-add-aq-time-switch',
  templateUrl: './add-aq-time-switch.component.html',
  styleUrls: ['./add-aq-time-switch.component.css']
})
export class AddAqTimeSwitchComponent implements OnInit {
  @Input() images: ProjectImageData[] = [];
  @Input() projectId?: number;
  @Output() refIsOneTime: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() refIsTouched: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() refIsWithBlinkerTimer: EventEmitter<boolean> = new EventEmitter<boolean>();
  public selectedImage?: ProjectImageData;
  public allImages: ProjectImageData[] = [];

  public isFunctionDropdownOpen: boolean = false;
  public functionSelect: SelectInput[] = [{ name: 'Aus', id: 0 }, { name: 'An', id: 1 }, { name: 'Blinkend', id: 2 }];
  public selectedFunction: SelectInput = { name: "Funktion" };

  public isPriorityDropdownOpen: boolean = false;
  public prioritySelect: SelectInput[] = getPrioritySelect();
  public selectedPriority: SelectInput = { name: "Priorität" };
  
  public starttime: string = "08:00:00";
  public endtime: string = "16:00:00";
  public isOneTime: boolean = false;
  public isWithBlinkerTimer: boolean = false;
  public startDate?: NgbDate;
  public endDate?: NgbDate;
  public form: FormGroup;
  public untouched: boolean = true;
  protected readonly environment = environment;
  constructor(private rootFormGroup: FormGroupDirective,
    private projectService: ProjectService
  ) {
    this.allImages = this.images;
    this.form = this.rootFormGroup.control.get("timerSwitchForm") as FormGroup;
  }

  public ngOnInit(): void {
    this.onFunctionItemClicked({ name: "An", id: 1 });
    this.onPriorityItemClicked({ name: "Mittel", id: 2 });
    this.getAllImages()
  }

  private getAllImages(): void {
    setTimeout(() => {
      this.projectService.getAllRouteStationImages(this.projectId as number).subscribe(images => {
        if(images.length === 1){  this.selectedImage = images[0];   this.form.patchValue({ tlS_Code: images[0].stellcode }); this.onTouchedChange() }
        this.images = images;
        this.allImages = images;
        this.rotateImagesPaths(images);
      });
    }, 1000);
  }

  public onProjectImageClicked(image: ProjectImageData) {
    if (this.images.length > 1) { this.allImages = this.images }
    if (this.images) {
      if (this.images.length > 1) {
        this.selectedImage = image
        this.images = [];
        this.images.push(image)
        this.form.patchValue({ tlS_Code: this.selectedImage?.stellcode })
        this.untouched = false;
        this.onTouchedChange()
      }
      else if (this.images.length === 1) {
        this.images = this.allImages;
          this.onTouchedChange()
      }
    }
  }

  public rotateImagesPaths(images: ProjectImageData[]): void {
    images.forEach(image => {
      if (image.isAnimated && image.animatedImageIds!.length > 0) { /* für jedes Bild, das für die RouteStation gesetzt wird, wird nun separat geprüft ob ein animatedImageId vorhanden ist */
        this.rotatePathForId(image)
      }
    });
  }

  public rotatePathForId(image: ProjectImageData): void {
    const myValueList = image.animatedImageIds!;

    let i = 0;
    const id = setInterval(() => {
      image.path = this.getPathByImageId(myValueList[i++]); /* iteration durchs animatedimage array, und zieht sich dasnn über die id, den path */
      if (i === myValueList.length) {
        i = 0;
      }
    }, 1000);
  }

  public getPathByImageId(imageId: number) {
    if (this.allImages) {
      let imageObject = this.allImages.find((x) => x.imageId == imageId)
      return imageObject?.path as string
    }
    else return
  }

  public onEnterStartTimeChange(newValue: string): void {
    this.starttime = newValue;
    this.form.patchValue({ starttime: this.starttime })
  }

  public onEnterEndTimeChange(newValue: string): void {
    this.endtime = newValue;
    this.form.patchValue({ endtime: this.endtime })
  }

  public onFunctionItemClicked(item: SelectInput): void {
    this.isFunctionDropdownOpen = false;
    this.selectedFunction.name = item.name
    this.selectedFunction.id = item.id
    let funcId = item.id as number
    this.form.patchValue({ tlS_Function: funcId })
    if (funcId !== 2) {

      this.form.patchValue({ tlS_Time: 0 })
    }
  }

  public onFunctionDropdownClicked(): void {
    this.isFunctionDropdownOpen = !this.isFunctionDropdownOpen;
  }

  public onPriorityItemClicked(item: SelectInput): void {
    this.isPriorityDropdownOpen = false;
    this.selectedPriority.name = item.name
    this.selectedPriority.id = item.id
    let prioId = item.id as number
    this.form.patchValue({ priority: prioId })
  }

  public onPriorityDropdownClicked(): void {
    this.isPriorityDropdownOpen = !this.isPriorityDropdownOpen;
  }

  public onOneTimeChange(): void {
    this.isOneTime = !this.isOneTime
    this.refIsOneTime.emit(this.isOneTime)
    this.refIsTouched.emit(false)
  }
  public isWithBlinkerTimerChange(): void {
    this.isWithBlinkerTimer = !this.isWithBlinkerTimer
    this.refIsWithBlinkerTimer.emit(this.isWithBlinkerTimer)
  }

  public onTouchedChange(): void {
    this.refIsTouched.emit(true)
  }

  public onStartDateSelect(newValue: NgbDate) {
    this.startDate = newValue;
    this.form.patchValue({ startdate: { year: newValue.year, month: newValue.month, day: newValue.day } })
    this.refIsTouched.emit(true)
  }

  public onEndDateSelect(newValue: NgbDate) {
    this.endDate = newValue;
    this.form.patchValue({ enddate: { year: newValue.year, month: newValue.month, day: newValue.day } })
  }
}

