import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectRouteStation } from "../../project/shared/interfaces/project-route-station.interface";
import { RouteStationService } from "../../project/shared/services/route-station.service";
import { DeviceDataService } from "../shared/services/device-data.service";
import { Subscription } from 'rxjs';
import { Project } from 'src/app/project/shared/interfaces/project.interface';
import { ProjectGroup } from 'src/app/project/shared/interfaces/project-group.interface';
import { GroupService } from 'src/app/project/shared/services/group.service';
import { ProjectService } from 'src/app/project/shared/services/project.service';

@Component({
  selector: 'avs-device-menu',
  templateUrl: './device-menu.component.html',
  styleUrls: ['./device-menu.component.css']
})
export class DeviceMenuComponent implements OnInit, OnDestroy {
  public routeStations: ProjectRouteStation[] | undefined;
  public activeRouteStation: ProjectRouteStation | undefined;
  public searchInput: string = "";
  public filteredStations: ProjectRouteStation[] = [];
  public projects: Project[] = [];
  public projectGroups: ProjectGroup[] = [];

  public deviceDataSubScription?: Subscription;
  public activeRouteStationSubscription?: Subscription;



  constructor(private readonly deviceDataService: DeviceDataService,
    private groupService: GroupService,
    private projectService: ProjectService,
    private routeStationService: RouteStationService) {
    this.deviceDataSubScription = deviceDataService.routeStations.subscribe(stations => {
      this.routeStations = stations;
    });
    this.activeRouteStationSubscription = deviceDataService.activeRouteStation.subscribe(activeStation => {
      this.activeRouteStation = activeStation;
    });

  }

  ngOnInit() {
    this.getRouteStations();
    this.groupService.getAllProjectGroups().subscribe(groups => {
      this.projectGroups = groups;
    });
    this.projectService.getProjects().subscribe(projects => {
      this.projects = projects;
    });
  }

  public ngOnDestroy(): void {
    this.deviceDataSubScription?.unsubscribe();
    this.activeRouteStationSubscription?.unsubscribe();
  }

  public onSearchInputChanged(event: string): void {
    this.searchInput = event;
    this.filterRouteStations();
  }

  public onRouteStationClicked(routeStation: ProjectRouteStation): void {
    if (this.activeRouteStation === routeStation) {
      this.deviceDataService.updateActiveRouteStation(undefined);
    } else {
      this.deviceDataService.updateActiveRouteStation(routeStation);
    }
  }

  private getRouteStations(): void {
    this.routeStationService.getRouteStations().subscribe(stations => {
      this.deviceDataService.updateRouteStations(stations);
    });
  }

  private filterRouteStations(): void {
    if (this.routeStations) {
      this.filteredStations = this.routeStations.filter(station =>
        station.name.toLowerCase().includes(this.searchInput.toLowerCase()) ||
        station.samId.toString().includes(this.searchInput.toLowerCase())
      );
    }
  }

  public getProjectNameByGroupId(groupId: number): string {
    /* brauche jetzt alle gruppen, damit ich innerhalb der gruppen nach der zugehörigen
    projektId suchen kann und dann brauch ich alle (wenn der projektnamme nicht in der gruppe ist) 
    alle projekte damit ich auf den namen zugreifen kann */
    let projectId = this.projectGroups.find(group => group.id === groupId)?.projectId;
    let projectName = this.projects.find(project => project.id === projectId)?.name;
    return projectName ? projectName : "";
  }



}
