import { Injectable } from "@angular/core";
import { HttpService } from "../../../core/shared/http.service";
import { DataEndChannel } from "../interfaces/data-end-channel.interface";
import { Observable } from "rxjs";
import { DataEndChannelConnection } from "../interfaces/data-end-channel-connection.interface";
import { Fallbacksettings } from "../../project-settings/shared/fallbacksettings";

@Injectable({
    providedIn: 'root'
})
export class DataEndChannelService {
    constructor(private readonly httpService: HttpService) { }

    public createNewDataEndChannel(dataEndChannel: DataEndChannel): Observable<DataEndChannel> {
        return this.httpService.post<DataEndChannel>('DataEndChannel', dataEndChannel);
    }

    public setDisplayType(channelId: number, displayId: number | string): Observable<any> {
        return this.httpService.post<any>('DataEndChannel/' + channelId + '/Display/' + displayId, {});
    }

    public setRadarType(channelId: number, radarId: number | string): Observable<any> {
        return this.httpService.post<any>('DataEndChannel/' + channelId + '/Radar/' + radarId, {});
    }

    public editDataEndChannel(id: number, dataEndChannel: DataEndChannel): Observable<DataEndChannel> {
        return this.httpService.put('DataEndChannel/' + id, dataEndChannel);
    }

    public deleteDataEndChannel(id: number): Observable<any> {
        return this.httpService.delete<any>('DataEndChannel/' + id);
    }

    public getDataEndChannelConection(id: number): Observable<DataEndChannelConnection> {
        return this.httpService.get<DataEndChannelConnection>('DataEndChannel/' + id + '/connection');
    }

    public assignDataEndChannelToTimerSwitch(deId: number, timerId: number): Observable<any> {
        return this.httpService.get<any>('DataEndChannel/' + deId + '/timerswitch/' + timerId);
    }

    public deleteDataEndChannelTimerAssignment(deId: number): Observable<any> {
        return this.httpService.delete<any>('DataEndChannel/' + deId + '/timerswitch');
    }


    public setFallbackSettings(id: number, settings: Fallbacksettings): Observable<any> {
        return this.httpService.put('DataEndChannel/' + id + '/display/fallbacksettings', settings);
    }

    public setServerParameter(id: number, height: number, offset: number): Observable<any> {
        let parameter = {
            height: height,
            offset: offset
        }
        return this.httpService.put('DataEndChannel/' + id + '/serverParameter', parameter);
    }
}
