<mat-card class="mt-1 mb-4">
    <mat-card-header>
        <div class="d-flex justify-content-start w-100 position-relative">
            <h4>Berichte </h4>
            <div class="d-flex justify-content-center">
                <button mat-icon-button [matMenuTriggerFor]="menuReport" aria-label="Report settings menu"
                    class="position-absolute" style="top: -21px; right: -16px; ">
                    <mat-icon style="font-size: 26px ">settings</mat-icon>
                </button>
                <mat-menu #menuReport="matMenu">
                    <button mat-menu-item (click)="openChangelogConfigOverviewModal()">
                        <img src="assets/icons/problem_24dp_FILL0_wght400_GRAD0_opsz24.svg">
                        <span class="ms-1">Berichteinstellungen einsehen</span>
                    </button>
                    <button mat-menu-item (click)="openChangelogConfigurationModal()">
                        <img src="assets/icons/person_alert_24dp_FILL0_wght400_GRAD0_opsz24.svg">
                        <span class="ms-1">Benachrichtigung anlegen</span>
                    </button>
                </mat-menu>
            </div>
        </div>
    </mat-card-header>
    <mat-card-content>
        <div class="d-flex justify-content-between position-relative">
            <div class="d-flex">
                <div>
                    <mat-form-field *ngIf="activeTab === 'Projekt-Übersicht'" class="example-form-field"
                        (click)="showTree = true;">
                        <mat-label>Suche</mat-label>
                        <input matInput type="text" [(ngModel)]="searchFilter"
                            (keyup)="filterDataSource(); showTree = true;" placeholder="Projektname...">
                        <button *ngIf="searchFilter" matSuffix mat-icon-button aria-label="Clear" (click)="resetTree()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-form-field *ngIf="activeTab === 'Geräte-Übersicht'" class="example-form-field"
                        (click)="showTree = true;">
                        <mat-label>Suche</mat-label>
                        <input matInput type="text" [(ngModel)]="searchFilterTree"
                            (keyup)="filterDataSourceTree(); showTree = true;" placeholder="RouteStationname...">
                        <button *ngIf="searchFilterTree" matSuffix mat-icon-button aria-label="Clear"
                            (click)="resetTreeTree()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                <div class="ms-2" *ngIf="activeTab === 'Projekt-Übersicht'">
                    <mat-form-field appearance="fill">
                        <mat-label>Projekt Status</mat-label>
                        <mat-select [(value)]="selectedStatus" (selectionChange)="filterDataSource()">
                            <mat-option value="alle">alle Projekte</mat-option>
                            <mat-option value="aktiv">aktive Projekte</mat-option>
                            <mat-option value="inaktiv">inaktive Projekte</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>


                <div class="ms-2" *ngIf="activeTab === 'Projekt-Übersicht' || activeTab === 'Geräte-Übersicht'">
                    <mat-form-field class="example-form-field">
                        <mat-label>Zeitraum wählen</mat-label>
                        <mat-date-range-input [formGroup]="range" [rangePicker]="rangePicker">
                            <input matStartDate formControlName="start" placeholder="Startdatum" [min]="minDate"
                                [max]="maxDate">
                            <input matEndDate formControlName="end" placeholder="Enddatum" [max]="maxDate">
                        </mat-date-range-input>
                        <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
                        <mat-date-range-picker #rangePicker>
                            <mat-date-range-picker-actions>
                                <button mat-button matDateRangePickerCancel>Abbrechen</button>
                                <button mat-raised-button color="primary" matDateRangePickerApply>Ok</button>
                            </mat-date-range-picker-actions>
                        </mat-date-range-picker>
                    </mat-form-field>
                </div>
            </div>
            <div>
                <div class="ms-2">
                    <div class="d-flex justify-content-end">
                        <!--                         <button *ngIf="activeTab === 'Projekt-Übersicht'" mat-raised-button color="primary"
                            (click)="createReport()" class="btn btn-sm rounded-0 me-2">
                            Bericht erstellen
                        </button>
                        <button *ngIf="activeTab === 'Geräte-Übersicht'" mat-raised-button color="primary"
                            (click)="createReport()" class="btn btn-sm rounded-0 me-2">
                            Bericht erstellen
                        </button> -->
                        <button *ngIf="activeTab === 'Projekt-Übersicht'" mat-raised-button color="primary"
                            (click)="createEngineReport()" class="btn btn-sm rounded-0 me-2">
                            Schaltgrundentscheid
                        </button>
                        <button *ngIf="activeTab === 'Geräte-Übersicht'" mat-raised-button color="primary"
                            (click)="createEngineReport()" class="btn btn-sm rounded-0 me-2">
                            Schaltgrundentscheid
                        </button>
                        <!-- nur anzeigen, wenn eine routeStation mit min 1 SENSOR ausgewählt wurde -->
                        <div class="d-flex flex-column">
                            <button
                                *ngIf="activeTab === 'Geräte-Übersicht' && activeRouteStation?.deviceParameter?.radar!.length > 0"
                                mat-raised-button color="primary" (click)="getMQChangelog()"
                                class="btn btn-sm rounded-0 me-2" style="width: 244px;">
                                Bericht für Verkehrsdaten erstellen
                            </button>
                            <div class="mt-2"
                                *ngIf="activeTab === 'Geräte-Übersicht' && activeRouteStation?.deviceParameter?.radar!.length > 0">
                                <mat-form-field style="width: 244px;">
                                    <mat-label>Intervall</mat-label>
                                    <mat-select [(value)]="intervall">
                                        <mat-option [value]="option" *ngFor="let option of intervalls">{{ option
                                            }} min</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <!--                             <mat-form-field > 
                                <mat-label>Intervall</mat-label>
                                <input matInput [(ngModel)]="intervall" type="number" min="1" max="60" step="5"
                                    class="form-control" style="width: 50px;" placeholder="Intervall">
                            </mat-form-field> -->
                            <!--  <input appearance="fill"
                                *ngIf="activeTab === 'Geräte-Übersicht' && activeRouteStation?.deviceParameter?.radar!.length > 0"
                                [(ngModel)]="intervall" type="number" min="1" max="60" class="form-control"
                                style="width: 50px;" placeholder="Intervall"> -->
                        </div>
                        <!-- TODO: nur anzeigen, wenn eine routeStation mit min 1 ANZEIGE ausgewählt wurde -->
                        <!--                         <button *ngIf="activeTab === 'Geräte-Übersicht'" mat-raised-button color="primary"
                            (click)="getAQChangelog()" class="btn btn-sm rounded-0">
                            Bericht für Anzeigedaten erstellen
                        </button> -->

                    </div>
                </div>
            </div>
        </div>
        <div
            style="border-top: 2px solid rgba(18, 18, 18, 0.509); border-bottom: 2px solid rgba(18, 18, 18, 0.252); margin-top: 8px; ">
            <div class=" d-flex justify-content-between">
                <div class="w-100">
                    <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="start" [selectedIndex]="selected.value"
                        (selectedIndexChange)="selected.setValue($event)" (selectedTabChange)="tabChanged($event)">
                        <mat-tab label="Projekt-Übersicht">
                            <!-- TODO: Tree als eigene Componente? -->
                            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                                <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding
                                    class="border-bottom border-opacity-25 tree-node-group"
                                    [ngClass]="selectedGroup.name == returnCleanName(node.name) ? 'selectedGroup' : ''">
                                    <button mat-icon-button disabled></button>
                                    <div (click)="setGroupIdByName(node)" style="cursor: pointer;"> &#x2022;
                                        {{ returnCleanName(node.name)}}
                                    </div> <!-- return methode einbauen, die den Namen wieder hübsch macht :D -->
                                </mat-tree-node>
                                <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding
                                    class="border-bottom border-opacity-25 tree-node-project"
                                    [ngClass]="selectedProject.name == returnCleanName(node.name) ? 'selectedProject' : ''">
                                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                                        <mat-icon class="mat-icon-rtl-mirror">
                                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                        </mat-icon>
                                    </button>
                                    <div (click)="setProjectIdByName(node)">
                                        <span class="" style="cursor: pointer;"> {{ returnCleanName(node.name)}} </span>
                                    </div>
                                </mat-tree-node>
                            </mat-tree>
                        </mat-tab>
                        <mat-tab label="Geräte-Übersicht">
                            <mat-tree [dataSource]="dataSourceRouteStations" [treeControl]="treeControl2">
                                <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding
                                    class="border-bottom border-opacity-25 tree-node-group"
                                    [ngClass]="selectedDeviceTree.name == node.name ? 'selectedGroup' : ''">
                                    <button mat-icon-button disabled></button>
                                    <div (click)="setSamIDByName(node)" style="cursor: pointer;"> <img class="me-1 img"
                                            height="18px" src="../../../assets/icons/pin-map.svg" alt="pin-map icon">
                                        {{node.name}}
                                    </div> <!-- return methode einbauen, die den Namen wieder hübsch macht :D -->
                                </mat-tree-node>
                            </mat-tree>
                        </mat-tab>
                        <ng-container *ngFor="let extendedChangelog of reportCollection; index as i">
                            <mat-tab
                                [label]="extendedChangelog.projectName + ' | ' + correctTimezone(extendedChangelog.fromDate).split('T')[0] + '_' + extendedChangelog.toDate.split('T')[0]">
                                <div class="m-2 card rounded-0 shadow" style="min-height: 500px">
                                    <div class="d-flex justify-content-between"> <span class="fw-semibold"> {{
                                            extendedChangelog.projectName }} | {{
                                            correctTimezone(extendedChangelog.fromDate).split('T')[0]
                                            }} _ {{ extendedChangelog.toDate.split('T')[0] }}</span>
                                        <div class="d-flex">
                                            <button *ngIf="changelogs" mat-icon-button
                                                [matMenuTriggerFor]="menuDownload" aria-label="Download menu">
                                                <mat-icon>download</mat-icon>
                                            </button>
                                            <mat-menu #menuDownload="matMenu">
                                                <button mat-menu-item
                                                    (click)="downloadReport('csv', extendedChangelog)">
                                                    <mat-icon>download</mat-icon>
                                                    <span>CSV</span>
                                                </button>
                                                <button mat-menu-item
                                                    (click)="downloadReport('ascii', extendedChangelog)">
                                                    <mat-icon>download</mat-icon>
                                                    <span>ASCII</span>
                                                </button>
                                                <button mat-menu-item
                                                    (click)="downloadReport('pdf', extendedChangelog)">
                                                    <mat-icon>download</mat-icon>
                                                    <span>PDF</span>
                                                </button>
                                            </mat-menu>
                                            <button mat-icon-button (click)="removeTab(i)">
                                                <mat-icon>close</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                    <avs-changelog-table
                                        [changelogs]="extendedChangelog.changelog"></avs-changelog-table>
                                </div>
                            </mat-tab>
                        </ng-container>

                        <ng-container *ngFor="let extendedChangelog of reportEngineCollection; index as i">
                            <mat-tab
                                [label]="extendedChangelog.projectName + ' | ' + correctTimezone(extendedChangelog.fromDate).split('T')[0] + '_' + extendedChangelog.toDate.split('T')[0]">
                                <div class="m-2 card rounded-0 shadow" style="min-height: 500px">
                                    <div class="d-flex justify-content-between"> <span class="fw-semibold"> {{
                                            extendedChangelog.projectName }} | {{
                                            correctTimezone(extendedChangelog.fromDate).split('T')[0]
                                            }} _ {{ extendedChangelog.toDate.split('T')[0] }}</span>
                                        <div class="d-flex">
                                            <button *ngIf="changelogs" mat-icon-button
                                                [matMenuTriggerFor]="menuDownload" aria-label="Download menu">
                                                <mat-icon>download</mat-icon>
                                            </button>
                                            <mat-menu #menuDownload="matMenu">
                                                <button mat-menu-item
                                                    (click)="downloadEngineReport('csv', extendedChangelog)">
                                                    <mat-icon>download</mat-icon>
                                                    <span>CSV</span>
                                                </button>
                                                <button mat-menu-item
                                                    (click)="downloadEngineReport('ascii', extendedChangelog)">
                                                    <mat-icon>download</mat-icon>
                                                    <span>ASCII</span>
                                                </button>
                                                <button mat-menu-item
                                                    (click)="downloadEngineReport('pdf', extendedChangelog)">
                                                    <mat-icon>download</mat-icon>
                                                    <span>PDF</span>
                                                </button>
                                            </mat-menu>
                                            <button mat-icon-button (click)="removeTab(i)">
                                                <mat-icon>close</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                    <avs-changelog-engine-table
                                        [changelogs]="extendedChangelog.changelog"></avs-changelog-engine-table>
                                </div>
                            </mat-tab>
                        </ng-container>


                        <ng-container *ngFor="let extendedChangelog of reportAQCollection; index as i">
                            <mat-tab
                                [label]="extendedChangelog.projectName + ' | ' + correctTimezone(extendedChangelog.fromDate).split('T')[0] + '_' + extendedChangelog.toDate.split('T')[0]">
                                <div class="m-2 card rounded-0 shadow" style="min-height: 500px">
                                    <div class="d-flex justify-content-between"> <span class="fw-semibold"> {{
                                            extendedChangelog.projectName }} | {{
                                            correctTimezone(extendedChangelog.fromDate).split('T')[0]
                                            }} _ {{ extendedChangelog.toDate.split('T')[0] }}</span>
                                        <div class="d-flex">
                                            <button mat-icon-button (click)="removeAQTab(i)">
                                                <mat-icon>close</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                    <div *ngFor=" let log of extendedChangelog.changelog; index as ic">
                                        {{extendedChangelog.samId}}
                                        <avs-changelog-aq-report [aqReports]="log.changeLogAqReport"
                                            [samId]="extendedChangelog.samId" [projectId]="extendedChangelog.projectId!"
                                            [toDate]="extendedChangelog.toDate"
                                            [fromDate]="extendedChangelog.fromDate"></avs-changelog-aq-report>
                                    </div>
                                </div>
                            </mat-tab>
                        </ng-container>
                        <ng-container *ngFor="let extendedChangelog of reportMQCollection; index as i">
                            <mat-tab
                                [label]="extendedChangelog.projectName + ' | ' + correctTimezone(extendedChangelog.fromDate).split('T')[0] + '_' + extendedChangelog.toDate.split('T')[0]">
                                <div class="m-2 card rounded-0 shadow" style="min-height: 500px">
                                    <div class="d-flex justify-content-between"> <span class="fw-semibold"> {{
                                            extendedChangelog.projectName }} | {{
                                            correctTimezone(extendedChangelog.fromDate).split('T')[0]
                                            }} _ {{ extendedChangelog.toDate.split('T')[0] }}</span>
                                        <div class="d-flex">
                                            <button mat-icon-button (click)="removeMQTab(i)">
                                                <mat-icon>close</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                    <div *ngFor=" let log of extendedChangelog.changelog; index as ic">
                                        <mat-card appearance="outlined" class="m-4">
                                            <mat-card-header> {{ ic === 0 ? 'Hauptfahrspur' : (ic + '
                                                .Überholspur')}}</mat-card-header>
                                            <avs-changelog-mq-graph [mqReports]="log.changeLogMqReport"
                                                [samId]="extendedChangelog.samId" [index]="ic"
                                                [fromDate]="extendedChangelog.fromDate"
                                                [toDate]="extendedChangelog.toDate"
                                                (genereatedJPGURL)="recievedJPGURL($event, extendedChangelog.samId, ic, extendedChangelog.fromDate, extendedChangelog.toDate)"></avs-changelog-mq-graph>
                                            <div>
                                                <div *ngFor=" let pdf of pdfURLObjects">
                                                    <ng-container
                                                        *ngIf="pdf.samId === extendedChangelog.samId && pdf.channel === ic">
                                                        <div class="d-flex justify-content-end">
                                                            <a [href]="pdf.pdfUrl" target="_blank"
                                                                class="btn btn-sm btn-primary rounded-0">PDF</a>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                                <avs-changelog-mq-report [mqReports]="log.changeLogMqReport"
                                                    [samId]="extendedChangelog.samId" [index]="ic "
                                                    style="height: 500px" [fromDate]="extendedChangelog.fromDate"
                                                    [toDate]="extendedChangelog.toDate"
                                                    (generatedPDFURL)="recievedPDFURL($event, extendedChangelog.samId, ic, extendedChangelog.fromDate, extendedChangelog.toDate)"
                                                    [projectId]="extendedChangelog.projectId!"
                                                    [jpgUrl]="filterURLs(extendedChangelog.samId, ic, extendedChangelog.fromDate, extendedChangelog.toDate)"></avs-changelog-mq-report>
                                            </div>
                                        </mat-card>
                                    </div>
                                </div>
                            </mat-tab>
                        </ng-container>
                    </mat-tab-group>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>

<div *ngIf="isLoading" class="overlay">
    <mat-spinner></mat-spinner>
    <div class="d-flex flex-column justify-content-center"
        style="position: absolute; top: 70%; left: 50%; transform: translate(-50%, -50%); z-index: 1001; background-color: rgba(255, 255, 255, 0.708); padding: 16px;">
        <h5> Der Ladevorgang könnte einen Moment in Anspruch nehmen, bitte warten...</h5>
        <div class="d-flex justify-content-center">
            <button class="btn btn-sm btn-danger rounded-0 mt-2" (click)="isLoading = false">
                Abbrechen
            </button>
        </div>
    </div>
</div>