<mat-dialog-content class="rounded-0">

    <div class="d-flex justify-content-between">
        <h5 class="fw-semibold">Sensor Einstellungen</h5>
        <img class="ms-3 avs-modal-close-btn mb-1" width="30" src="assets/icons/x.svg" alt="close-modal-icon"
            (click)="onCloseDialogClicked()">
    </div>


    <div class="d-flex justify-content-center flex-column">
        <div class="d-flex justify-content-center mb-2 flex-column">
            <span class="d-flex  fw-semibold text-secondary justify-content-center"> Höhe </span>
            <div class="d-flex">
                <input type="number" placeholder="Höhe" [required]="true" label="Höhe" [(ngModel)]="height" min="-15"
                    step="0.1" max="15" style="width: 240px !important" class="d-flex   justify-content-center">&nbsp; m
            </div>
        </div>
        <div class="d-flex justify-content-center mb-2 flex-column">

            <span class="d-flex  fw-semibold text-secondary justify-content-center"> Offset </span>
            <div class="d-flex">
                <input type="number" placeholder="Offset" [required]="true" label="Offset" [(ngModel)]="offset"
                    min="-15" step="0.1" max="15" style="width: 240px !important"
                    class="d-flex   justify-content-center">&nbsp; m
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
    <button class="btn btn-sm btn-outline-success rounded-0" (click)="onSubmitBtnClicked()">
        Erstellen
    </button>
</mat-dialog-actions>