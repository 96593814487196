import { Injectable } from "@angular/core";
import { HttpService } from "../../../core/shared/http.service";
import { Observable } from "rxjs";
import { DeviceParameter } from "../interfaces/device-parameter.interface";
import { HttpContentTypes } from "src/app/core/shared/http-content-types.enum";
import { DataEndChannelError } from "../../../project/shared/interfaces/data-end-channel-error.interface";

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  constructor(private httpService: HttpService) {}

  public getDeviceParameter(deviceId: number): Observable<DeviceParameter> {
    return this.httpService.get<DeviceParameter>('Tls/Device/NewGetAllParameter?deviceId=' + deviceId);
  }

  public getDeviceParameterForAllGroupStations(groupId: number): Observable<DeviceParameter[]> {
    return this.httpService.get<DeviceParameter[]>('Tls/Device/GetProjectSamsbyGroupId?GroupId=' + groupId);
  }

  public getAllChannelErrors(deviceId: number): Observable<DataEndChannelError[]> {
    return this.httpService.get<DataEndChannelError[]>('Tls/Device/GetAllChannelErrors?deviceId=' + deviceId);
  }

  public resetDevice(guid: string, deKanal: any): Observable<any> {
    const data = new FormData();
    var deKanalArr = deKanal;
    data.append('guid', guid.toString());
    for (var i = 0; i < deKanalArr.length; i++) {
      data.append('deKanalArr[]', deKanalArr[i]);
    }

    return this.httpService.get('Tls/Device/Reset?guid=' + guid.toString() + '&deKanal=' + deKanal.toString(), true);
  }

  public refreshDataFromChannels(guid: string): Observable<any> {
    return this.httpService.get<any>('Tls/Device/RefreshDataFromChannels?guid=' + guid);
  }

  public learnSmartMicro(guid:string, deKanal: number, status: number): Observable<any> {
    return this.httpService.get<any>('Tls/Device/LearnSmartMicro?guid=' + guid + '&deKanal=' + deKanal + '&status=' + status);
  }

  public getAllChannelErrorsByGroupId(groupId: number): Observable<any> {
    return this.httpService.get<any>('Tls/Device/GetAllChannelErrorsByGroupId?groupid=' + groupId);
  }

  public getSolarDetailsBySamIdForNewVersion(samId: number): Observable<any> {
    return this.httpService.get<any>('Tls/Device/GetSolarDetailsBySamIdForNewVersion?samId=' + samId);
  }
  public getFuellCellDetails(samId: number): Observable<any> {
    return this.httpService.get<any>('Tls/Device/GetFuelCellDetails?samId=' + samId);
  }

  /* POST
/Tls/Device/setAdecHeightOffset
Set Adec height and offset

Sample request:

  'height=2'  \
  'offset=1'  \
  'deKanal=2'

Parameters

No parameters
Request body

{
  "deviceId": 2147483647,
  "deKanal": 254,
  "height": 0,
  "offset": 0
 */

  public setAdecHeightOffset(deviceId: number, deKanal: number, height: number, offset: number): Observable<any> {
    return this.httpService.post<any>('Tls/Device/setAdecHeightOffset', {
      deviceId: deviceId,
      deKanal: deKanal,
      height: height,
      offset: offset
    });
  }
  
}
