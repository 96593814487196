import { Injectable } from '@angular/core';
import { Observable } from 'rxjs'
import { HttpService } from "../../../core/shared/http.service";
import { HttpContentTypes } from "../../../core/shared/http-content-types.enum";

@Injectable({
  providedIn: 'root'
})
export class StmService {

  constructor(private httpService: HttpService) { }


  setDigitalOutput(channel: string, id: number, status: boolean): Observable<any> {
    const formData = new FormData();
    formData.append('deKanal', `${channel}`)
    formData.append('deviceId', `${id}`)
    formData.append('status', `${status ? 1 : 0}`)
    return this.httpService.post('Tls/stm/digitalOutput', formData, true, HttpContentTypes.formData);
  }

  setSensorPowerSwitch(channel: string, id: number, status: boolean): Observable<any> {
    const formData = new FormData();
    formData.append('deKanal', `${channel}`)
    formData.append('deviceId', `${id}`)
    formData.append('status', `${status ? 1 : 0}`)
    return this.httpService.post('Tls/stm/sensorPowerSwitch', formData, true, HttpContentTypes.formData);
  }

  setLedSwitch(channel: string, id: number, status: boolean): Observable<any> {
    const formData = new FormData();
    formData.append('deKanal', `${channel}`)
    formData.append('deviceId', `${id}`)
    formData.append('status', `${status ? 1 : 0}`)
    return this.httpService.post('Tls/stm/ledSwitch', formData, true, HttpContentTypes.formData);
  }



}
