<div>
    <form class="example-form" [formGroup]="form">
    <div class="w-100 d-flex justify-content-center mb-2">
        <div class="d-flex justify-content-center mb-2 mt-2" style="width: 240px !important">
            <div class="w-100 avs-route-station-settings-dropdown-container justify-content-between">
                <span class="ms-1 fw-semibold text-secondary">Priorität</span>
                <div [ngClass]="isPriorityDropdownOpen ? 'border-danger' : ''"
                    class="border d-flex justify-content-between" role="button" (click)="onPriorityDropdownClicked()">
                    <div class="ms-2 p-1">{{ selectedPriority.name }}</div>
                    <img *ngIf="!isPriorityDropdownOpen" class="me-2" src="assets/icons/caret-down.svg"
                        alt="caret-down open select">
                    <img *ngIf="isPriorityDropdownOpen" class="me-2" src="assets/icons/caret-up.svg"
                        alt="caret-up close select">
                </div>
                <avs-dropdown [selectInput]="prioritySelect" [activeItem]="selectedPriority" 
                    (onItemClicked)="onPriorityItemClicked($event)" [isHeaderActive]="false"  
                    *ngIf="isPriorityDropdownOpen" class="avs-route-station-settings-dropdown-container">
                </avs-dropdown>
            </div>
        </div>
    </div>

    <avs-bilderkatalog  [images]="images" (myClick)="onProjectImageClicked($event)"></avs-bilderkatalog>
<!-- 
    <div class="row avs-project-images-container d-flex justify-content-center mb-2">
        <ng-container *ngFor="let image of images">
            <div class="mb-3 avs-project-images " (click)="onProjectImageClicked(image)">
                <div class="d-flex justify-content-center w-100">
                    <img [src]="environment.apiAddress + image.path" alt="Bilderkatalog">
                </div>
                <div class="small text-center">Stellcode: {{image.stellcode}}</div>
            </div>
        </ng-container>
    </div> -->

    <div class="w-100 d-flex justify-content-center mb-2">
        <div class="avs-route-station-settings-dropdown-container justify-content-between"
            style="width: 240px !important">
            <span class="ms-1 fw-semibold text-secondary">Funktion</span>
            <div [ngClass]="isFunctionDropdownOpen ? 'border-danger' : ''" class="border d-flex justify-content-between"
                role="button" (click)="onFunctionDropdownClicked()">
                <div class="ms-2 p-1">{{ selectedFunction.name }}</div>
                <img *ngIf="!isFunctionDropdownOpen" class="me-2" src="assets/icons/caret-down.svg"
                    alt="caret-down open select">
                <img *ngIf="isFunctionDropdownOpen" class="me-2" src="assets/icons/caret-up.svg"
                    alt="caret-up close select">
            </div>
            <avs-dropdown [selectInput]="functionSelect" [activeItem]="selectedFunction"
                (onItemClicked)="onFunctionItemClicked($event)" [isHeaderActive]="false" *ngIf="isFunctionDropdownOpen"
                class="avs-route-station-settings-dropdown-container">
            </avs-dropdown>
        </div>
    </div>

    <div *ngIf="selectedFunction.id == 2" class="w-100 d-flex justify-content-center mb-2">
        <input type="number" placeholder="Intervall in ms angeben" *ngIf="selectedFunction.id === 2" [required]="true"
            label="Blinker-Intervall in ms"  formControlName="tlS_Time" min="1" max="15">
    </div>
    <div class="w-100 d-flex justify-content-center mb-2">
        <mat-slide-toggle [checked]="isOneTime" color="primary" (change)="onOneTimeChange()">
            Einmalig
        </mat-slide-toggle>
    </div>

    <div *ngIf="isOneTime" class="w-100 d-flex justify-content-evenly mb-2 ms-2 me-2">

        <ngbd-datepicker-range-popup (startDate)="onStartDateSelect($event)"
            (endDate)="onEndDateSelect($event)"></ngbd-datepicker-range-popup>

    </div>


    <div *ngIf="!isOneTime" class="d-flex w-100 justify-content-center pe-4 " required>
        <mat-checkbox color="primary" class="example-margin" formControlName="monday">Mo</mat-checkbox>
        <mat-checkbox color="primary" class="example-margin" formControlName="tuesday">Di</mat-checkbox>
        <mat-checkbox color="primary" class="example-margin" formControlName="wednesday">Mi</mat-checkbox>
        <mat-checkbox color="primary" class="example-margin" formControlName="thursday">Do</mat-checkbox>
        <mat-checkbox color="primary" class="example-margin" formControlName="friday">Fr</mat-checkbox>
        <mat-checkbox color="primary" class="example-margin" formControlName="saturday">Sa</mat-checkbox>
        <mat-checkbox color="primary" class="example-margin" formControlName="sunday">So</mat-checkbox>
    </div>




    <div class="w-100 d-flex justify-content-evenly mb-2 ">
        <avs-time-input  class="" label="Startzeit" (valueChange)="onEnterStartTimeChange($event)" [value]="starttime"
            id="starttime" name="starttime">
        </avs-time-input>
        <avs-time-input class="ml-1" label="Endzeit" (valueChange)="onEnterEndTimeChange($event)" [value]="endtime"
            id="endtime" name="endtime">
        </avs-time-input>
    </div>
    <div class="w-100 d-flex justify-content-center mb-2">
        <mat-slide-toggle [checked]="isWithBlinkerTimer" color="primary" (change)="isWithBlinkerTimerChange()">
            Blinker
        </mat-slide-toggle>
    </div>

    </form>

</div>