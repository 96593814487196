import { Component, OnDestroy, OnInit } from '@angular/core';
import { DeviceDataService } from "../shared/services/device-data.service";
import { ProjectRouteStationWithParam } from "../../project/shared/interfaces/project-route-station.class";
import { GroupService } from "../../project/shared/services/group.service";
import { ProjectService } from "../../project/shared/services/project.service";
import { ProjectImageData } from "../../project/shared/interfaces/project-image-data.interface";
import { Subscription } from 'rxjs';
import { SchemaDataEndChannelError } from 'src/app/project/project-schema/shared/interfaces/schema-data-end-channel-error.interface';
import { DeviceService } from '../shared/services/device.service';

@Component({
  selector: 'avs-device-route-station',
  templateUrl: './device-route-station.component.html',
  styleUrls: ['./device-route-station.component.css']
})
export class DeviceRouteStationComponent implements OnInit, OnDestroy {
  public routeStationWithDeviceParam?: ProjectRouteStationWithParam;
  public projectImages: ProjectImageData[] = [];
  public routeStationWithDeviceParamWithoutDeChannels?: ProjectRouteStationWithParam;
  private subscriptionCollection: Subscription[] = [];
  private projectRouteStationsWithParamSubscription?: Subscription;
  public channelErrors: SchemaDataEndChannelError[] = [];

  constructor(private readonly deviceDataService: DeviceDataService,
    private readonly groupService: GroupService,
    private readonly projectService: ProjectService,
    private deviceService: DeviceService) {
    this.projectRouteStationsWithParamSubscription = deviceDataService.routeStationWithDeviceParam.subscribe(station => {
      this.routeStationWithDeviceParam = station;
      /*      this.routeStationWithDeviceParamWithoutDeChannels = this.filterDeChannels(station!); */
      if (station) {
        this.testBlock()
        if (station.routeStation.groupId !== null)
          this.getProjectImages(station.routeStation.groupId)
        this.getChannelErrorsByGroupId(station.routeStation.groupId)
      }
    });

  }

  public ngOnInit(): void {
    this.subscriptionCollection.push(this.projectRouteStationsWithParamSubscription!);
  }

  public ngOnDestroy(): void {
    this.subscriptionCollection.forEach(sub => sub.unsubscribe());
  }
  public getChannelErrorsByGroupId(groupId: number): void {
    if (groupId === null) return;
    this.deviceService.getAllChannelErrorsByGroupId(groupId).subscribe(channelErrors => {
      this.channelErrors = channelErrors
      if (channelErrors.length > 0)
        this.getRouteStationsChannelErrorsByRouteStationId(channelErrors)
    })
  }

  public testBlock() {

    let originalObject = this.routeStationWithDeviceParam?.routeStation
    const keyToRemove: keyof ProjectRouteStationWithParam['routeStation'] = 'dataEndChannels';

    const newObject = Object.assign({}, originalObject);
    delete newObject[keyToRemove];
    this.routeStationWithDeviceParam!.routeStation = newObject;
  }


  public getRouteStationsChannelErrorsByRouteStationId(channelErrors: SchemaDataEndChannelError[]): void {
    if (channelErrors.length === 0 || channelErrors === null) return;
    channelErrors.forEach(channelError => {
      if (channelError.deviceId !== this.routeStationWithDeviceParam?.routeStation.samId) return;
      channelError.errors.find(error => {
        let routeStation = this.routeStationWithDeviceParam
        if (error.de === 11 && error.fg === 6) {
          if (routeStation) {
            routeStation.deviceParameter.solar.panelError1 = true
            routeStation.deviceParameter.solar.channelError = true;
          }
        }
        if (error.de === 12 && error.fg === 6) {
          if (routeStation) {
            routeStation.deviceParameter.solar.panelError2 = true
            routeStation.deviceParameter.solar.channelError = true;
          }
        }
        if (error.fg === 6 && (error.de === 10 || error.de === 11 || error.de === 12 || error.de === 13 || error.de === 14) && error.localbusAddress === 10) {
          if (routeStation) {

            routeStation!.deviceParameter.solar.channelError = error.error;
          }
        }
        if (error.fg === 4 && error.de === 1) {
          routeStation!.deviceParameter.led.channelError = true;
        }
        if (error.fg === 1) {
          routeStation?.deviceParameter.radar?.forEach((radar) => {
            if (radar.de === error.de) { radar.channelError = true }
          })
        }
        if (error.fg === 4 && error.de === 2) {
          routeStation!.deviceParameter.blinker.channelError = true;
        }
      })
    })
  }


  // function to remove DataEndChannel Object from routestation array
  public filterDeChannels(station: ProjectRouteStationWithParam) {
    delete this.routeStationWithDeviceParam?.routeStation.dataEndChannels;
    return this.routeStationWithDeviceParam;
  }


  private getProjectImages(groupId: number) {
    if (groupId === null) return;
    this.groupService.getProjectGroup(groupId).subscribe(group => {
      this.projectService.getAllRouteStationImages(group.projectId).subscribe(images => {
        this.projectImages = images;
      });
    });
  }

}
