import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Chart } from 'chart.js';
import { ReportService } from 'src/app/dashboard/shared/services/report.service';

@Component({
  selector: 'avs-stm-chart',
  templateUrl: './stm-chart.component.html',
  styleUrls: ['./stm-chart.component.css']
})
export class StmChartComponent {

  public chart: any; public chart2: any;
  public samId: number;
  public now = new Date();
  public nowAsISOString: string;
  public twoDaysAgoAsString: string;
  public chartData: any;
  public chartData2: any;
  public labelCollection: string[] = [];
  public resultCollection: number[] = [];
  public labelCollection2: string[] = [];
  public resultCollection2: number[] = [];
  public fromDate: number = 2;
  public toDate: number = 0;
  public noData1?: boolean;
  public noData2?: boolean;
  public dataAge: number = 0;
  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    private reportService: ReportService,
    private dialogRef: MatDialogRef<StmChartComponent>) {
    let lastData = new Date(this.data.deviceParameter.solar.dateTime)
    let today = new Date()
    let diff = today.getTime() - lastData.getTime()
    let diffDays = diff / (1000 * 60 * 60 * 24)
    this.dataAge = diffDays
    if (this.dataAge !== undefined && this.dataAge > 1) { this.toDate = Math.round(this.dataAge); this.fromDate = Math.round(this.dataAge) + 2 }
    this.samId = this.data.routeStation.samId
    this.nowAsISOString = this.now.toISOString()
    let d = new Date();
    d.setDate(d.getDate() - 2);
    this.twoDaysAgoAsString = d.toISOString()
  }

  ngOnInit(): void {
    this.getData();
  }

  private getData(): void {
    this.labelCollection = [];
    this.resultCollection = [];
    this.reportService.getRouteStationsSTMWithFilterNew(this.samId, this.twoDaysAgoAsString, this.nowAsISOString, 1).subscribe(x => {
      if (x === null) { this.noData1 = true; return; }
      this.chartData = x;
      this.chartData.forEach((element: { measuredAt: string, deviceData: { activeAsNumber: number, active: boolean }}) => {
        console.log(element)
        element.deviceData.activeAsNumber = this.getActiveAsNumber(element.deviceData.active)
        let date = element.measuredAt.split('T')
        let splittedDate = date[0].split('-')
        let time = date[1].split(':')
        let hours = time[0]
        let mins = time[1]
        let dateTime = '  ' + splittedDate[2] + '.' + splittedDate[1] + '.' + splittedDate[0].substring(2) + ' ' + hours + ':' + mins
        this.labelCollection.push(dateTime)
      })
      this.chartData.forEach((element: { deviceData: { activeAsNumber: number; }}) => {

        this.resultCollection.push(element.deviceData.activeAsNumber)
      });
      this.createChart1();
    })
    this.labelCollection2 = [];
    this.resultCollection2 = [];
    this.reportService.getRouteStationsSTMWithFilterNew(this.samId, this.twoDaysAgoAsString, this.nowAsISOString, 2).subscribe(x => {
      if (x === null) { this.noData2 = true; return; }
      this.chartData2 = x;
      this.chartData2.forEach((element: { measuredAt: string, deviceData: { activeAsNumber: number, active: boolean }}) => {
        element.deviceData.activeAsNumber = this.getActiveAsNumber(element.deviceData.active)
        let date = element.measuredAt.split('T')
        let splittedDate = date[0].split('-')
        let time = date[1].split(':')
        let hours = time[0]
        let mins = time[1]
        let dateTime = '  ' + splittedDate[2] + '.' + splittedDate[1] + '.' + splittedDate[0].substring(2) + ' ' + hours + ':' + mins
        this.labelCollection2.push(dateTime)
      })
      this.chartData2.forEach((element: { deviceData: { activeAsNumber: number; }}) => {

        this.resultCollection2.push(element.deviceData.activeAsNumber)
      });
      this.createChart2();
    })
  }

  public getActiveAsNumber(active: boolean) {
    if (active === false) {
      return 0;
    }
    else if (active === true) {
      return 1;
    }
    return -1; // Default return value if status does not match any condition
  }

  public removeData(chart: any): void {
    chart.destroy()
    this.chart2.destroy()
    this.getData();
  }

  public setFromDate(date: number): void {
    this.fromDate = date
    let d = new Date();
    d.setDate(d.getDate() - this.fromDate);
    this.twoDaysAgoAsString = d.toISOString()
  }

  public setToDate(date: number): void {
    this.toDate = date
    let d = new Date();
    d.setDate(d.getDate() - this.toDate);
    this.nowAsISOString = d.toISOString()
  }

  public onCloseAddTimeSwitchClicked(): void {
    this.dialogRef.close();
  }

  private createChart1(): void {
    this.chart = new Chart("MyChart", {
      type: 'line',

      data: {
        labels: this.labelCollection,
        datasets: [
          {
            label: "STM Status 1",
            data: this.resultCollection,
            backgroundColor: 'red',
            fill: false,
            borderColor: 'rgba(0, 0, 0, 0.198)',
            tension: 0.1,
            pointRadius: 2
          },
        ]
      },
      options: {
        aspectRatio: 2.5,
        scales: {

          y: {
            grid:
            {
              display: false
            },
            beginAtZero: true,
            ticks: {

              callback: function (value: any, index: any, values: any) {
                if (value === 0) {
                  return 'deaktiv'
                }
                else if (value === 1) {
                  return 'aktiv'
                }
                else {
                  return ''
                }
              },

            }

          },
          x: {
            grid:
            {
              display: false
            },
          }

        }
      }

    });
  }

  private createChart2(): void {
    this.chart2 = new Chart("MyChart2", {
      type: 'line',

      data: {
        labels: this.labelCollection2,
        datasets: [
          {
            label: "STM Status 2",
            data: this.resultCollection2,
            backgroundColor: 'blue',
            fill: false,
            borderColor: 'rgba(0, 0, 0, 0.198)',
            tension: 0.1,
            pointRadius: 2
          },
        ]
      },
      options: {
        aspectRatio: 2.5,
        scales: {
          y: {
            grid:
            {
              display: false
            },
            beginAtZero: true,
            ticks: {
              callback: function (value: any, index: any, values: any) {
                if (value === 0) {
                  return 'deaktiv'
                }
                else if (value === 1) {
                  return 'aktiv'
                }
                else {
                  return ''
                }
              }
            }
          },
          x: {
            grid:
            {
              display: false
            },
          }
        }
      }

    });
  }
}