import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Radar } from 'src/app/device/shared/interfaces/radar.interface';
import { DeviceService } from 'src/app/device/shared/services/device.service';
import { SolarDetailedData } from 'src/app/project/shared/interfaces/solar-detailed-data.interface';
import { SolarPanelErrorLuminax, getSolarPanelErrorLuminaxString, SolaWarningStatus, getSolaWarningStatusString, SolarErrorMessagesStatus, getSolarErrorMessagesStatusString, getSolarPanelTypeString, SolarPanelType, getSolarPanelErrorVictronString, SolarPanelErrorVictron } from 'src/app/project/shared/enums/solar-error-messages.enum';


@Component({
  selector: 'avs-solar-details',
  templateUrl: './solar-details.component.html',
  styleUrls: ['./solar-details.component.css']
})
export class SolarDetailsComponent implements OnInit {
  public samId?: number;
  public detailObj: SolarDetailedData[] = [];
  protected readonly getSolarPanelErrorLuminaxString = getSolarPanelErrorLuminaxString;
  protected readonly getSolaWarningStatusString = getSolaWarningStatusString;
  protected readonly getSolarErrorMessagesStatusString = getSolarErrorMessagesStatusString;
  protected readonly getSolarPanelTypeString = getSolarPanelTypeString;
  protected readonly getSolarPanelErrorVictronString = getSolarPanelErrorVictronString;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private deviceService: DeviceService,
    private dialogRef: MatDialogRef<SolarDetailsComponent>
  ) { }

  public ngOnInit(): void {
    this.samId = this.data.data.routeStation.samId;
    if (this.samId) {
      this.deviceService.getSolarDetailsBySamIdForNewVersion(this.samId).subscribe(element => {
        this.detailObj = element;
      })
    }
  }


  lastSunday(year: number, month: number) {
    let date = new Date(year, month, 1, 12);
    let weekday = date.getDay();
    let dayDiff = weekday === 0 ? 7 : weekday;
    let lastSunday = date.setDate(date.getDate() - dayDiff);

    return date;
  }

  isCEST(d: Date): boolean {
    let testD = new Date(d);
    let year = testD.getFullYear();
    let startCET: Date = this.lastSunday(year, 3);
    let endCET: Date = this.lastSunday(year, 10);
    return !(startCET < testD && testD > endCET);
  }

  offset(d: Date): string {
    return this.isCEST(d) ? 'UTC+2' : 'UTC+1'
  }

  public onCloseClicked(): void {
    this.dialogRef.close();
  }
}
