import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProjectService } from 'src/app/project/shared/services/project.service';
import { SelectInput } from 'src/app/shared/dropdown/shared/select-input.class';

@Component({
  selector: 'avs-fallback-flasher-settings',
  templateUrl: './fallback-flasher-settings.component.html',
  styleUrls: ['./fallback-flasher-settings.component.css']
})
export class FallbackFlasherSettingsComponent {

  public isFunctionDropdownOpen: boolean = false;
  public functionSelect: SelectInput[] = [{ name: 'Aus', id: 0 }, { name: 'An', id: 1 }/* , { name: 'Blinkend', id: 2 } */];
  public selectedFunction: SelectInput = { name: "Funktion" };
  public onFunctionSelected: boolean = true;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<FallbackFlasherSettingsComponent>,
    private projectService: ProjectService) { }


  public onFunctionItemClicked(item: SelectInput): void {
    this.onFunctionSelected = false;
    this.isFunctionDropdownOpen = false;
    this.selectedFunction.name = item.name
    this.selectedFunction.id = item.id
    let funcId = item.id as number
  }
  public onCloseDialogClicked(): void {
    this.dialogRef.close();
  }
  public onFunctionDropdownClicked(): void {
    this.isFunctionDropdownOpen = !this.isFunctionDropdownOpen;
  }

  public onSubmitBtnClicked(): void {
    this.dialogRef.close({
      stellcode: 61,
      funktions: this.selectedFunction.id as number
    });
  }
}
