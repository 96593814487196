<mat-dialog-content class="rounded-0 overflow-x-hidden">
    <div class="d-flex justify-content-between">
        <h5 class="fw-semibold">Wechselbild erstellen</h5>
        <img class="ms-3 avs-modal-close-btn mb-1" width="30" src="assets/icons/x.svg" alt="close-modal-icon"
            (click)="onCloseModalClicked()">
    </div>

    <div class="d-flex justify-content-center align-items-center flex-column">
        <ng-container *ngFor="let image of animatedImages; let i = index">
            <img [src]="environment.apiAddress + getPathByImageId(image.imageId)" alt="Bilderkatalog" class="mt-2">
            <div class="d-flex flex-column ">
                <div class="d-flex justify-content-between align-items-center ">
                    <span> Position: {{ i+1 }} </span> <img class="avs-icon-delete mt-2 mb-1 p-1"
                        src="assets/icons/trash.svg" alt="icon-delete dataEndChannel" (click)="onRemoveImageClicked(i)"
                        style="height: 32px">
                </div>
                <input type="number" placeholder="Anzeigedauer in ms" [required]="true" label="Anzeigedauer in ms"
                    [(ngModel)]="image.duration">
            </div>
        </ng-container>
    </div>
    <div class="d-flex justify-content-around">
        <div>
            <div class="d-flex justify-content-center "> Stellcodeauswahl</div>
            <div class="w-100 d-flex justify-content-center ms-2 mb-2">
                <div class="avs-route-station-settings-dropdown-container justify-content-between">
                    <div [ngClass]="isFunctionDropdownOpen ? 'border-danger' : ''"
                        class="border d-flex justify-content-between" role="button"
                        (click)="onFunctionDropdownClicked()">
                        <div class="ms-2 p-1" [ngClass]="onGetSelectedClass()">{{ selectStellcode.name }}</div>
                        <img *ngIf="!isFunctionDropdownOpen" class="me-2" src="assets/icons/caret-down.svg"
                            alt="caret-down open select">
                        <img *ngIf="isFunctionDropdownOpen" class="me-2" src="assets/icons/caret-up.svg"
                            alt="caret-up close select">
                    </div>
                    <avs-dropdown [selectInput]="stellcodeSelect" [activeItem]="selectStellcode"
                        (onItemClicked)="onFunctionItemClicked($event)" [isHeaderActive]="false"
                        *ngIf="isFunctionDropdownOpen" class="avs-route-station-settings-dropdown-container">
                    </avs-dropdown>
                </div>
            </div>
        </div>
        <div *ngIf="!singleRouteStation">
            <div class="d-flex justify-content-center "> Gruppenauswahl</div>
            <div class="w-100 d-flex justify-content-center ms-2 mb-2">
                <div class="avs-route-station-settings-dropdown-container justify-content-between">
                    <div [ngClass]="isGroupDropdownOpen ? 'border-danger' : ''"
                        class="border d-flex justify-content-between" role="button" (click)="onGroupDropdownClicked()">
                        <div class="ms-2 p-1">{{ selectedGroup.name }}</div>
                        <img *ngIf="!isGroupDropdownOpen" class="me-2" src="assets/icons/caret-down.svg"
                            alt="caret-down open select">
                        <img *ngIf="isGroupDropdownOpen" class="me-2" src="assets/icons/caret-up.svg"
                            alt="caret-up close select">
                    </div>
                    <avs-dropdown [selectInput]="groupSelect" [activeItem]="selectedGroup"
                        (onItemClicked)="onGroupItemClicked($event)" [isHeaderActive]="false"
                        *ngIf="isGroupDropdownOpen" class="avs-route-station-settings-dropdown-container">
                    </avs-dropdown>
                </div>
            </div>
        </div>
    </div>


    <avs-bilderkatalog [title]="'Bilder'" [images]="data.assignedImages"
        (myClick)="onProjectImageClicked($event)"></avs-bilderkatalog>
        
    <div class="d-flex justify-content-between mt-5 justify-content-between">
        <button class="btn btn-sm btn-outline-danger rounded-0" (click)="onCloseModalClicked()">Abbrechen</button>
        <button *ngIf="selectedGroup.id !== 0 || singleRouteStation" class="btn btn-sm btn-outline-success rounded-0"
            (click)="onSubmitModalClicked()">Erstellen</button>
    </div>
</mat-dialog-content>