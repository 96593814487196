import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProjectRouteStationWithParam } from "../shared/interfaces/project-route-station.class";
import { ProjectImageData } from "../shared/interfaces/project-image-data.interface";
import { DeviceParameter } from "../../device/shared/interfaces/device-parameter.interface";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { environment } from "../../../environments/environment";
import { DeviceTypeEnum } from "../../device/shared/enums/device-type.enum";
import { VoltageChartComponent } from '../project-schema/shared/voltage-chart/voltage-chart.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RadarDataChartComponent } from '../project-schema/shared/radar-data-chart/radar-data-chart.component';
import { CurrentChartComponent } from '../project-schema/shared/current-chart/current-chart/current-chart.component';
import { SchemaDataEndChannelError } from '../project-schema/shared/interfaces/schema-data-end-channel-error.interface';
import { SchemaDataEndChannelErrorError } from '../project-schema/shared/interfaces/schema-data-end-channel-error-error.interface';

@Component({
  selector: 'avs-project-route-station',
  templateUrl: './project-route-station.component.html',
  styleUrls: ['./project-route-station.component.css'],
  animations: [
    trigger('blink', [
      state('off', style({
        opacity: 0
      })),
      state('on', style({
        opacity: 1
      })),
      transition('off <=> on', animate('0.5s'))
    ])
  ]
})
export class ProjectRouteStationComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() routeStation?: ProjectRouteStationWithParam;
  @Input() images?: ProjectImageData[] = [];
  @Input() isModalOpen: boolean = false;
  @Input() channelErrors: SchemaDataEndChannelError[] = [];
  channelError: SchemaDataEndChannelErrorError[] = [];
  public url = this.router.url
  public blinkState: string = 'off';
  public deviceType?: DeviceTypeEnum;
  public rotiationsOngoing: boolean = false;
  public radarErrorDeChannels: number[] = [];
  public blinkerError: boolean = false;
  public solarChannelCount: number = 0;

  public errorPanel1: boolean = false;
  public errorPanel2: boolean = false;
  protected readonly environment = environment;
  protected readonly DeviceTypeEnum = DeviceTypeEnum;

  public imageIntervalCollection: any[] = [];
  @ViewChild('myImage')
  public myImage: ElementRef | undefined;
  public imageWidth: number = 0;
  public imageHeight: number = 0;

  constructor(public dialog: MatDialog,
    public router: Router) { }

  public ngOnInit(): void {
    setInterval(() => {
      this.blinkState = (this.blinkState === 'off') ? 'on' : 'off';
    }, 1000);
    if (this.routeStation) {
      this.deviceType = this.routeStation.deviceParameter.typ;
    }
    this.solarChannelCount = 0;
    this.routeStation?.routeStation.dataEndChannels?.forEach((channel) => {
      if (channel.localbusAddress === 10 && channel.fg === 6) {
        this.solarChannelCount++;

      }
    })
    if (this.url === '/project/station-overview' || this.url === '/device/route-station') {
      this.isModalOpen = true;
    }
  }

  public ngOnDestroy(): void {
    this.imageIntervalCollection.forEach(id => clearInterval(id));
  }

  ngAfterViewInit() {
    this.imageWidth = this.myImage?.nativeElement.offsetWidth;
    this.imageHeight = this.myImage?.nativeElement.offsetHeight;
  }

  public rotateImagesPaths(images: ProjectImageData[]): void {
    if (images.length === 0) return;
    this.rotiationsOngoing = true
    images.forEach(image => {
      if (image.isAnimated && image.animatedImageIds!.length > 0) {
        this.rotatePathForId(image)
      }
    });
  }

  public getSystemzustand(): string {
    if (this.routeStation?.deviceParameter.brennstoffZelle !== null) {
      if (this.routeStation?.deviceParameter.brennstoffZelle?.systemzustand === 1) {
        return 'bsz-warnung';
      }
      if (this.routeStation?.deviceParameter.brennstoffZelle?.systemzustand === 2) {
        return 'bsz-fehler';
      }
      if (this.routeStation?.deviceParameter.brennstoffZelle?.systemzustand === 0) {
        return '';
      }
    }
    return 'k.A.'

  }

  public rotatePathForId(image: ProjectImageData): void {
    const myValueList = image.animatedImageIds!;

    let i = 0;
    let idp = setInterval(() => {
      image.path = this.getPathByImageId(myValueList[i++]);
      if (i === myValueList.length) {
        i = 0;
      }
    }, 1000);

    this.imageIntervalCollection.push(idp);
  }

  public getPathByImageId(imageId: number) {
    if (this.images) {
      let imageObject = this.images.find((x) => x.imageId === imageId && x.routeStationId === this.routeStation?.routeStation.id)
      return imageObject?.path ? imageObject.path : 'upload/images/6480/207 - Custom Stellcode.bmp';
    }
    else return
  }

  public openVoltageChart(): void {
    this.dialog.open(VoltageChartComponent, {
      data: this.routeStation,
      height: '77vh',
      width: '98vw'
    });
  }

  public openCurrentChart(): void {
    this.dialog.open(CurrentChartComponent, {
      data: this.routeStation,
      height: '77vh',
      width: '98vw'
    });
  }

  public openRadarDataChart(radar: any): void {
    this.dialog.open(RadarDataChartComponent, {
      data: { routeStation: this.routeStation, radar: radar, samId: this.routeStation?.routeStation.samId },
      width: '98vw'
    });
  }

  public getBatteryIcon(): string {
    if (!this.routeStation?.deviceParameter.online) {
      return 'assets/icons/battery/battery_unknown_FILL0_wght400_GRAD0_opsz24.svg';
    } else if (+(this.routeStation.deviceParameter.solar.batteriespannung) > 13) {
      return 'assets/icons/battery/battery_full_alt_FILL0_wght400_GRAD0_opsz24.svg';
    } else if (+(this.routeStation.deviceParameter.solar.batteriespannung) <= 13 && +(this.routeStation.deviceParameter.solar.batteriespannung) > 12.5) {
      return 'assets/icons/battery/battery_horiz_075_FILL0_wght400_GRAD0_opsz24.svg';
    } else if (+(this.routeStation.deviceParameter.solar.batteriespannung) <= 12.5 && +(this.routeStation.deviceParameter.solar.batteriespannung) > 12) {
      return 'assets/icons/battery/battery_horiz_050_FILL0_wght400_GRAD0_opsz24.svg';
    } else if (+(this.routeStation.deviceParameter.solar.batteriespannung) <= 12 && +(this.routeStation.deviceParameter.solar.batteriespannung) > 11.5) {
      return 'assets/icons/battery/battery_low_FILL0_wght400_GRAD0_opsz24.svg';
    } else {
      return 'assets/icons/battery/battery_very_low_FILL0_wght400_GRAD0_opsz24.svg';
    }
  }

  public getReceptionIcon(): string {
    if (!this.routeStation?.deviceParameter.online) {
      return 'assets/icons/signal/signal_disconnected.svg';
    } else if (+(this.routeStation.deviceParameter.sim.signal) > -66) {
      return 'assets/icons/signal/reception-4.svg';
    } else if (+(this.routeStation.deviceParameter.sim.signal) > -78) {
      return 'assets/icons/signal/reception-3.svg';
    } else if (+(this.routeStation.deviceParameter.sim.signal) > -90) {
      return 'assets/icons/signal/reception-2.svg';
    } else if (+(this.routeStation.deviceParameter.sim.signal) > -102) {
      return 'assets/icons/signal/reception-1_yellow.svg';
    } else {
      return 'assets/icons/signal/reception-1.svg';
    }
  }

  public checkIfPW(routeStation: ProjectRouteStationWithParam): boolean {
    let isPW: boolean = false;
    routeStation.routeStation.dataEndChannels?.forEach((channel) => {
      if (channel.localbusAddress === 8) { isPW = true }
    })
    return isPW
  }

  public getStationImage(stellcode: any): string {
    if (stellcode === undefined) return '';
    let path = this.images?.find(i => i.stellcode === +stellcode)?.path?.toString();
    return path ? path : '';
  }

  public getBatterySpannung(deviceParameter: DeviceParameter | undefined) {
    if (!deviceParameter) {
      return 'offline';
    }
    if (deviceParameter.solar.batteriespannung === null) {
      return 'offline';
    }
    else {
      return Number.parseFloat(deviceParameter.solar.batteriespannung.toString()).toFixed(2) + 'V';
    }
  }

  public getBatteryChargingCurrent(deviceParameter: DeviceParameter | undefined) {
    if (!deviceParameter) {
      return '';
    }
    if (deviceParameter.solar.laststrom === null) {
      return 'fehler';
    }
    else {
      return Number.parseFloat(deviceParameter.solar.laststrom.toString()).toFixed(2) + 'A';
    }
  }

  public getBatteryPowerPurchase(deviceParameter: DeviceParameter | undefined) {
    if (!deviceParameter) {
      return '';
    }
    if (deviceParameter.solar.batteriestrom === null) {
      return 'fehler';
    }
    else {
      return Number.parseFloat(deviceParameter.solar.batteriestrom.toString()).toFixed(2) + 'A';
    }
  }

  public getBatteryPowerPurchaseClass(deviceParameter: DeviceParameter | undefined): string {
    if (!deviceParameter) {
      return 'text-secondary text-center small';
    }
    if (+deviceParameter.solar.batteriestrom < 0) {
      return 'text-danger text-center small';
    } else {
      return 'text-success text-center small';
    }
  }


  public isDoorOpen() {
    let door = this.routeStation?.deviceParameter.io.find(io => io.de === 1)
    if (door?.active === false && this.routeStation?.deviceParameter.brennstoffZelle !== null) {
      return false
    }
    else return true
  }

  public getIconForPowerPurchase(deviceParameter: DeviceParameter | undefined): string {
    if (!deviceParameter) {
      return 'assets/icons/arrow-left-short-red.svg'
    }
    if (+deviceParameter.solar.batteriestrom < 0) {
      return 'assets/icons/arrow-right-short-red.svg';
    } else {
      return 'assets/icons/arrow-right-short-green.svg';
    }
  }
}
