<div class="d-flex justify-content-between">
  <h5 class="m-3">Navigation & Filter</h5>
  <img *canAccess="[1]" class="avs-icon-add me-3 mt-2 mb-3" src="assets/icons/plus.svg" alt="icon-add-group"
    (click)="onAddProjectBtnClicked()">
</div>
<div class="d-flex">
  <div class="ms-3 me-1 w-100 rounded-0">
    <div [ngClass]="isDropdownOpen ? 'border-danger' : ''"
      class="border d-flex justify-content-between avs-project-menu-dropdown" role="button"
      (click)="onDropdownClicked()">
      <div class="ms-2 p-1">{{ selectedProjectFilter.name }}</div>
      <img *ngIf="!isDropdownOpen" class="me-2" src="assets/icons/caret-down.svg" alt="caret-down open select">
      <img *ngIf="isDropdownOpen" class="me-2" src="assets/icons/caret-up.svg" alt="caret-up close select">
    </div>
    <avs-dropdown [selectInput]="projectFilter" [activeItem]="selectedProjectFilter"
      (onItemClicked)="onProjectSelectChange($event)" [isHeaderActive]="false" *ngIf="isDropdownOpen">

    </avs-dropdown>
  </div>
  <input placeholder="Suche" class="me-3 ms-1 w-100 form-control rounded-0 " #search type="text"
    (keyup)="onSearchInputChanged(search.value)">
  <!-- ggf. mit dem avs-text-input realisieren
    <avs-text-input placeholder="Suchen" class="me-3 ms-1 w-100 rounded-0" [value]="searchInput"
    (valueChange)="onSearchInputChanged($event)">
  </avs-text-input> -->
</div>

<div class="avs-nav-tree m-3 overflow-y-auto" style="max-height: 486px;">
  <ng-container *ngFor="let project of filteredProjects[0] ? filteredProjects : projects">
    <div class="ms-2 me-3 mb-2 mt-2 avs-item-div" [ngClass]="activeProject === project ? 'avs-active' : ''"
      (click)="onProjectFolderClicked(project)">
      <i aria-hidden="true" class="fa fa-icon fa-folder-tree me-1" title="folder-tree"></i>
      <!--    <img class="me-1 img" height="18px" src="../../../assets/icons/folder.svg" alt="ordner icon"> -->
      <span>{{project.name}}</span>
    </div>
    <ng-container *ngIf="activeProject === project">
      <ng-container *ngFor="let group of projectGroups">
        <div class="ms-4 avs-item-div" (click)="onProjectGroupClicked(group)"
          [ngClass]="activeGroup === group ? 'avs-active' : ''">

          <!-- logik von unten kopieren und mit ngclass arbeiten -->
<!--           <ng-container *ngIf="activeGroup === group">
            <i aria-hidden="true" class="fa fa-icon fa-folder me-1" title="folder"></i>
          </ng-container>
          <ng-container *ngIf="activeGroup !== group">
            <i aria-hidden="true" class="fa fa-icon fa-folder-open me-1" title="folder-open"></i>
          </ng-container> -->

          <img class="me-1 img" height="18px" [src]="activeGroup === group ?
              '../../../assets/icons/folder-open-solid.svg' : '../../../assets/icons/folder-solid.svg'" alt="caret icon">
          <span>{{group.name}}</span>
        </div>
        <ng-container *ngIf="activeGroup === group">
          <div *canAccess="[1,2,5]">
            <ng-container *ngFor="let routeStation of projectRouteStations">
              <div class=" ms-5 avs-item-div" (click)="onProjectRouteStationClicked(routeStation)"
                [ngClass]="activeRouteStation === routeStation ? 'avs-active' : ''">
                <img class="me-1 img" height="18px" src="../../../assets/icons/map-pin-solid.svg" alt="pin-map icon">
                <span>{{routeStation.name}}</span>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

</div>