import { MapPositionService } from './shared/map-position.service';
import { MapPosition } from './shared/map-position.interface';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
// @ts-ignore
import * as mapboxgl from 'mapbox-gl';
import { MapPoint } from './shared/map-point.interface';
import { environment } from "../../../environments/environment";
import { MapHandlerService } from './shared/map-handler.service';
import { Location } from '@angular/common';
import { ProjectDataSessionStorageService } from '../shared/services/project-data-session-storage.service';
import { ProjectSessionStorageData } from '../shared/interfaces/project-session-storage-data.interface';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { access } from 'fs';




@Component({
  selector: 'avs-project-map',
  templateUrl: './project-map.component.html',
  styleUrls: ['./project-map.component.css']
})
export class ProjectMapComponent implements OnInit, OnDestroy {
  public index: number = 0;
  public hasToResize: boolean = false;
  private map: any = null;
  private projectIndex: number = 0;

  private subscriptionCollection: Subscription[] = [];
  private subscription1?: Subscription;
  private subscription2?: Subscription;
  private subscription5?: Subscription;
  private subscription6?: Subscription;
  private subscription7?: Subscription;

  constructor(private mapPositionService: MapPositionService,
    private readonly cdr: ChangeDetectorRef,
    private mapHandler: MapHandlerService,
    private projectDataSessionService: ProjectDataSessionStorageService,
    public router: Router) { }

  public ngOnInit(): void {
    this.getMap();
    this.mapHandler.isResize.subscribe(() => {
      setTimeout(() => {  //TODO -> muss noch geändert werden.
        this.map.resize();
      }, 50);
    });

    this.subscription1 = this.mapPositionService.mapPosition.subscribe(mapPosition => this.flyToMapPositon(mapPosition));
    this.subscription2 = this.mapPositionService.mapProjectPoints.subscribe(mapPoints => this.getMapProjectPoints(mapPoints));
    setTimeout(() => { /* Timeout wird benötigt, da es sonst zu einem undefinierten Zustand kommen kann, wenn die Applikation mit Werten aus dem SessionStorage geladen wird */
      /* this.subscription3 =  */this.mapPositionService.mapGroupPoints.subscribe(mapPoints => this.getAndSetSourceData('groupPoints', mapPoints))
      /* this.subscription4 =  */this.mapPositionService.mapRouteStationPoints.subscribe(mapPoints => this.getAndSetSourceData('routeStationPoints', mapPoints));
    }, 2000);

    /* ProjectLayer Handler */
    this.subscription5 = this.mapHandler.projectLayer.subscribe((data) => {
      let activeProjectData: ProjectSessionStorageData = this.projectDataSessionService.getProjectSessionData();
      if (data === 2) { this.mapPositionService.mapProjectPoints.subscribe(mapPoints => this.getAndSetSourceData('projectPoints', mapPoints)); return }
      if (data === 0) { if (activeProjectData.activeProjectId !== '') { this.getAndSetSourceData('projectPoints', []); return } else return }
      if (data === 1) { this.getAndSetSourceData('projectPoints', []); return };
    });

    /* GruppenLayer  Handler */
    this.subscription6 = this.mapHandler.groupLayer.subscribe((data) => {
      let activeProjectData: ProjectSessionStorageData = this.projectDataSessionService.getProjectSessionData();
      if (data === 2) { this.mapPositionService.mapGroupPoints.subscribe(mapPoints => this.getAndSetSourceData('groupPoints', mapPoints)); return }
      if (data === 0) { if (activeProjectData.activeProjectGroupId !== '') { this.getAndSetSourceData('groupPoints', []); return } }
      if (data === 1) { this.getAndSetSourceData('groupPoints', []); return }
    });

    /* RouteStationLayer  Handler */
    this.subscription7 = this.mapHandler.routeStationLayer.subscribe((data) => {
      let activeProjectData: ProjectSessionStorageData = this.projectDataSessionService.getProjectSessionData();
      if (data === 2) { this.mapPositionService.mapGroupPoints.subscribe(mapPoints => this.getAndSetSourceData('routeStationPoints', mapPoints)); return }
      if (data === 0) { if (activeProjectData.activeProjectRouteStation !== '') { this.getAndSetSourceData('routeStationPoints', []) }; return }
      if (data === 1) { this.getAndSetSourceData('routeStationPoints', []); return }

    })

    /* SessionStorage Handler */
    setTimeout(() => {
      let activeProjectData: ProjectSessionStorageData = this.projectDataSessionService.getProjectSessionData();
      if (activeProjectData.activeProjectId !== '') { this.mapHandler.removeProjectLayer(1) }
      if (activeProjectData.activeProjectGroupId !== '') { this.mapHandler.removeGroupLayer(1) }
    }, 2500);

    this.subscriptionCollection.push(this.subscription1!);
    this.subscriptionCollection.push(this.subscription2!);
    /* this.subscriptionCollection.push(this.subscription3!);
    this.subscriptionCollection.push(this.subscription4!); */
    this.subscriptionCollection.push(this.subscription5!);
    this.subscriptionCollection.push(this.subscription6!);
    this.subscriptionCollection.push(this.subscription7!);

  }

  public ngOnDestroy(): void {
    this.subscriptionCollection.forEach(sub => sub.unsubscribe());
  }

  private getMap(): void {

    this.map = new mapboxgl.Map({
      access_token: environment.mapAccessToken,
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [10, 52],
      zoom: 6,
    });
  }

  private flyToMapPositon(mapPosition: MapPosition): void {
    this.map.flyTo({
      center: [mapPosition.longitude, mapPosition.latitude],
      zoom: mapPosition.zoom
    });
    this.cdr.detectChanges();
  }


  private getMapProjectPoints(mapPoits: MapPoint[]): void {
    if (this.projectIndex > 0) {
      if (this.map.getLayer('projectCircle')) { this.map.removeLayer('projectCircle') }
      this.map.addLayer({
        id: 'projectCircle',
        type: 'circle',
        source: 'projectPoints',
        paint: {
          'circle-color': '#E00000',
          'circle-radius': 8,
          'circle-stroke-width': 2,
          'circle-stroke-color': '#ffffff'
        }
      }); return
    };
    if (!mapPoits[0]) { return; }
    this.map.on('load', () => {
      this.map.addSource('projectPoints', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: mapPoits
        }
      });
      // Add a circle layer
      this.map.addLayer({
        id: 'projectCircle',
        type: 'circle',
        source: 'projectPoints',
        paint: {
          'circle-color': '#E00000',
          'circle-radius': 8,
          'circle-stroke-width': 2,
          'circle-stroke-color': '#ffffff'
        }
      });

      /* create GroupPoint Source */
      this.map.addSource(`groupPoints`, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      });
      // Add a circle layer
      this.map.addLayer({
        id: 'GroupCircle',
        type: 'circle',
        source: `groupPoints`,
        paint: {
          'circle-color': '#3c00e0',
          'circle-radius': 8,
          'circle-stroke-width': 2,
          'circle-stroke-color': '#ffffff'
        }
      });

      /* create RouteStationPoint Source */

      this.map.addSource('routeStationPoints', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      });
      // Add a circle layer
      this.map.addLayer({
        id: 'RouteCircle',
        type: 'circle',
        source: 'routeStationPoints',
        paint: {
          'circle-color': '#652213',
          'circle-radius': 6,
          'circle-stroke-width': 2,
          'circle-stroke-color': '#ffffff'
        }
      });


      this.projectIndex++
    });
    this.cdr.detectChanges();
  }

  private getAndSetSourceData(source: string, mapPoints: MapPoint[]) {
    let layerSource = this.map.getSource(source)
    if (layerSource === undefined) { return }
    layerSource.setData({
      "type": "FeatureCollection",
      "features": mapPoints
    })
  }
}