<mat-dialog-content class="overflow-hidden ">
    <div class="d-flex justify-content-between">
        <h5 class="fw-semibold">Brennstoffverlauf von SAM ID:{{ samId }}</h5>
        <img class="ms-3 avs-modal-close-btn mb-1" width="30" src="assets/icons/x.svg" alt="close-modal-icon"
            (click)="onCloseDialogClicked()">
    </div>
    <div class="d-flex justify-content-between ms-4 me-4">
        <div class="d-flex justify-content-center align-items-center mb-4">
            Von vor
            <input class="ms-1 me-1 form-control rounded-0 form-control-sm" style="width: 70px" type="number"
                [(ngModel)]="fromDate" (ngModelChange)="setFromDate(fromDate)" min="1" max="14"> Tagen
            Bis zu
            <input class="ms-1 me-1 form-control rounded-0 form-control-sm" style="width: 70px" type="number"
                [(ngModel)]="toDate" (ngModelChange)="setToDate(toDate)" min="0" max="13"> Tagen
        </div>
        <div>
            <button (click)="exportChart()" class="btn rounded-0 btn-outline-success ms-5   ms-sm-5 btn-sm"> Export
            </button>
            <button (click)="removeData(chart)" class="btn rounded-0 btn-outline-success ms-5   ms-sm-5 btn-sm"> Update
            </button>
        </div>
    </div>
    <div class="d-flex w-100 h-100 justify-content-center align-content-center ">
        <div class="chart-container d-flex justify-content-center "
            style="width: 72vw; height: 65vh;padding-bottom: 100px;">
            <canvas id="MyChart">{{ chart }}</canvas>
        </div>
    </div>
</mat-dialog-content>
<div mat-dialog-actions class="d-flex justify-content-end me-5">
    <button (click)="onCloseDialogClicked()" class="btn rounded-0 btn-outline-danger ms-sm-5 btn-sm mt-4 ">
        Schließen
    </button>
</div>