<mat-dialog-content class="rounded-0">
    <div class="d-flex justify-content-between">
        <h5 class="fw-semibold">Zeitschaltuhr bearbeiten</h5>
        <img class="ms-3 avs-modal-close-btn mb-1" width="30" src="assets/icons/x.svg" alt="close-modal-icon"
            (click)="onCloseEditTimeSwitchClicked()">
    </div>

    <!-- Verkehrsstufe  MQ -->
    <div *ngIf="data.type === 'mq'" class="w-100 d-flex justify-content-center mb-2">
        <div class="d-flex justify-content-center mb-2 mt-2" style="width: 240px !important">
            <div class="w-100 avs-route-station-settings-dropdown-container justify-content-between">
                <span class="ms-1 fw-semibold text-secondary">Verkehrsstufe</span>
                <div [ngClass]="isTrafficDropdownOpen ? 'border-danger' : ''"
                    class="border d-flex justify-content-between" role="button" (click)="onTrafficDropdownClicked()">
                    <div class="ms-2 p-1">{{ selectedTraffic.name }}</div>
                    <img *ngIf="!isTrafficDropdownOpen" class="me-2" src="assets/icons/caret-down.svg"
                        alt="caret-down open select">
                    <img *ngIf="isTrafficDropdownOpen" class="me-2" src="assets/icons/caret-up.svg"
                        alt="caret-up close select">
                </div>
                <avs-dropdown [selectInput]="trafficSelect" [activeItem]="selectedTraffic"
                    (onItemClicked)="onTrafficItemClicked($event)" [isHeaderActive]="false"
                    *ngIf="isTrafficDropdownOpen" class="avs-route-station-settings-dropdown-container">
                </avs-dropdown>
            </div>
        </div>
    </div>

    <!-- Priority  AQ + Flasher -->
    <div class="w-100 d-flex justify-content-center mb-2">
        <div class="d-flex justify-content-center mb-2 mt-2" style="width: 240px !important">
            <div class="w-100 avs-route-station-settings-dropdown-container justify-content-between">
                <span class="ms-1 fw-semibold text-secondary">Priorität</span>
                <div [ngClass]="isPriorityDropdownOpen ? 'border-danger' : ''"
                    class="border d-flex justify-content-between" role="button" (click)="onPriorityDropdownClicked()">
                    <div class="ms-2 p-1">
                        {{ selectedPriority.name }}
                    </div>
                    <img *ngIf="!isPriorityDropdownOpen" class="me-2" src="assets/icons/caret-down.svg"
                        alt="caret-down open select">
                    <img *ngIf="isPriorityDropdownOpen" class="me-2" src="assets/icons/caret-up.svg"
                        alt="caret-up close select">
                </div>
                <avs-dropdown [selectInput]="prioritySelect" [activeItem]="selectedPriority"
                    (onItemClicked)="onPriorityItemClicked($event)" [isHeaderActive]="false"
                    *ngIf="isPriorityDropdownOpen" class="avs-route-station-settings-dropdown-container">
                </avs-dropdown>
            </div>
        </div>
    </div>
    <!-- verkehrssituation MQ -->

    <div *ngIf="data.type === 'mq'" class="w-100 d-flex justify-content-center mb-2">
        <div class="d-flex justify-content-center mb-2 mt-2" style="width: 240px !important">
            <div class="w-100 avs-route-station-settings-dropdown-container justify-content-between">
                <span class="ms-1 fw-semibold text-secondary">Verkehrssituation</span>
                <div [ngClass]="isVerkehrszustandDropdownOpen ? 'border-danger' : ''"
                    class="border d-flex justify-content-between" role="button"
                    (click)="onVerkehrszustandDropdownClicked()">
                    <div class="ms-2 p-1">{{ selectedVerkehrszustand.name }}</div>
                    <img *ngIf="!isVerkehrszustandDropdownOpen" class="me-2" src="assets/icons/caret-down.svg"
                        alt="caret-down open select">
                    <img *ngIf="isVerkehrszustandDropdownOpen" class="me-2" src="assets/icons/caret-up.svg"
                        alt="caret-up close select">
                </div>
                <avs-dropdown [selectInput]="verkehrszustandSelect" [activeItem]="selectedVerkehrszustand"
                    (onItemClicked)="onVerkehrszustandItemClicked($event)" [isHeaderActive]="false"
                    *ngIf="isVerkehrszustandDropdownOpen" class="avs-route-station-settings-dropdown-container">
                </avs-dropdown>
            </div>
        </div>
    </div>
    <!-- Stellcode-Images  AQ  -->

    <div *ngIf="data.type === 'aq'" class="row avs-project-images-container d-flex justify-content-center mb-2">
        <avs-bilderkatalog [images]="images" (myClick)="onProjectImageClicked($event)"></avs-bilderkatalog>

    </div>
    <!-- Stellcode-Flasher flasher -->
    <div *ngIf="data.type === 'flasher'" class="row avs-project-images-container d-flex justify-content-center mb-2">
        <!-- Blinker aus -->
        <div *ngIf="selectedFunction.id === 0" style="display: flex"
            class="d-flex justify-content-center align-content-center h-100 mt-2">
            <div class="p-3 rounded-5 border border-black"
                style="background-color: white; height: 30px; width: 30px; margin-right: 16px;">

            </div>
            <div class="p-3 rounded-5 border border-black" style="background-color: white; height: 30px; width: 30px">
            </div>
        </div>

        <!-- Blinker an -->
        <div *ngIf="selectedFunction.id === 1" style="display: flex"
            class="d-flex justify-content-center align-content-center h-100 mt-2">
            <div class="p-3 rounded-5 border border-black"
                style="background-color: yellow; height: 30px; width: 30px; margin-right: 16px;">

            </div>
            <div class="p-3 rounded-5 border border-black" style="background-color: yellow; height: 30px; width: 30px">
            </div>
        </div>
    </div>

    <!-- Funktion AQ+Flasher -->
    <div *ngIf="data.type !== 'mq'" class="w-100 d-flex justify-content-center mb-2">
        <div class="avs-route-station-settings-dropdown-container justify-content-between"
            style="width: 240px !important">
            <span class="ms-1 fw-semibold text-secondary">Funktion</span>
            <div [ngClass]="isFunctionDropdownOpen ? 'border-danger' : ''" class="border d-flex justify-content-between"
                role="button" (click)="onFunctionDropdownClicked()">
                <div class="ms-2 p-1">{{ selectedFunction.name }}</div>
                <img *ngIf="!isFunctionDropdownOpen" class="me-2" src="assets/icons/caret-down.svg"
                    alt="caret-down open select">
                <img *ngIf="isFunctionDropdownOpen" class="me-2" src="assets/icons/caret-up.svg"
                    alt="caret-up close select">
            </div>
            <avs-dropdown [selectInput]="functionSelect" [activeItem]="selectedFunction"
                (onItemClicked)="onFunctionItemClicked($event)" [isHeaderActive]="false" *ngIf="isFunctionDropdownOpen"
                class="avs-route-station-settings-dropdown-container">
            </avs-dropdown>
        </div>
    </div>

    <div *ngIf="selectedFunction.id == 2" class="w-100 d-flex justify-content-center mb-2">
        <input type="number" placeholder="Intervall in ms angeben" *ngIf="selectedFunction.id === 2" [required]="true"
            label="Blinker-Intervall in ms" [(ngModel)]="data.data.tlS_Time" class="rounded-0 form-control w-50">
    </div>
    <!-- einmalig -->
    <div class="w-100 d-flex justify-content-center mb-2">
        <mat-slide-toggle [checked]="data.data.one_time" color="primary" (change)="onOneTimeChange()">
            Einmalig
        </mat-slide-toggle>
    </div>

    <div *ngIf="data.data.one_time" class="w-100 d-flex justify-content-evenly mb-2 ms-2 me-2">

        <ngbd-datepicker-range-popup (startDate)="onStartDateSelect($event)" [loadedFromDate]="inputStart"
            [loadedToDate]="inputEnd" (endDate)="onEndDateSelect($event)"></ngbd-datepicker-range-popup>

    </div>
    <!-- Tages Picker -->
    <div *ngIf="!data.data.one_time" class="d-flex w-100 justify-content-center pe-4 " required>
        <mat-checkbox color="primary" class="example-margin" [(ngModel)]="data.data.monday">Mo</mat-checkbox>
        <mat-checkbox color="primary" class="example-margin" [(ngModel)]="data.data.tuesday">Di</mat-checkbox>
        <mat-checkbox color="primary" class="example-margin" [(ngModel)]="data.data.wednesday">Mi</mat-checkbox>
        <mat-checkbox color="primary" class="example-margin" [(ngModel)]="data.data.thursday">Do</mat-checkbox>
        <mat-checkbox color="primary" class="example-margin" [(ngModel)]="data.data.friday">Fr</mat-checkbox>
        <mat-checkbox color="primary" class="example-margin" [(ngModel)]="data.data.saturday">Sa</mat-checkbox>
        <mat-checkbox color="primary" class="example-margin" [(ngModel)]="data.data.sunday">So</mat-checkbox>
    </div>
    <!-- Uhrzeiten -->
    <div class="w-100 d-flex justify-content-evenly mb-2 ">
        <avs-time-input class="" label="Startzeit" (valueChange)="onEnterStartTimeChange($event)" [value]="starttime"
            id="starttime" name="starttime">
        </avs-time-input>
        <avs-time-input class="ml-1" label="Endzeit" (valueChange)="onEnterEndTimeChange($event)" [value]="endtime"
            id="endtime" name="endtime">
        </avs-time-input>
    </div>



    <div class="d-flex justify-content-between mt-5 border-top pt-3" style="z-index: 1;">
        <button class="btn btn-sm btn-outline-danger rounded-0" (click)="onCloseEditTimeSwitchClicked()">
            Abbrechen
        </button>

        <button class="btn btn-sm btn-outline-success rounded-0" (click)="onSubmitBtnClicked()">
            Bearbeiten
        </button>
    </div>

</mat-dialog-content>