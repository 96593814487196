import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectGroup } from "../shared/interfaces/project-group.interface";
import { ProjectRouteStation } from "../shared/interfaces/project-route-station.interface";
import { ProjectDataService } from "../shared/services/project-data.service";
import { SchemaLine, SchemaLineWithPosition } from './shared/interfaces/schema-line.class';
import { ProjectRouteStationWithParam } from "../shared/interfaces/project-route-station.class";
import { MatDialog } from "@angular/material/dialog";
import { SchemaAddStationModalComponent } from "./schema-add-station-modal/schema-add-station-modal.component";
import { RouteStationService } from "../shared/services/route-station.service";
import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { SchemaRouteStationModalComponent } from "./schema-route-station-modal/schema-route-station-modal.component";
import { ProjectImageData } from "../shared/interfaces/project-image-data.interface";
import { ImageDataService } from "../shared/services/image-data.service";
import { DataPointService } from '../shared/services/datapoint.service';
import { GroupService } from '../shared/services/group.service';
import { MetaInformationService } from '../shared/services/meta-information.service';
import { TrafficLanes } from './shared/interfaces/trafficLanes.interface';
import { MQResultService } from '../shared/services/mqresult.service';
import { MQResult } from '../shared/interfaces/mqresult.interface';
import { Router } from '@angular/router';
import { RadarDataChartComponent } from './shared/radar-data-chart/radar-data-chart.component';
import { DeviceService } from 'src/app/device/shared/services/device.service';
import { AlertService } from 'src/app/shared/alert/shared/alert.service';
import { AlertTypes } from 'src/app/shared/alert/shared/alert-types';
import { Subscription } from 'rxjs';
import { MetaInformation } from '../shared/interfaces/meta-information.interface';
import { ProjectGroupMetaInformation } from '../shared/interfaces/project-group-meta-information';
import { ScenarioService } from '../shared/services/scenario.service';
import { Scenario } from '../shared/interfaces/scenario.interface';
import { ScenarioSelectDialogComponent } from './scenario-select-dialog/scenario-select-dialog.component';
import { SchemaDataEndChannelError } from './shared/interfaces/schema-data-end-channel-error.interface';
import { UserDataService } from 'src/app/user/shared/services/user-data.service';
import { SWAConfig } from '../shared/interfaces/swaconfig';
import { SWAConfigService } from '../shared/services/swaconfig.service';
import * as _ from 'lodash';
@Component({
  selector: 'avs-project-schema',
  templateUrl: './project-schema.component.html',
  styleUrls: ['./project-schema.component.css']
})
export class ProjectSchemaComponent implements OnInit, OnDestroy {
  public activeRouteStation: ProjectRouteStation | undefined;
  public routeStations: ProjectRouteStation[] | undefined;
  public activeGroup: ProjectGroup | undefined;
  public projectRouteStations: ProjectRouteStationWithParam[] | undefined;
  public MQResults: MQResult[] = [];
  public activeScenario: Scenario | undefined;
  public settings?: string;
  public formattedJson: any;
  public matchingSWAConfig?: SWAConfig;

  public isJsonValid?: boolean
  public validJson: any;


  public line: SchemaLine = new SchemaLine(
    [{ station: undefined, x: 1, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 2, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 3, isActive: false, line: [], colorArr: [] }, /* schema line erweitern um piktogram? */
    { station: undefined, x: 4, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 5, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 6, isActive: false, line: [], colorArr: [] },
    { station: undefined, x: 7, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 8, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 9, isActive: false, line: [], colorArr: [] },
    { station: undefined, x: 10, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 11, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 12, isActive: false, line: [], colorArr: [] },
    { station: undefined, x: 13, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 14, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 15, isActive: false, line: [], colorArr: [] }],

    [{ station: undefined, x: 1, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 2, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 3, isActive: false, line: [], colorArr: [] },
    { station: undefined, x: 4, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 5, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 6, isActive: false, line: [], colorArr: [] },
    { station: undefined, x: 7, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 8, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 9, isActive: false, line: [], colorArr: [] },
    { station: undefined, x: 10, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 11, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 12, isActive: false, line: [], colorArr: [] },
    { station: undefined, x: 13, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 14, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 15, isActive: false, line: [], colorArr: [] }]);

  public unassignedStations: ProjectRouteStationWithParam[] = [];
  public width: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  public countdown: number = 30;
  public isGroupChanged: boolean = false;
  public freewayLanes: TrafficLanes[] = [];
  public isEditSchemaActive: boolean = false;
  public isCompletelyLoaded: boolean = false;
  public projectImages: ProjectImageData[] = [];
  public trafficSignPosition: number = -36;
  public isAreaACollapsed: boolean = true;
  public isAreaBCollapsed: boolean = true;
  public timer: any;
  public scenarios: Scenario[] = [];
  public schemaSubscriptionCollection: Subscription[] = [];
  public activeRouteStationSubscription?: Subscription;
  public activeGroupSubscription?: Subscription;
  public projectRouteStationsWithParamSubscription?: Subscription;
  public projectImagesSubscription?: Subscription;
  public channelErrors: SchemaDataEndChannelError[] = [];
  public routeStationsChannelErrors: SchemaDataEndChannelError[] = [];

  constructor(private projectDataService: ProjectDataService,
    private dialog: MatDialog,
    private stationService: RouteStationService,
    private readonly imageDataService: ImageDataService,
    private dataPointService: DataPointService,
    private groupService: GroupService,
    private metaInfoService: MetaInformationService,
    private MQResultService: MQResultService,
    private router: Router,
    private deviceService: DeviceService,
    private alertService: AlertService,
    private scenarioService: ScenarioService,
    private userDataService: UserDataService,
    private SWAConfigService: SWAConfigService) {
    this.activeRouteStationSubscription = projectDataService.activeRouteStation.subscribe(routeStation => {
      this.activeRouteStation = routeStation; /* Warum wird hier eine einzelne routeStation gezogen? */
    });
    this.activeGroupSubscription = projectDataService.activeGroup.subscribe(group => {
      if (group) {
        this.metaInfoService.getMetaInformationByGroupId(group.id).subscribe((metaInfo: ProjectGroupMetaInformation[]) => {
          group.metaInformation = metaInfo;
        })
        /*  this.getAllSWAConfigs(); */
        this.setTrafficLanesFromActiveGroup(group);
        this.getChannelErrorsByGroupId(group.id)
        this.getGroupsScenarios(group.id);


        /* Es soll nur nach den MQResults gefragt werden,
        wenn es eine  aktive? SWA gibt 
        
        
        */


        if (group !== this.activeGroup) {
          this.isGroupChanged = true;
          this.countdown = 30;
        }
      }
      this.activeGroup = group;
    });
    this.projectRouteStationsWithParamSubscription = projectDataService.projectsRouteStationsWithParam.subscribe(routeStations => {
      this.projectRouteStations = routeStations;
      if (routeStations) {

        this.fillLineWithStations(routeStations);
      }
    });
    this.projectImagesSubscription = imageDataService.projectImages.subscribe(images => {
      this.projectImages = images;
      this.rotateImagesPaths(this.projectImages);
    });
  }

  public ngOnInit(): void {
    this.startTimer();
    this.schemaSubscriptionCollection.push(this.activeRouteStationSubscription!);
    this.schemaSubscriptionCollection.push(this.activeGroupSubscription!);
    this.schemaSubscriptionCollection.push(this.projectRouteStationsWithParamSubscription!);
    this.schemaSubscriptionCollection.push(this.projectImagesSubscription!);
  }

  public ngOnDestroy(): void {
    this.stopTimer()
    this.schemaSubscriptionCollection.forEach(sub => sub.unsubscribe());
  }
  public rotateImagesPaths(images: ProjectImageData[]): void {
    if (images === null || images === undefined) return;
    images.forEach(image => {
      if (image.isAnimated && image.animatedImageIds!.length > 0) { /* für jedes Bild, das für die RouteStation gesetzt wird, wird nun separat geprüft ob ein animatedImageId vorhanden ist */
        this.rotatePathForId(image)
      }
    });
  }

  public rotatePathForId(image: ProjectImageData): void {
    const myValueList = image.animatedImageIds!;

    let i = 0;
    const id = setInterval(() => {
      image.path = this.getPathByImageId(myValueList[i++]); /* iteration durchs animatedimage array, und zieht sich dasnn über die id, den path */
      if (i === myValueList.length) {
        i = 0;
      }
    }, 1000);
  }

  public getPathByImageId(imageId: number) {

    if (this.projectImages) {
      let imageObject = this.projectImages.find((x) => x.imageId == imageId)
      return imageObject?.path as string
    }
    else return
  }
  public colorRoadByZ(): void {
    this.settings = '';
    this.formattedJson = '';
    this.validJson = '';
    this.isJsonValid = undefined;
    this.matchingSWAConfig = undefined;
    if (this.activeGroup)
      this.SWAConfigService.getSWAConfigs(this.activeGroup.id).subscribe(data => {
        if (data === null) { return; }
        else {
          this.matchingSWAConfig = data[0];
          if (this.activeGroup && this.matchingSWAConfig?.active) {
            this.MQResultService.getMQResultByGroupId(this.activeGroup!.id).subscribe(MQResult => { this.MQResults = MQResult; if (this.MQResults) { this.MQResults.forEach(x => { if (x.saM_Id) this.getCoordinates(x) }) } })
          }
        }


      })






    /* 
        if (this.activeGroup && this.matchingSWAConfig?.active) {
          this.MQResultService.getMQResultByGroupId(this.activeGroup?.id!).subscribe((MQResult) => {
            this.MQResults = MQResult;
            if (this.MQResults) { this.MQResults.forEach(x => { if (x.saM_Id) this.getCoordinates(x) }) }
    
          })
        } */
  }

  private getCoordinates(MQResult: MQResult): void {
    this.projectRouteStations?.forEach(x => { if (MQResult.saM_Id === x.routeStation.samId) { let coordinates = { x: x.routeStation.x, y: x.routeStation.y, samId: MQResult.saM_Id, z: MQResult.z, line: MQResult.line }; this.setColors(coordinates) } })
  }

  public setColors(coordinates: any): void {
    let colorX = coordinates.x;
    let colorZ = coordinates.z
    let line = coordinates.line
    this.line.areaA.forEach(element => {
      if (line)
        if (element.x === colorX) { element.colorArr[this.freewayLanes.length - line] = this.chooseColor(colorZ); element.line.push(line) }
    });
    this.line.areaB.forEach(element => {
      if (element.x === colorX) { element.colorArr[this.freewayLanes.length - line] = this.chooseColor(colorZ); element.line.push(line) }
    });
  }

  private chooseColor(colorZ: any) {
    switch (colorZ) {
      case 4:
        return 'avs-red';
      case 3:
        return 'avs-orange';
      case 2:
        return 'avs-yellow';
      case 1:
        return 'avs-green';
      default:
        return ''
    }
  }

  public onEditSchemaBtnChanged(): void {
    this.isEditSchemaActive = !this.isEditSchemaActive;

    if (!this.isEditSchemaActive) {

      this.onCollapseSchemeClicked();

    } else {
      this.stopTimer();
      if (!this.line.areaA[0].isActive && !this.line.areaB[0].isActive) {

        this.onExpandSchemeClicked();
      } else {
        if (!this.projectRouteStations) { return; }
        let stations = this.projectRouteStations.sort(
          (a, b) => b.routeStation.x - a.routeStation.x);
        if (!stations[0]) { return; }
        for (let area of this.line.areaA) {
          if (area.x <= stations[0].routeStation.x) {
            area.isActive = true;
          }
        }
        for (let area of this.line.areaB) {
          if (area.x <= stations[0].routeStation.x) {
            area.isActive = true;
          }
        }
      }
    }
  }

  public onSzenarioSelectClicked(): void {
    this.dialog.open(ScenarioSelectDialogComponent, { data: { scenarios: this.scenarios, groupId: this.activeGroup?.id! } }).afterClosed().subscribe(result => {
      if (result === undefined) return;
      if (result.result === 'changed') {
        setTimeout(() => {
          this.getGroupsScenarios(this.activeGroup!.id);
        }, 500);
        this.alertService.alert("Sonderprogramm wurde erfolgreich geändert", AlertTypes.success);
      }
    });
  }

  public getGroupsScenarios(groupId: number): void {
    let hasPermission = false;
    if (this.userDataService.isAdmin() || this.userDataService.isUser()) hasPermission = true;

    if (hasPermission) {
      this.scenarioService.getAllScenariosByGroupId(groupId).subscribe(scenarios => { this.scenarios = scenarios; this.activeScenario = scenarios.find((x: Scenario) => x.enabled === true) }
      );
    }
    else return
  }

  public getChannelErrorsByGroupId(groupId: number): void {
    this.deviceService.getAllChannelErrorsByGroupId(groupId).subscribe(channelErrors => {
      this.channelErrors = channelErrors
      if (channelErrors.length > 0)
        this.getRouteStationsChannelErrorsByRouteStationId(channelErrors)
    })
  }

  public getRouteStationsChannelErrorsByRouteStationId(channelErrors: SchemaDataEndChannelError[]): void {
    channelErrors.forEach(channelError => {
      channelError.errors.find(error => {
        let routeStation = this.projectRouteStations?.find(routeStation => routeStation.routeStation.samId === channelError.deviceId)
        if (error.de === 11 && error.fg === 6 && error.localbusAddress === 10) {
          if (routeStation) {
            routeStation.deviceParameter.solar.panelError1 = true
            routeStation.deviceParameter.solar.channelError = true;
          }
        }
        if (error.de === 12 && error.fg === 6&& error.localbusAddress === 10) {
          if (routeStation) {
            routeStation.deviceParameter.solar.panelError2 = true
            routeStation.deviceParameter.solar.channelError = true;
          }
        }
        if (error.fg === 6 && (error.de === 10 || error.de === 11 || error.de === 12 || error.de === 13 || error.de === 14) && error.localbusAddress === 10) {
          if (routeStation) {
            routeStation.deviceParameter.solar.channelError = true;
          }
        }
        if (error.fg === 4 && error.de === 1) {
          if (routeStation) {
            routeStation.deviceParameter.led.channelError = true;
          }
        }
        if (error.fg === 1) {
          if (routeStation) {
            routeStation?.deviceParameter.radar?.forEach((radar) => {
              if (radar.de === error.de) { radar.channelError = true }
            })
          }
        }
        if (error.fg === 4 && error.de === 2) {
          if (routeStation) {
            routeStation!.deviceParameter.blinker.channelError = true;
          }
        }
      })
    })
  }

  public onAddBtnClicked(x: number, y: number): void {
    const dialogRef = this.dialog.open(SchemaAddStationModalComponent, { data: this.unassignedStations });
    dialogRef.afterClosed().subscribe((station: ProjectRouteStationWithParam) => {
      if (station) {
        station.routeStation.x = x;
        station.routeStation.y = y;
        station.routeStation.z = this.checkForZ(x, y)


        this.stationService.editProjectRouteStation(station.routeStation.id, station.routeStation).subscribe(() => {
          this.projectDataService.updateActiveGroup(this.activeGroup);
        });
      }
    });
  }

  public checkForZ(x: number, y: number): number {
    if (y === 1) {
      let coordinate = this.line.areaA.find(areaA => areaA.x === x)
      let stations = coordinate?.station
      if (stations?.length === 0 || stations === undefined) {
        return 1
      }
      else {
        let gap = this.findFreeId(stations!)
        if (gap === 0) {
          return stations.length + 1
        }
        else {
          return gap
        }
      }
    }
    else if (y === 2) {
      let coordinate = this.line.areaB.find(areaB => areaB.x === x)
      let stations = coordinate?.station
      if (stations?.length === 0 || stations === undefined) {
        return 1
      }
      else {
        let gap = this.findFreeId(stations!)
        if (gap === 0) {
          return stations.length + 1
        }
        else {
          return gap
        }
      }
    }
    return 0
  }

  public onStationClicked(station: ProjectRouteStationWithParam): void {
    this.projectDataService.updateActiveRouteStation(station.routeStation);
    this.dialog.open(SchemaRouteStationModalComponent, { data: station, maxHeight: '98vh' },).afterClosed().subscribe(
      result => {
        this.countdown = 3;
        this.projectDataService.updateActiveGroup(this.activeGroup);
        if (result && result.toDelete) {
          this.deleteStationFromSchema(result.data);
        }
        if (result && result.navigate) {
          this.navigateToRouteStation(station)
        }
      });
  }

  public navigateToRouteStation(station: ProjectRouteStationWithParam) {
    this.projectDataService.updateActiveRouteStation(station.routeStation);
    this.router.navigate(['/project/settings']);
  }

  public navigateToTestarea(station: ProjectRouteStationWithParam) {
    this.projectDataService.updateActiveRouteStation(station.routeStation);
    this.router.navigate(['/project/test-area']);
  }


  public updateSolardata(station: ProjectRouteStationWithParam) {
    this.deviceService.refreshDataFromChannels(station.routeStation.id).subscribe(data => this.alertService.alert("Solar Daten werden aktualisiert", AlertTypes.info));
  }


  public findFreeId(array: ProjectRouteStationWithParam[]) {
    const sortedArray = array
      .slice() // Make a copy of the array.
      .sort(function (a, b) { return a.routeStation.z - b.routeStation.z }); // Sort it.
    let previousId = 0;
    for (let element of sortedArray) {
      if (element.routeStation.z != (previousId + 1)) {
        // Found a gap.
        return previousId + 1;
      }
      previousId = element.routeStation.z;
    }
    // Found no gaps.
    return previousId + 1;
  }

  public canBeSwapped(station: ProjectRouteStationWithParam, area: SchemaLineWithPosition): boolean {
    let canBeSwapped = false;
    if (area.station!.length > 0) {
      let highestZ = Math.max(...area.station!.map(x => x.routeStation.z!));
      if (station.routeStation.z === highestZ) {
        canBeSwapped = false;
      }
      else canBeSwapped = true;
    }
    return canBeSwapped

  }

  public onDropStation(event: CdkDragDrop<any>): void {
   /*  if (event.currentIndex === event.previousIndex) return; */

    if (event.previousContainer.data.station) {
      let station = event.previousContainer.data.station[event.previousIndex];
      station.routeStation.y = +event.container.id;
      station.routeStation.x = event.container.data.x;


      let x = station.routeStation.x
      let y = station.routeStation.y

      if (y === 1) {
        let coordinate = this.line.areaA.find(areaA => areaA.x === x)
        let stations = coordinate?.station
        if (stations?.length === 0 || stations === undefined) { /* hier wird z dann richtig gesetzt */
          station.routeStation.z = 1
        }
        else {
          let gap = this.findFreeId(stations!)
          if (gap === 0) {
            station.routeStation.z = stations.length + 1
          }
          else {
            station.routeStation.z = gap
          }
        }
      }
      if (y === 2) {
        let coordinate = this.line.areaB.find(areaB => areaB.x === x)
        let stations = coordinate?.station
        if (stations?.length === 0 || stations === undefined) {
          station.routeStation.z = 1
        }
        else {
          let gap = this.findFreeId(stations!)
          if (gap === 0) {
            station.routeStation.z = stations.length + 1
          }
          else {
            station.routeStation.z = gap
          }
        }
      }

      this.stationService.editProjectRouteStation(station.routeStation.id, station.routeStation).subscribe(() => {
        this.projectDataService.updateActiveGroup(this.activeGroup);
      });
    }
  }

  onRadarLaneBClicked(area: any) {
    let y = 2;
    let x = area.x;
    let activeGroupId = this.activeGroup?.id;
    let targetObj = this.projectRouteStations?.find(element => element.routeStation.x === x && element.routeStation.y === y && element.routeStation.groupId === activeGroupId)
    if (!targetObj) return;
    let targetsRadare = targetObj?.deviceParameter.radar
    if (targetsRadare!.length > 0 && targetsRadare !== undefined) {
      this.openRadarDataChart(targetsRadare, targetObj)
    }
  }

  onRadarLaneAClicked(area: any) {
    let x = area.x;
    let activeGroupId = this.activeGroup?.id;

    let targetObj = this.projectRouteStations?.find(element => element.routeStation.x === x && element.routeStation.groupId === activeGroupId && (element.routeStation.y === 1 || 2))
    if (!targetObj) return;
    let targetsRadare = targetObj?.deviceParameter.radar
    if (targetsRadare!.length > 0 && targetsRadare !== undefined) {
      this.openRadarDataChart(targetsRadare, targetObj)
    }
  }

  public openRadarDataChart(radar: any, routeStation: any,): void {
    this.dialog.open(RadarDataChartComponent, {
      data: { routeStation: routeStation, radar: radar, samId: routeStation.routeStation.samId },
      width: '98vw'
    });
  }

  public getAllSWAConfigs(): void {
    this.settings = '';
    this.formattedJson = '';
    this.validJson = '';
    this.isJsonValid = undefined;
    this.matchingSWAConfig = undefined;
    if (this.activeGroup)
      this.SWAConfigService.getSWAConfigs(this.activeGroup.id).subscribe(data => {
        if (data === null) { return; }
        else {
          this.matchingSWAConfig = data[0];
          if (this.matchingSWAConfig?.active) {
            this.MQResultService.getMQResultByGroupId(this.activeGroup!.id).subscribe(MQResult => this.MQResults = MQResult)
          }
        }
      })
  }

  public onExpandSchemeClicked(): void {
    for (let area of this.line.areaA) {
      area.isActive = true;
    }
    for (let area of this.line.areaB) {
      area.isActive = true;
    }
  }

  public onCollapseSchemeClicked(): void {
    this.startTimer()
    if (!this.projectRouteStations) {
      return;
    }
    if (this.projectRouteStations.length === 0) {
      for (let area of this.line.areaA) {
        area.isActive = false;
      }
      for (let area of this.line.areaB) {
        area.isActive = false;
      }
      return;
    }
    let stationsA: ProjectRouteStationWithParam[] = [];
    let stationsB: ProjectRouteStationWithParam[] = [];
    for (let station of this.projectRouteStations) {
      if (station.routeStation.y === 1) {
        stationsA.push(station);
      } else if (station.routeStation.y === 2) {
        stationsB.push(station);
      }
    }

    let stations = this.projectRouteStations.sort(
      (a, b) => b.routeStation.x - a.routeStation.x);
    for (let area of this.line.areaA) {
      if (stationsA[0]) {
        if (area.x > stations[0].routeStation.x) {
          area.isActive = false;
        }
      } else {
        area.isActive = false;
      }
    }
    for (let area of this.line.areaB) {
      if (stationsB[0]) {
        if (area.x > stations[0].routeStation.x) {
          area.isActive = false;
        }
      } else {
        area.isActive = false;
      }
    }
  }

  private stopTimer() {
    if (this.timer)
      clearInterval(this.timer)
  }

  private startTimer() {
    this.stopTimer();
    this.timer = setInterval(() => {
      this.countdown--;

      if (this.countdown <= 0) {
        if (this.activeGroup) {
          this.projectDataService.updateActiveGroup(this.activeGroup);

          this.countdown = 30;
        }
      }
    }, 1000);
  }

  private fillLineWithStations(routeStations: ProjectRouteStationWithParam[]): void {
    this.colorRoadByZ()
    this.getChannelErrorsByGroupId(routeStations[0].routeStation.groupId)
    if (!this.isGroupChanged) {
      this.line = new SchemaLine(
        [{ station: undefined, x: 1, isActive: this.line.areaA[0].isActive, line: [], colorArr: [] }, { station: undefined, x: 2, isActive: this.line.areaA[1].isActive, line: [], colorArr: [] },
        { station: undefined, x: 3, isActive: this.line.areaA[2].isActive, line: [], colorArr: [] }, { station: undefined, x: 4, isActive: this.line.areaA[3].isActive, line: [], colorArr: [] },
        { station: undefined, x: 5, isActive: this.line.areaA[4].isActive, line: [], colorArr: [] }, { station: undefined, x: 6, isActive: this.line.areaA[5].isActive, line: [], colorArr: [] },
        { station: undefined, x: 7, isActive: this.line.areaA[6].isActive, line: [], colorArr: [] }, { station: undefined, x: 8, isActive: this.line.areaA[7].isActive, line: [], colorArr: [] },
        { station: undefined, x: 9, isActive: this.line.areaA[8].isActive, line: [], colorArr: [] }, { station: undefined, x: 10, isActive: this.line.areaA[9].isActive, line: [], colorArr: [] },
        { station: undefined, x: 11, isActive: this.line.areaA[10].isActive, line: [], colorArr: [] }, { station: undefined, x: 12, isActive: this.line.areaA[11].isActive, line: [], colorArr: [] },
        { station: undefined, x: 13, isActive: this.line.areaA[12].isActive, line: [], colorArr: [] }, { station: undefined, x: 14, isActive: this.line.areaA[13].isActive, line: [], colorArr: [] },
        { station: undefined, x: 15, isActive: this.line.areaA[14].isActive, line: [], colorArr: [] }],
        [{ station: undefined, x: 1, isActive: this.line.areaB[0].isActive, line: [], colorArr: [] }, { station: undefined, x: 2, isActive: this.line.areaB[1].isActive, line: [], colorArr: [] },
        { station: undefined, x: 3, isActive: this.line.areaB[2].isActive, line: [], colorArr: [] }, { station: undefined, x: 4, isActive: this.line.areaB[3].isActive, line: [], colorArr: [] },
        { station: undefined, x: 5, isActive: this.line.areaB[4].isActive, line: [], colorArr: [] }, { station: undefined, x: 6, isActive: this.line.areaB[5].isActive, line: [], colorArr: [] },
        { station: undefined, x: 7, isActive: this.line.areaB[6].isActive, line: [], colorArr: [] }, { station: undefined, x: 8, isActive: this.line.areaB[7].isActive, line: [], colorArr: [] },
        { station: undefined, x: 9, isActive: this.line.areaB[8].isActive, line: [], colorArr: [] }, { station: undefined, x: 10, isActive: this.line.areaB[9].isActive, line: [], colorArr: [] },
        { station: undefined, x: 11, isActive: this.line.areaB[10].isActive, line: [], colorArr: [] }, { station: undefined, x: 12, isActive: this.line.areaB[11].isActive, line: [], colorArr: [] },
        { station: undefined, x: 13, isActive: this.line.areaB[12].isActive, line: [], colorArr: [] }, { station: undefined, x: 14, isActive: this.line.areaB[13].isActive, line: [], colorArr: [] },
        { station: undefined, x: 15, isActive: this.line.areaB[14].isActive, line: [], colorArr: [] }]);
    } else {
      this.line = new SchemaLine(
        [{ station: undefined, x: 1, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 2, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 3, isActive: false, line: [], colorArr: [] },
        { station: undefined, x: 4, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 5, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 6, isActive: false, line: [], colorArr: [] },
        { station: undefined, x: 7, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 8, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 9, isActive: false, line: [], colorArr: [] },
        { station: undefined, x: 10, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 11, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 12, isActive: false, line: [], colorArr: [] },
        { station: undefined, x: 13, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 14, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 15, isActive: false, line: [], colorArr: [] }],
        [{ station: undefined, x: 1, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 2, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 3, isActive: false, line: [], colorArr: [] },
        { station: undefined, x: 4, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 5, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 6, isActive: false, line: [], colorArr: [] },
        { station: undefined, x: 7, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 8, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 9, isActive: false, line: [], colorArr: [] },
        { station: undefined, x: 10, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 11, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 12, isActive: false, line: [], colorArr: [] },
        { station: undefined, x: 13, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 14, isActive: false, line: [], colorArr: [] }, { station: undefined, x: 15, isActive: false, line: [], colorArr: [] }]);
      this.isGroupChanged = false;
    }

    this.unassignedStations = [];
    this.setLinesOver6(routeStations);
    this.sortMetainformationIntoSchema(routeStations);
    this.calculateTrafficSignPosition();
    let widthA: number[] = [150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150];
    let widthB: number[] = [150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150];
    for (let station of routeStations) {
      if (station.routeStation.y === 1) {
        if (station.routeStation.x > 0 && station.routeStation.x < 16) {
          if (this.line.areaA[station.routeStation.x - 1].station) {
            this.line.areaA[station.routeStation.x - 1].station?.push(station);
            widthA[station.routeStation.x - 1] += 300;
          } else {
            this.line.areaA[station.routeStation.x - 1].station = [station];
            widthA[station.routeStation.x - 1] = 300;
          }
        } else {
          this.unassignedStations.push(station);
        }
      } else if (station.routeStation.y === 2) {
        if (station.routeStation.x > 0 && station.routeStation.x < 16) {
          if (this.line.areaB[station.routeStation.x - 1].station) {
            this.line.areaB[station.routeStation.x - 1].station?.push(station);
            widthB[station.routeStation.x - 1] += 300;
          } else {
            this.line.areaB[station.routeStation.x - 1].station = [station];
            widthB[station.routeStation.x - 1] = 300;
          }
        } else {
          this.unassignedStations.push(station);
        }
      } else {
        this.unassignedStations.push(station);
      }
    }
    this.width[0] = widthA[0] > widthB[0] ? widthA[0] : widthB[0];
    this.width[1] = widthA[1] > widthB[1] ? widthA[1] : widthB[1];
    this.width[2] = widthA[2] > widthB[2] ? widthA[2] : widthB[2];
    this.width[3] = widthA[3] > widthB[3] ? widthA[3] : widthB[3];
    this.width[4] = widthA[4] > widthB[4] ? widthA[4] : widthB[4];
    this.width[5] = widthA[5] > widthB[5] ? widthA[5] : widthB[5];
    this.width[6] = widthA[6] > widthB[6] ? widthA[6] : widthB[6];
    this.width[7] = widthA[7] > widthB[7] ? widthA[7] : widthB[7];
    this.width[8] = widthA[8] > widthB[8] ? widthA[8] : widthB[8];
    this.width[9] = widthA[9] > widthB[9] ? widthA[9] : widthB[9];
    this.width[10] = widthA[10] > widthB[10] ? widthA[10] : widthB[10];
    this.width[11] = widthA[11] > widthB[11] ? widthA[11] : widthB[11];
    this.width[12] = widthA[12] > widthB[12] ? widthA[12] : widthB[12];
    this.width[13] = widthA[13] > widthB[13] ? widthA[13] : widthB[13];
    this.width[14] = widthA[14] > widthB[14] ? widthA[14] : widthB[14];
    this.isCompletelyLoaded = true;

    this.line.areaA.forEach(element => {
      element.station = _.orderBy(element.station, ['routeStation.z'], ['asc'])
    });
    this.line.areaB.forEach(element => {
      element.station = _.orderBy(element.station, ['routeStation.z'], ['asc'])
    });
  }

  private setLinesOver6(routeStations: ProjectRouteStationWithParam[]): void {
    let stationsA: ProjectRouteStationWithParam[] = [];
    let stationsB: ProjectRouteStationWithParam[] = [];

    for (let station of routeStations) {
      if (station.routeStation.y === 1) {
        stationsA.push(station);
      } else if (station.routeStation.y === 2) {
        stationsB.push(station);
      }
    }
    let stations = routeStations.sort(
      (a, b) => b.routeStation.x - a.routeStation.x);
    if (!stations[0]) { return; }
    if (stationsA[0]) {

      for (let area of this.line.areaA) {
        if (area.x <= stations[0].routeStation.x) {
          area.isActive = true;
        }
      }
    }
    if (stationsB[0]) {
      for (let area of this.line.areaB) {
        if (area.x <= stations[0].routeStation.x) {
          area.isActive = true;
        }
      }
    }
  }

  public changeZWithRightNeighbour(station: ProjectRouteStationWithParam): void {
    let oldZ = station.routeStation.z;
    let newZ = station.routeStation.z - 1

    station.routeStation.z = station.routeStation.z + 1;
    this.stationService.editProjectRouteStation(station.routeStation.id, station.routeStation).subscribe(() => {
      this.projectDataService.updateActiveGroup(this.activeGroup);
    });
    if (station.routeStation.y === 1) {
      let area = this.line.areaA.find(x => x.x === station.routeStation.x);
      if (area) {
        let foundStation = area.station?.find(x => x.routeStation.z === station.routeStation.z && x.routeStation.samId !== station.routeStation.samId);

        if (foundStation) { foundStation.routeStation.z = oldZ }


        this.stationService.editProjectRouteStation(foundStation!.routeStation.id, foundStation?.routeStation!).subscribe(() => {
          this.projectDataService.updateActiveGroup(this.activeGroup);
        });
      }
    }
    if (station.routeStation.y === 2) {
      let area = this.line.areaB.find(x => x.x === station.routeStation.x);
      if (area) {
        let foundStation = area.station?.find(x => x.routeStation.z === station.routeStation.z && x.routeStation.samId !== station.routeStation.samId);
        if (foundStation) { foundStation.routeStation.z = oldZ }
        this.stationService.editProjectRouteStation(foundStation!.routeStation.id, foundStation?.routeStation!).subscribe(() => {
          this.projectDataService.updateActiveGroup(this.activeGroup);
        });
      }
    }
  }

  public sortMetainformationIntoSchema(routeStations: ProjectRouteStationWithParam[]) {
    let stations = routeStations.sort(
      (a, b) => b.routeStation.x - a.routeStation.x);
    let collectionMeta: number[] = [];
    if (this.activeGroup?.metaInformation === undefined || this.activeGroup?.metaInformation === null) return;
    this.activeGroup?.metaInformation!.forEach(mi => collectionMeta.push(mi.x))
    let highestMetainfoX = Math.max(...collectionMeta);

    const isAreaCollapsed = (element: boolean) => element === false;
    const isAreaAEmpty = (element: number) => element !== 1;
    const isAreaBEmpty = (element: number) => element !== 2;

    let areaAisEmpty = this.activeGroup?.metaInformation.map((x: ProjectGroupMetaInformation) => x.y).every(isAreaAEmpty) && stations.map((x: ProjectRouteStationWithParam) => x.routeStation.y).every(isAreaAEmpty) /*hier noch den abgleich? */; /* dann größtes (gegenüberliegendes?) x prüfen, und iterieren und alle unter dem größter auf isActive = true setzen */
    let areaBisEmpty = this.activeGroup?.metaInformation.map((x: ProjectGroupMetaInformation) => x.y).every(isAreaBEmpty) && stations.map((x: ProjectRouteStationWithParam) => x.routeStation.y).every(isAreaBEmpty);

    let areaAisCollapsed = this.line.areaA.map(x => x.isActive).every(isAreaCollapsed);
    let areaBisCollapsed = this.line.areaB.map(x => x.isActive).every(isAreaCollapsed);
    if (areaAisCollapsed && areaBisCollapsed) return;
    if (!areaBisEmpty) {
      for (let area of this.line.areaB) {
        if (area.x <= highestMetainfoX) {
          area.isActive = true;
        }
      }
    }
    if (!areaAisEmpty) {
      for (let area of this.line.areaA) {
        if (area.x <= highestMetainfoX) {
          area.isActive = true;
        }
      }
    }
  }

  private setTrafficLanesFromActiveGroup(group: ProjectGroup): void {
    this.freewayLanes = [];
    for (let index = group.trafficLanes; index > 0; index--) {
      this.freewayLanes.push({ id: index });
    }
  }

  private deleteStationFromSchema(station: ProjectRouteStationWithParam): void {
    if (!this.projectRouteStations) { return }
    let editStation = this.projectRouteStations.find(s => s === station);
    if (editStation) {
      editStation.routeStation.x = 0;
      editStation.routeStation.y = 0;
      this.stationService.editProjectRouteStation(editStation.routeStation.id, editStation.routeStation).subscribe(() => {
        this.projectDataService.updateActiveGroup(this.activeGroup);
      });
    }
  }

  private calculateTrafficSignPosition(): void {
    this.isAreaACollapsed = true;
    this.projectRouteStations?.forEach(routeStations => { if (routeStations.routeStation.y === 1) { this.isAreaACollapsed = false }; if (routeStations.routeStation.y === 2) { this.isAreaBCollapsed = false } })
    if (this.isAreaACollapsed && this.isAreaBCollapsed) {
      this.trafficSignPosition = -12
      return
    }
    if (this.isAreaACollapsed) {
      this.trafficSignPosition = 10 + (33 * this.freewayLanes.length / 2) - 16;
    }
    else {
      this.trafficSignPosition = (36 + (33 * this.freewayLanes.length / 2) - 16) * (-1);
    }
  }
}
