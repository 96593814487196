<div class="d-flex justify-content-between">
    <p *ngIf="title.length > 0" class="fw-semibold text-secondary">{{ title }}</p>
    <div>
        <ng-container *ngFor="let method of methods">
            <img (click)="genericTitleMethod(method.name)" class="avs-icon-add mb-3" [src]="method.src"
                alt="Wechselbild erstellen"></ng-container>
    </div>
</div>
<div class="row avs-project-images-container justify-content-center">
    <ng-container *ngFor="let image of images">
        <div (click)="onImageClicked(image)" class="mb-3 avs-project-images">
            <div class="d-flex justify-content-center ">
                <div class="position-relative">
                    <img [src]="environment.apiAddress + image.path" alt="Bilderkatalog">
                    <img *ngIf="image.isAnimated" src="assets/icons/shuffle.svg" alt="animatedImage"
                        class="position-absolute bg-white rounded-5 p-1 " style="top: 1px; right: 2px; height: 20px">
                </div>
            </div>
            <div class="small text-center">Stellcode: {{image.stellcode}}</div>
        </div>
    </ng-container>
</div>