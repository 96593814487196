<div class="row m-3 avs-project-body pb-4">
  <div class="d-flex justify-content-start d-none d-lg-block d-xl-block">
    <span class="pb-1 pe-2 avs-menu-hide">
      <img *ngIf="!isSmallMenu" class="mb-1 me-1" src="../../../assets/icons/chevron-left.svg"
        alt="Pfeil Menu einklappen">
      <img *ngIf="isSmallMenu" class="mb-1 me-1" src="../../../assets/icons/chevron-right.svg"
        alt="Pfeil Menu ausklappen">
      <span (click)="onHideMenuBtnClick()">{{ isSmallMenu ? 'Menü einblenden' : 'Menü ausblenden'}}</span>
    </span>
  </div>
  <div class=" p-3 mt-3" [ngClass]="isSmallMenu ? 'd-none' : 'col-4 d-none d-lg-block d-xl-block'">
    <div class="card shadow rounded-0">
      <avs-project-menu></avs-project-menu>
    </div>
  </div>
  <div class="p-3" [ngClass]="isSmallMenu ? 'col-12' : ' col-lg-8'">
    <div class="mt-lg-4 ms-lg-0 ms-2" *ngIf="activeProject">
      <span class="avs-label-position-nav">Sie befinden sich hier: </span>
      <span class="avs-position-nav">{{activeProject.name}}</span>
      <span *ngIf="activeGroup"> |
        <span class="avs-position-nav">{{activeGroup.name}}</span>
      </span>
      <span *ngIf="activeRouteStation"> |
        <span class="avs-position-nav">{{activeRouteStation.name}}</span>
      </span>
    </div>
    <div class="d-none d-lg-block d-xl-block">
      <a class="avs-nav-button me-2 mt-2" routerLinkActive="active" routerLink="map" mat-raised-button>Karte</a>
      <a class="avs-nav-button me-2 mt-2" routerLinkActive="active" routerLink="mapOld" mat-raised-button>Karte alt</a>
      <a class="avs-nav-button me-2 mt-2" routerLinkActive="active" routerLink="station-overview"
        mat-raised-button>Stationsübersicht</a>
      <a class="avs-nav-button me-2 mt-2" routerLinkActive="active" routerLink="test-area" mat-raised-button
        *canAccess="[1,5]">Testbereich</a>
      <a class="avs-nav-button me-2 mt-2" routerLinkActive="active" routerLink="schema" mat-raised-button>Schema</a>
      <a class="avs-nav-button me-2 mt-2" routerLinkActive="active" routerLink="settings" mat-raised-button
        *canAccess="[1,5]">Einstellungen</a>
      <a class="avs-nav-button me-2 mt-2" routerLinkActive="active" routerLink="images" mat-raised-button
        *canAccess="[1,5]">Bilder</a>
      <a class="avs-nav-button me-2 mt-2" routerLinkActive="active" routerLink="documents" mat-raised-button
        *canAccess="[1,5]">Dokumente</a>
      <a class="avs-nav-button me-2 mt-2" routerLinkActive="active" routerLink="notes" mat-raised-button
        *canAccess="[1,5]">Notizen</a>
      <a class="avs-nav-button me-2 mt-2" routerLinkActive="active" routerLink="time-switch" mat-raised-button
        *canAccess="[1,5]">Zeitschaltuhr</a>
      <a class="avs-nav-button me-2 mt-2" routerLinkActive="active" routerLink="swa-config" mat-raised-button
        *canAccess="[1,5]">SWA</a>
      <a class="avs-nav-button me-2  mt-2" routerLinkActive="active" routerLink="scenario"
        mat-raised-button>Sonderprogramme</a>
      <a *ngIf="(user.firstName === 'Meet' && user.lastName === 'Modi') " class="avs-nav-button mt-2"
        routerLinkActive="active" routerLink="lisa" mat-raised-button>Lisa Messages</a>
    </div>
    <div class="d-block d-lg-none d-xl-none">
      <div class="d-flex justify-content-between">
        <div class="avs-menu-hide ms-2" (click)="onProjectMenuClicked()">
          <span>Projekt Menü</span>
          <img *ngIf="!isProjectMenuOpen" class="ms-1" src="assets/icons/caret-down.svg"
            alt="Project Menu Pfeil nach unten">
          <img *ngIf="isProjectMenuOpen" class="ms-1" src="assets/icons/caret-up.svg"
            alt="Project Menu Pfeil nach unten">
        </div>
        <div class="me-2">
          <div class="position-relative">
            <img *ngIf="!isProjectSelectOpen" class="avs-menu-icon p-1" height="36" src="assets/icons/list.svg"
              (click)="isProjectSelectOpen = true" alt="menu icon button">
            <avs-dropdown class="avs-project-nav-select position-absolute" *ngIf="isProjectSelectOpen"
              [title]="'Seite auswählen'" [selectInput]="projectMenuSelectInput"
              (onCloseBtnClicked)="isProjectSelectOpen = false" (onItemClicked)="isProjectSelectOpen = false">
            </avs-dropdown>
          </div>
        </div>
      </div>
      <div *ngIf="isProjectMenuOpen" class="card shadow rounded-0 mt-3">
        <avs-project-menu></avs-project-menu>
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>