<ng-container *ngIf="activeRouteStation">
    <div class="row">
        <div class="col-xl-6 mt-3">
            <div class="card shadow rounded-0 p-3 h-100">
                <p class="fw-semibold text-secondary">Meta</p>
                <div class="row">
                    <div class="d-flex mb-1 avs-station-settings-samID">
                        <div class="ms-1 mt-1">Sam&nbsp;ID</div>
                        <div class="text-secondary w-100 border-bottom ms-1 mt-1 mb-1">{{activeRouteStation.samId}}
                        </div>
                    </div>
                    <div class="d-flex mb-1 avs-station-settings-lastData">
                        <div class="ms-1 mt-1">Letzte&nbsp;Daten</div>
                        <div class="mt-1 ms-1 mb-1 text-secondary w-100 border-bottom">{{activeRouteStation.lastData ?
                            (activeRouteStation.lastData | date:'dd.MM.YYYY') : 'keine Daten'}}
                        </div>
                    </div>
                    <div class="d-flex mb-1 avs-station-settings-version">
                        <div class="ms-1 mt-1">Version</div>
                        <div class="ms-1 mt-1 mb-1 text-secondary w-100 border-bottom">{{activeRouteStation.versionId}}
                        </div>
                    </div>
                </div>
                <avs-text-input class="mt-3" label="Name" [value]="activeRouteStation.name"
                    (valueChange)="onMetaValueChange($event, RouteStationMetaDataTypes.name)"
                    placeholder="Stationsnamen eingeben...">
                </avs-text-input>

                <avs-text-input class="mt-3" label="Telefon"
                    (valueChange)="onMetaValueChange($event, RouteStationMetaDataTypes.telefon)"
                    [value]="activeRouteStation.phoneNo" placeholder="Telefon Nr. eingeben...">
                </avs-text-input>
                <div class="d-flex justify-content-end mt-3">
                    <button class="btn btn-outline-success mt-auto rounded-0" type="button" *ngIf="stationMetaData"
                        (click)="onSaveMetaDataClicked()">
                        <span>Speichern</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-xl-6 mt-3">
            <div class="card shadow rounded-0 h-100">
                <div class="placeholder-map-route-station"></div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-xl-6 mt-3">
            <div class="card rounded-0 shadow p-3 h-100">
                <p class="text-secondary fw-semibold">Gruppe</p>
                <div>
                    <p class="ms-2">Zugeordnete Gruppe</p>
                    <div class="avs-route-station-settings-dropdown-container justify-content-between ">
                        <div [ngClass]="isGroupDropdownOpen ? 'border-danger' : ''"
                            class="border d-flex justify-content-between" role="button"
                            (click)="onGroupDropdownClicked()">
                            <div class="ms-2 p-1">{{ activeGroup?.name }} </div>
                            <img *ngIf="!isGroupDropdownOpen" class="me-2" src="assets/icons/caret-down.svg"
                                alt="caret-down open select">
                            <img *ngIf="isGroupDropdownOpen" class="me-2" src="assets/icons/caret-up.svg"
                                alt="caret-up close select">
                        </div>
                        <div *ngIf="routerUrl === '/device/settings'">
                            <avs-dropdown [selectInput]="allNotArchivedProjectGroups" [activeItem]="activeGroup"
                                (onItemClicked)="onGroupDropdownValueChange($event)" [isHeaderActive]="false"
                                *ngIf="isGroupDropdownOpen" [isSmallDropdown]="false"
                                class="avs-route-station-settings-dropdown-container">
                            </avs-dropdown>
                        </div>
                        <div *ngIf="routerUrl !== '/device/settings'">
                            <avs-dropdown [selectInput]="allProjectGroups" [activeItem]="activeGroup"
                                (onItemClicked)="onGroupDropdownValueChange($event)" [isHeaderActive]="false"
                                *ngIf="isGroupDropdownOpen" [isSmallDropdown]="false"
                                class="avs-route-station-settings-dropdown-container">
                            </avs-dropdown>
                        </div>
                    </div>
                    <div class="text-end" *ngIf="isSelectedGroupChanged">
                        <button class="btn btn-outline-success rounded-0 mt-4" type="button"
                            (click)="onSaveSelectedGroupClicked()">Speichern</button>
                    </div>
                </div>

            </div>
        </div>

        <div class="col-xl-6 mt-3">
            <div class="card rounded-0 shadow p-3 h-100">
                <div class="d-flex">
                    <!--  <div class="col-5 border-end">
                        <p class="text-secondary fw-semibold">Schema Position</p>
                        <div class="me-3">
                            <avs-text-input label="X"
                                            [value]="activeRouteStation.x"
                                            placeholder="X-Position..."
                                            (valueChange)="onPositionValueChange($event, RouteStationPositionDataTypes.schemaX)">
                            </avs-text-input>
                        </div>
                        <div class="mt-3 me-3">
                            <avs-text-input label="Y"
                                            [value]="activeRouteStation.y"
                                            placeholder="Y-Position..."
                                            (valueChange)="onPositionValueChange($event, RouteStationPositionDataTypes.schemaY)">
                            </avs-text-input>
                        </div>
                    </div> -->
                    <div class="col-12">
                        <div class="d-flex justify-content-between">
                            <p class="text-secondary fw-semibold ms-3">Ort</p>
                            <div>
                                <a href="https://maps.google.com/?q={{activeRouteStation.latitude}},{{activeRouteStation.longitude}}"
                                    target="_blank" class="btn">
                                    <img class="avs-icon-edit p-2" src="../../../../assets/icons/pin-map-fill.svg"
                                        alt="Open" height="36"></a>
                                <button class="btn" (click)="onCopyCoordinatesLinkBtnClick()"><img
                                        class="avs-icon-edit p-2" src="../../../../assets/icons/copy.svg" alt="Open"
                                        height="36"></button>
                            </div>
                        </div>
                        <div class="row">

                            <avs-text-input class="col-xxl-6 mb-2" label="Latitude" [value]="activeRouteStation.latitude ?
                                                activeRouteStation.latitude.toLocaleString(
                                                'en-EN', {minimumFractionDigits: 14}) : ''" placeholder="latitude..."
                                (valueChange)="onPositionValueChange($event, RouteStationPositionDataTypes.latitude)">
                            </avs-text-input>


                            <avs-text-input class="col-xxl-6 mb-2" label="Longitude" [value]="activeRouteStation.longitude ?
                                                activeRouteStation.longitude.toLocaleString(
                                                    'en-EN', {minimumFractionDigits: 14}) : ''"
                                placeholder="longitude..."
                                (valueChange)="onPositionValueChange($event, RouteStationPositionDataTypes.longitude)">
                            </avs-text-input>

                        </div>
                        <div class="mt-3 mb-3 justify-content-center w-100 d-flex">
                            <button class="btn" (click)="onGPSDataInProjectClick()"> <img
                                    src="../../../../assets/icons/arrow-down-up.svg"
                                    alt="GPS Daten ins Projekt transferieren" height="16"></button>

                        </div>
                        <div class="row">
                            <div class="mb-1 w-50 text-nowrap">
                                <div class="ms-2 mt-1">GPS-Latitude</div>
                                <div class="text-secondary w-100 border-bottom ms-2 mt-1 mb-1">
                                    {{routeStationsGPSdata?.gps?.latitude ? routeStationsGPSdata?.gps?.latitude : 0 }}
                                </div>
                            </div>
                            <div class="mb-1  w-50  text-nowrap ">
                                <div class="ms-2 mt-1">GPS-Longitude</div>
                                <div class="text-secondary w-100 border-bottom ms-2 mt-1 mb-1">
                                    {{routeStationsGPSdata?.gps?.longitude ? routeStationsGPSdata?.gps?.longitude : 0}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-end mt-3">
                    <button class="btn btn-outline-success mt-auto rounded-0" type="button" *ngIf="stationPositionData"
                        (click)="onSavePositionDataClicked()">
                        <span>Speichern</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 mt-3">
            <div class="card shadow rounded-0 p-3">
                <div class="d-flex justify-content-between">
                    <div>
                        <span class="text-secondary fw-semibold">Datenendkanäle</span>
                        <span *ngIf="dataEndChannels.length < 1" class="fw-semibold text-danger ms-3">Keine
                            Datenendkanäle angelegt!</span>
                    </div>
                    <img class="avs-icon-add mb-3" src="assets/icons/plus.svg" alt="icon-add-DataEndChannel"
                        (click)="onAddDataEndChannelClicked()">
                </div>
                <avs-data-end-channel-table class="ms-xxl-5 me-xxl-5" [dataEndChannels]="dataEndChannels"
                [samId]="this.activeRouteStation.samId"
                    [projectId]="this.activeGroup?.projectId" [routeStationGuid]="this.activeRouteStation.id"
                    (dataEndChannelRefresh)="dataEndChannelRefresh.emit(true)"
                    [dataEndChannelErrors]="dataEndChannelErrors"
                    (dataEndChannelErrorsRefresh)="dataEndChannelErrorsRefresh.emit(true)" [channelAdded]="channelAdded"
                    (channelAddedRefresh)="channelAddedRefresh.emit(true)">
                </avs-data-end-channel-table>
            </div>
        </div>
    </div>
</ng-container>
