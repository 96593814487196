import { ProjectDataService } from "../shared/services/project-data.service";
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectRouteStationWithParam } from "../shared/interfaces/project-route-station.class";
import { ProjectGroup } from "../shared/interfaces/project-group.interface";
import { ImageDataService } from "../shared/services/image-data.service";
import { ProjectImageData } from "../shared/interfaces/project-image-data.interface";
import { Subscription } from "rxjs";
import { DeviceService } from "src/app/device/shared/services/device.service";
import { SchemaDataEndChannelError } from "../project-schema/shared/interfaces/schema-data-end-channel-error.interface";

@Component({
  selector: 'avs-project-station-overview',
  templateUrl: './project-station-overview.component.html',
  styleUrls: ['./project-station-overview.component.css']
})
export class ProjectStationOverviewComponent implements OnInit, OnDestroy {
  public projectRouteStations: ProjectRouteStationWithParam[] = [];
  public projectGroup: ProjectGroup | undefined;
  public projectImages: ProjectImageData[] = [];

  public subscriptionCollection: Subscription[] = [];
  public projectRouteStationsWithParamSubscription?: Subscription;
  public projectGroupSubscription?: Subscription;
  public projectImagesSubscription?: Subscription;

  public channelErrors: SchemaDataEndChannelError[] = [];

  constructor(private readonly projectDataService: ProjectDataService,
    private readonly imageDataService: ImageDataService,
  private deviceService: DeviceService) {
    this.projectRouteStationsWithParamSubscription = projectDataService.projectsRouteStationsWithParam.subscribe(routeStations => {
      this.projectRouteStations = [];
      if (routeStations) {
        this.projectRouteStations = this.sortRouteStations(routeStations);
        
          this.getChannelErrorsByGroupId(this.projectRouteStations[0].routeStation.groupId)
      }
    });
    this.projectGroupSubscription = projectDataService.activeGroup.subscribe(activeGroup => {
      this.projectGroup = activeGroup;
     
    })
    this.projectImagesSubscription = imageDataService.projectImages.subscribe(images => {
      this.projectImages = images;
    });
  }

  public ngOnInit(): void {
    this.subscriptionCollection.push(this.projectRouteStationsWithParamSubscription!);
    this.subscriptionCollection.push(this.projectGroupSubscription!);
    this.subscriptionCollection.push(this.projectImagesSubscription!);
  }

  public ngOnDestroy(): void {
    this.subscriptionCollection.forEach(sub => sub.unsubscribe());
  }

  public reloadOverview(): void {
    this.projectDataService.updateActiveGroup(this.projectGroup!);
  }

  public getChannelErrorsByGroupId(groupId: number): void {
    this.deviceService.getAllChannelErrorsByGroupId(groupId).subscribe(channelErrors => {
      this.channelErrors = channelErrors
      if (channelErrors.length > 0)
        this.getRouteStationsChannelErrorsByRouteStationId(channelErrors)
    })
  }

  public getRouteStationsChannelErrorsByRouteStationId(channelErrors: SchemaDataEndChannelError[]): void {
    channelErrors.forEach(channelError => {
      channelError.errors.find(error => {
        let routeStation = this.projectRouteStations?.find(routeStation => routeStation.routeStation.samId === channelError.deviceId)
        if (error.de === 11 && error.fg === 6 && error.localbusAddress === 10) {
          if (routeStation) {
            routeStation.deviceParameter.solar.panelError1 = true
            routeStation.deviceParameter.solar.channelError = true;
          }
        }
        if (error.de === 12 && error.fg === 6&& error.localbusAddress === 10) {
          if (routeStation) {
            routeStation.deviceParameter.solar.panelError2 = true
            routeStation.deviceParameter.solar.channelError = true;
          }
        }
        if (error.fg === 6 && (error.de === 10 || error.de === 11 || error.de === 12 || error.de === 13 || error.de === 14) && error.localbusAddress === 10) {
          if (routeStation) {
            routeStation.deviceParameter.solar.channelError = true;
          }
        }
        if (error.fg === 4 && error.de === 1) {
          if (routeStation) {
            routeStation.deviceParameter.led.channelError = true;
          }
        }
        if (error.fg === 1) {
          if (routeStation) {
            routeStation?.deviceParameter.radar?.forEach((radar) => {
              if (radar.de === error.de) { radar.channelError = true }
            })
          }
        }
        if (error.fg === 4 && error.de === 2) {
          if (routeStation) {
            routeStation!.deviceParameter.blinker.channelError = true;
          }
        }

      })
    })
  }


  public sortRouteStations(projectRouteStations: ProjectRouteStationWithParam[]): ProjectRouteStationWithParam[] {
    return projectRouteStations.sort((a, b) => {
      if (a.routeStation.samId < b.routeStation.samId) {
        return -1;
      } else if (a.routeStation.samId > b.routeStation.samId) {
        return 1;
      } else {
        return 0;
      }
    });
  }
}
