import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/core/shared/http.service';

@Injectable({
  providedIn: 'root'
})
export class FuelcellService {

  constructor(private readonly httpService: HttpService) { }


  public setFillingLevel(fillingLevel: number, deviceId: number, deKanal: number, patronNo: number): Observable<any> {
    let body = {
      status: fillingLevel,
      deviceId: deviceId,
      deKanal: deKanal,
      patronNo: patronNo
    }
    return this.httpService.post('Tls/FuelCell/setFillingLevel', body);
  }

  setSystemStatus(status: number, deKanal: number, deviceId: number): Observable<any> {
    let body = {
      status: status,
      deKanal: deKanal,
      deviceId: deviceId
    }
    return this.httpService.post('Tls/FuelCell/setSystemStatus', body);
  }

}