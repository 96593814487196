import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/shared/http.service';
import { SWAConfig } from '../../../project/shared/interfaces/swaconfig';
import { Observable, last } from 'rxjs';
import { WorkloadData } from '../interfaces/workloadData.interface';
import { AssignmentData } from '../interfaces/assignmentData.interface';
import { ChangelogAqFilter } from 'src/app/report/shared/interfaces/changelog-aq-filter.interface';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  constructor(private readonly httpService: HttpService) { }

  public getRouteStationUsageReport(): Observable<WorkloadData> {
    return this.httpService.get<WorkloadData>('Report/RouteStationUsageReport');
  }

  public getAssignedReport(): Observable<AssignmentData> {
    return this.httpService.get<AssignmentData>('Report/AssignedReport');
  }

  public getAQReport(filter: ChangelogAqFilter): Observable<any> {
    return this.httpService.post<any>('Report/AQReport', filter)
  }

  public getAQReportDownload(filter: ChangelogAqFilter): Observable<any> {
    return this.httpService.postBlob('Report/AQReport', filter)
  }

  public getRouteStationsSTMWithFilterNew(id: number, dateFrom: string, dateTo: string, deKanal: number): Observable<any> {
    dateFrom = this.toLocalTime(dateFrom)
    dateTo = this.toLocalTime(dateTo)
    return this.httpService.post<any>('Report/StmReport' + '?dekanal=' + deKanal, { from: dateFrom, to: dateTo, samId: id })
  }

  public getRouteStationsCurrentWithFilterNew(id: number, dateFrom: string, dateTo: string): Observable<any> {
    dateFrom = this.toLocalTime(dateFrom)
    dateTo = this.toLocalTime(dateTo)
    return this.httpService.post<any>('Report/CurrentReport', { from: dateFrom, to: dateTo, samId: id })
  }

  public getRouteStationVoltageWithFilterNew(id: number, dateFrom: string, dateTo: string): Observable<any> {
    dateFrom = this.toLocalTime(dateFrom)
    dateTo = this.toLocalTime(dateTo)
    return this.httpService.post<any>('Report/VoltageReport', { from: dateFrom, to: dateTo, samId: id })
  }

  public getRouteStationsSpeedWithFilterNew(id: number, dateFrom: string, dateTo: string, minutes: number, deKanal: number, projectId: number): Observable<any> {
    dateFrom = this.toLocalTime(dateFrom)
    dateTo = this.toLocalTime(dateTo)
    return this.httpService.post<any>('Report/SpeedReport/?minutes=' + minutes + '&deKanal=' + deKanal, { from: dateFrom, to: dateTo, samId: id, type: 'json', projectId: projectId })
  }

  public getRouteStationsSpeedWithFilterNewPDFMergeTEst(id: number, dateFrom: string, dateTo: string, minutes: number, deKanal: number, projectId: number): Observable<any> {
    dateFrom = this.toLocalTime(dateFrom)
    dateTo = this.toLocalTime(dateTo)
    return this.httpService.postBlob('Report/SpeedReport/?minutes=' + minutes + '&deKanal=' + deKanal, { from: dateFrom, to: dateTo, samId: id, type: 'pdf', projectId: projectId })
  }

  public getRouteStationsSpeedWithFilterDownloadNew(id: number, dateFrom: string, dateTo: string, minutes: number, deKanal: number, filter: string, projectId: number): Observable<any> {
    dateFrom = this.toLocalTime(dateFrom)
    dateTo = this.toLocalTime(dateTo)
    return this.httpService.postBlob('Report/SpeedReport/?minutes=' + minutes + '&deKanal=' + deKanal, { from: dateFrom, to: dateTo, samId: id, type: filter, projectId: projectId })
  }

  public getVoltageOverview(projectIds: number[]): Observable<any> {
    return this.httpService.post<any>('Report/VoltageDiagramm', { listOfProjectIds: projectIds })
  }

  public getRouteStationErrorOverview(): Observable<any> {
    return this.httpService.get<any>('Report/RouteStationErrorOverview')
  }
  public getFuelCellReport(from: string, to: string, type: string, samId: number, groupId: number, projectId?: number): Observable<any> {
    return this.httpService.post<any>('Report/FuelCellReport', { from: this.toLocalTime(from), to: this.toLocalTime(to), type: type, samId: samId, groupId: groupId, projectId: projectId })
  }

  
  lastSunday(year: number, month: number) {
    let date = new Date(year, month, 1, 12);
    let weekday = date.getDay();
    let dayDiff = weekday === 0 ? 7 : weekday;
    let lastSunday = date.setDate(date.getDate() - dayDiff);

    return date;
  }

  isCEST(d: Date): boolean {
    let testD = new Date(d);
    let year = testD.getFullYear();
    let startCET: Date = this.lastSunday(year, 3);
    let endCET: Date = this.lastSunday(year, 10);
    return !(startCET < testD && testD > endCET);
  }

  offset(d: Date): number {
    return this.isCEST(d) ? 2 : 1
  }

  toLocalTime(date: any) {
    let diff = this.offset(date);
    let localDate = new Date(date);
    localDate.setHours(localDate.getHours() + diff);
    return localDate.toISOString().split('Z')[0]
  }
}
