import { Injectable } from "@angular/core";
import { HttpService } from "../../../core/shared/http.service";
import { Observable } from "rxjs";
import { ChangelogDeviceFilter } from "../interfaces/changelog-device-filter.interface";
import { ChangelogGroupFilter } from "../interfaces/changelog-group-filter.interface";
import { ChangelogProjectFilter } from "../interfaces/changelog-project-filter.interface";
import { Changelog } from "../interfaces/changelog.interface";
import { EngineChangelog } from "../interfaces/changelogEngine.interface";

@Injectable({
    providedIn: 'root'
})
export class EngineService {

    constructor(private readonly httpService: HttpService) { }

    public getChangelogDevice(deviceFilter: ChangelogDeviceFilter): Observable<Changelog[]> {
        deviceFilter.from = this.toLocalTime(deviceFilter.from)
        deviceFilter.to = this.toLocalTime(deviceFilter.to)
        return this.httpService.post<Changelog[]>('Engine/Changelog/device', deviceFilter);
    }

    public getChangelogGroup(groupFilter: ChangelogGroupFilter): Observable<Changelog[]> {
        groupFilter.from = this.toLocalTime(groupFilter.from)
        groupFilter.to = this.toLocalTime(groupFilter.to)
        return this.httpService.post<Changelog[]>('Engine/Changelog/group', groupFilter);
    }

    public getChangelogProject(projectFilter: ChangelogProjectFilter): Observable<Changelog[]> {

        projectFilter.from = this.toLocalTime(projectFilter.from)
        projectFilter.to = this.toLocalTime(projectFilter.to)
        return this.httpService.post<Changelog[]>('Engine/Changelog/project', projectFilter);
    }

    public getChangelogGroupDownload(groupFilter: ChangelogGroupFilter): Observable<Blob> {
        groupFilter.from = this.toLocalTime(groupFilter.from)
        groupFilter.to = this.toLocalTime(groupFilter.to)
        return this.httpService.postBlob('Engine/Changelog/group', groupFilter);
    }

    public getChangelogProjectDownload(projectFilter: ChangelogProjectFilter): Observable<Blob> {
        projectFilter.from = this.toLocalTime(projectFilter.from)
        projectFilter.to = this.toLocalTime(projectFilter.to)
        return this.httpService.postBlob('Engine/Changelog/project', projectFilter);
    }

    public getChangelogRouteStationDownload(deviceFilter: ChangelogDeviceFilter): Observable<Blob> {
        deviceFilter.from = this.toLocalTime(deviceFilter.from)
        deviceFilter.to = this.toLocalTime(deviceFilter.to)
        return this.httpService.postBlob('Engine/Changelog/device', deviceFilter);
    }



    /* anderer widergabetyp? analog zu report/aq/device einbauen?  */
    public getEngineChangeLogMQDevice(deviceFilter: ChangelogDeviceFilter): Observable<EngineChangelog[]> {
        deviceFilter.from = this.toLocalTime(deviceFilter.from)
        deviceFilter.to = this.toLocalTime(deviceFilter.to)
        return this.httpService.post<EngineChangelog[]>('Engine/Changelog/MQ/device', deviceFilter);
    }

    public getEngineChangeLogMQGroup(groupFilter: ChangelogGroupFilter): Observable<EngineChangelog[]> {
        groupFilter.from = this.toLocalTime(groupFilter.from)
        groupFilter.to = this.toLocalTime(groupFilter.to)
        return this.httpService.post<EngineChangelog[]>('Engine/Changelog/MQ/group', groupFilter);
    }

    public getEngineChangeLogMQProject(projectFilter: ChangelogProjectFilter): Observable<EngineChangelog[]> {
        projectFilter.from = this.toLocalTime(projectFilter.from)
        projectFilter.to = this.toLocalTime(projectFilter.to)
        return this.httpService.post<EngineChangelog[]>('Engine/Changelog/MQ/project', projectFilter);
    }

    public getEngineChangeLogMQDeviceDownload(deviceFilter: ChangelogDeviceFilter): Observable<Blob> {
        deviceFilter.from = this.toLocalTime(deviceFilter.from)
        deviceFilter.to = this.toLocalTime(deviceFilter.to)
        return this.httpService.postBlob('Engine/Changelog/MQ/device', deviceFilter);
    }

    public getEngineChangeLogMQGroupDownload(groupFilter: ChangelogGroupFilter): Observable<Blob> {
        groupFilter.from = this.toLocalTime(groupFilter.from)
        groupFilter.to = this.toLocalTime(groupFilter.to)
        return this.httpService.postBlob('Engine/Changelog/MQ/group', groupFilter);
    }

    public getEngineChangeLogMQProjectDownload(projectFilter: ChangelogProjectFilter): Observable<Blob> {
        projectFilter.from = this.toLocalTime(projectFilter.from)
        projectFilter.to = this.toLocalTime(projectFilter.to)
        return this.httpService.postBlob('Engine/Changelog/MQ/project', projectFilter);
    }


    lastSunday(year: number, month: number) {
        let date = new Date(year, month, 1, 12);
        let weekday = date.getDay();
        let dayDiff = weekday === 0 ? 7 : weekday;
        let lastSunday = date.setDate(date.getDate() - dayDiff);

        return date;
    }

    isCEST(d: Date): boolean {
        let testD = new Date(d);
        let year = testD.getFullYear();
        let startCET: Date = this.lastSunday(year, 3);
        let endCET: Date = this.lastSunday(year, 10);
        return !(startCET < testD && testD > endCET);
    }


    offset(d: Date): number {
        return this.isCEST(d) ? 2 : 1
    }

    toLocalTime(date: any) {

        let diff = this.offset(date);

        let localDate = new Date(date);
        localDate.setHours(localDate.getHours() + diff);


        return localDate.toISOString().split('Z')[0]
    }
}
