import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { ProjectRouteStation } from "../../shared/interfaces/project-route-station.interface";
import { AlertService } from "../../../shared/alert/shared/alert.service";
import { RouteStationMetaData, RouteStationMetaDataTypes } from "../shared/interfaces/route-station-meta.interface";
import { AlertTypes } from "../../../shared/alert/shared/alert-types";
import { RouteStationPositionData, RouteStationPositionDataTypes } from "../shared/interfaces/route-station-position.interface";
import { RouteStationService } from "../../shared/services/route-station.service";
import { MatDialog } from "@angular/material/dialog";
import { DataEndChannelAddModalComponent } from "../data-end-channel-add-modal/data-end-channel-add-modal.component";
import { GroupService } from "../../shared/services/group.service";
import { ProjectGroup } from "../../shared/interfaces/project-group.interface";
import { ProjectDataService } from "../../shared/services/project-data.service";
import { SelectInput } from "../../../shared/dropdown/shared/select-input.class";
import { DataEndChannel } from "../../shared/interfaces/data-end-channel.interface";
import { PositionGmapService } from '../shared/services/position-gmap.service';
import { DataEndChannelError } from "../../shared/interfaces/data-end-channel-error.interface";
import { DeviceService } from 'src/app/device/shared/services/device.service';
import { ProjectRouteStationWithParam } from '../../shared/interfaces/project-route-station.class';
import { Router } from '@angular/router';
import { DataEndChannelService } from '../../shared/services/data-end-channel.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'avs-route-station-settings',
  templateUrl: './route-station-settings.component.html',
  styleUrls: ['./route-station-settings.component.css']
})
export class RouteStationSettingsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() activeRouteStation: ProjectRouteStation | undefined;
  @Input() activeGroup: ProjectGroup | undefined;
  @Input() dataEndChannels: DataEndChannel[] = [];
  @Input() dataEndChannelErrors: DataEndChannelError[] = [];
  @Input() groupsActiveRouteStation: ProjectRouteStationWithParam[] | undefined;

  @Output() dataEndChannelErrorsRefresh: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() dataEndChannelRefresh: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() stationGroupRelationRefresh: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() channelAddedRefresh: EventEmitter<boolean> = new EventEmitter<boolean>();
  public stationMetaData: RouteStationMetaData | undefined;
  public stationPositionData: RouteStationPositionData | undefined;
  public isGroupDropdownOpen: boolean = false;
  public allProjectGroups: ProjectGroup[] = [];
  public allNotArchivedProjectGroups: ProjectGroup[] = [];
  public routerUrl: string;
  public channelAdded: boolean = false;
  public isSelectedGroupChanged: boolean = false;
  protected readonly RouteStationMetaDataTypes = RouteStationMetaDataTypes;
  protected readonly RouteStationPositionDataTypes = RouteStationPositionDataTypes;
  protected readonly SelectInput = SelectInput;
  public routeStationsGPSdata: any;

  public groupSubscription?: Subscription;
  public searchInput: string = "";



  constructor(private alertService: AlertService,
    private stationService: RouteStationService,
    private dialog: MatDialog,
    private projectDataService: ProjectDataService,
    private groupService: GroupService,
    private positionGmapService: PositionGmapService,
    private deviceService: DeviceService,
    private _router: Router,
    private dataEndChannelService: DataEndChannelService) {
    this.routerUrl = _router.url;
    this.groupSubscription = projectDataService.activeGroup.subscribe(activeGroup => {
      this.activeGroup = activeGroup;
      this.getAllRouteStations();
    });
  }

  public ngOnInit() {
    this.getAllProjectGroups();
  }

  public ngOnDestroy(): void {
    this.groupSubscription?.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.getAllRouteStations()
  }

  public onMetaValueChange(newValue: any, type: RouteStationMetaDataTypes): void {
    if (!this.stationMetaData) {
      this.stationMetaData = { name: undefined, version: undefined, telefon: undefined };
    }
    switch (type) {
      case RouteStationMetaDataTypes.name:
        this.stationMetaData.name = newValue;
        break;
      case RouteStationMetaDataTypes.version:
        this.stationMetaData.version = newValue;
        break;
      case RouteStationMetaDataTypes.telefon:
        this.stationMetaData.telefon = newValue;
        break;
    }
  }

  public onGPSDataInProjectClick(): void {
    if (!this.stationPositionData) {
      this.stationPositionData = { latitude: undefined, longitude: undefined };
    }
    if (this.activeRouteStation) {
      this.activeRouteStation.latitude = this.routeStationsGPSdata.gps.latitude
      this.activeRouteStation.longitude = this.routeStationsGPSdata.gps.longitude
    }
    if (this.stationPositionData) {
      this.stationPositionData.latitude = this.routeStationsGPSdata.gps.latitude;
      this.stationPositionData.longitude = this.routeStationsGPSdata.gps.longitude;
    }
  }

  public onPositionValueChange(newValue: any, type: RouteStationPositionDataTypes): void {
    if (!this.stationPositionData) {
      this.stationPositionData = { latitude: undefined, longitude: undefined };
    }
    switch (type) {
      case RouteStationPositionDataTypes.latitude:
        this.stationPositionData.latitude = newValue;
        break;
      case RouteStationPositionDataTypes.longitude:
        this.stationPositionData.longitude = newValue;
        break;
    }
  }

  /*   public setFallbackSettings(id: number, fg: number, de: number): void {
      this.dataEndChannelService.setFallbackSettings(id, fg, de).subscribe(() => {
        this.alertService.alert('Fallback Einstellungen wurden gesetzt', AlertTypes.info);
      });
    } */

  public onSaveMetaDataClicked(): void {
    if (this.activeRouteStation) {
      this.activeRouteStation.name = this.stationMetaData?.name === undefined ?
        this.activeRouteStation.name : this.stationMetaData.name;
      this.activeRouteStation.versionId = this.stationMetaData?.version === undefined ?
        this.activeRouteStation.versionId : this.stationMetaData.version;
      this.activeRouteStation.phoneNo = this.stationMetaData?.telefon === undefined ?
        this.activeRouteStation.phoneNo : this.stationMetaData.telefon;
      this.stationService.editProjectRouteStation(this.activeRouteStation.id, this.activeRouteStation).subscribe(() => {
        this.alertService.alert('Station Metadaten wurden geändert', AlertTypes.info);
        this.stationMetaData = undefined;
      });
    }
  }

  public onSavePositionDataClicked(): void {
    if (this.activeRouteStation) {
      this.activeRouteStation.latitude = this.stationPositionData?.latitude ? this.stationPositionData.latitude : this.activeRouteStation.latitude;
      this.activeRouteStation.longitude = this.stationPositionData?.longitude ? this.stationPositionData.longitude : this.activeRouteStation.longitude;
      this.stationService.editProjectRouteStation(this.activeRouteStation.id, this.activeRouteStation).subscribe(() => {
        this.alertService.alert('Station Positionsdaten wurden geändert', AlertTypes.info);
        this.stationPositionData = undefined;
      });
    }
  }

  public onAddDataEndChannelClicked(): void {
    const dialogRef = this.dialog.open(DataEndChannelAddModalComponent, { data: this.activeRouteStation });
    dialogRef.afterClosed().subscribe(isNewDataEndCannel => {
      if (isNewDataEndCannel) {
        this.dataEndChannelRefresh.emit();
        this.channelAdded = true;
        this.channelAddedRefresh.emit();
      }
    });
  }

  public getAllProjectGroups() {
    this.groupService.getAllProjectGroups().subscribe(groupData => {
      this.allProjectGroups = groupData;
      this.allNotArchivedProjectGroups = groupData.filter(gd => gd.isArchived != true)
    });

  }

  public getAllRouteStations() {

    if (this.groupsActiveRouteStation)
      this.groupsActiveRouteStation.forEach(element => {

        if (this.activeRouteStation) {
          if (element.routeStation.samId == this.activeRouteStation.samId) { this.routeStationsGPSdata = element.deviceParameter }
        }
      });

    else {
      if (this.activeRouteStation)
        this.deviceService.getDeviceParameter(this.activeRouteStation?.samId!).subscribe(deviceParameter => {
          this.routeStationsGPSdata = deviceParameter
        })
    }
  }

  public onGroupDropdownClicked() {
    this.isGroupDropdownOpen = !this.isGroupDropdownOpen;
  }

  public onGroupDropdownValueChange(selectedGroup: any): void {
    this.activeGroup = selectedGroup;
    this.isSelectedGroupChanged = true;
    this.isGroupDropdownOpen = false;
  }

  public onSaveSelectedGroupClicked() {
    this.isSelectedGroupChanged = false;
    this.isGroupDropdownOpen = false;

    if (this.activeRouteStation) {
      if (this.activeGroup) {
        this.activeRouteStation.groupId = +this.activeGroup.id
        this.activeRouteStation.y = 0;
        this.activeRouteStation.x = 0;
        this.stationService.editProjectRouteStation(this.activeRouteStation.id, this.activeRouteStation).subscribe(() => {
          if (this.activeGroup) {
            this.alertService.alert(`Deine Station wurde der Gruppe ${this.activeGroup.name} zugeordnet.`, AlertTypes.success);
            this.projectDataService.updateActiveGroup(this.activeGroup);
          }
        });
      }
      // this.groupService.editProjectRouteStations(this.activeRouteStation.groupId, [], [this.activeRouteStation.id])
      //   .subscribe((response) => {
      //     if (this.activeRouteStation) {
      //       this.groupService.editProjectRouteStations(this.selectedGroup.id ? +(this.selectedGroup.id) : 0,
      //           [this.activeRouteStation.id], [])
      //         .subscribe((response) => {
      //           this.alertService.alert(`Deine Station wurde der Gruppe ${this.selectedGroup} zugeordnet.`, AlertTypes.success);
      //           this.groupService.getProjectRouteStations(this.selectedGroup.id ? +(this.selectedGroup.id) : 0).subscribe(routeStations => {
      //             this.projectDataService.updateProjectRouteStations(routeStations);
      //           });
      //         });
      //     }
      //   });
    }
  }

  public navigateToSchema() {
    if (this.activeRouteStation) {
      sessionStorage.setItem('activeRouteStation', String(this.activeRouteStation.samId));
      sessionStorage.setItem('activeGroup', String(this.activeRouteStation.groupId));
    }

    if (this.activeGroup) sessionStorage.setItem('activeProject', String(this.activeGroup.projectId));

    this._router.navigate(['/project/schema']);

  }

  public onSearchInputChanged(event: string): void {
    this.searchInput = event;
    this.filterGroups();
  }

  public filterGroups(): void {
    if (this.routerUrl === '/device/settings')
      this.allNotArchivedProjectGroups = this.allNotArchivedProjectGroups.filter(group =>
        group.name.toLowerCase().includes(this.searchInput.toLowerCase())
      );
    if (this.routerUrl === '/project/settings')
      this.allProjectGroups = this.allProjectGroups.filter(group =>
        group.name.toLowerCase().includes(this.searchInput.toLowerCase())
      );

  }


  public onCopyCoordinatesLinkBtnClick(): void {
    let latitude = this.activeRouteStation?.latitude;
    let longitude = this.activeRouteStation?.longitude;

    this.positionGmapService.copyCoordinatesLink(latitude as number, longitude as number)
  }
}
