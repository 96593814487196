<mat-dialog-content>

    <div class="d-flex justify-content-between">
        <h5 class="fw-semibold">Stellcode löschen</h5>
        <img class="ms-3 avs-modal-close-btn mb-1" width="30" src="assets/icons/x.svg" alt="close-modal-icon"
            (click)="onCancelClicked()">
    </div>
    <div>
        <div class="d-flex justify-content-center "> Gruppenauswahl</div>
        <div class="w-100 d-flex justify-content-center ms-2 mb-2">
            <div class="avs-route-station-settings-dropdown-container justify-content-between">
                <div [ngClass]="isGroupDropdownOpen ? 'border-danger' : ''"
                    class="border d-flex justify-content-between" role="button" (click)="onGroupDropdownClicked()">
                    <div class="ms-2 p-1">{{ selectedGroup.name }}</div>
                    <img *ngIf="!isGroupDropdownOpen" class="me-2" src="assets/icons/caret-down.svg"
                        alt="caret-down open select">
                    <img *ngIf="isGroupDropdownOpen" class="me-2" src="assets/icons/caret-up.svg"
                        alt="caret-up close select">
                </div>
                <avs-dropdown [selectInput]="groupSelect" [activeItem]="selectedGroup"
                    (onItemClicked)="onGroupItemClicked($event)" [isHeaderActive]="false" *ngIf="isGroupDropdownOpen"
                    class="avs-route-station-settings-dropdown-container">
                </avs-dropdown>
            </div>
        </div>
        <avs-bilderkatalog [title]="'Bilder'" [images]="images"
            (myClick)="onProjectImageClicked($event)"></avs-bilderkatalog>

    </div>
    <div class="d-flex justify-content-between mt-5 justify-content-between">
        <button class="btn btn-sm btn-outline-danger rounded-0" (click)="onCancelClicked()">Abbrechen</button>
        <button *ngIf="selectedGroup.id !== 0" class="btn btn-sm btn-outline-success rounded-0"
            (click)="onSubmitModalClicked()">Stellcode entfernen</button>
    </div>
</mat-dialog-content>