import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectRouteStation } from "../shared/interfaces/project-route-station.interface";
import { ProjectDataService } from "../shared/services/project-data.service";
import { ProjectImageData } from "../shared/interfaces/project-image-data.interface";
import { ImageDataService } from "../shared/services/image-data.service";
import { ModalService } from "../../shared/modal/shared/modal.service";
import { ProjectService } from "../shared/services/project.service";
import { Project } from "../shared/interfaces/project.interface";
import { AlertService } from "../../shared/alert/shared/alert.service";
import { AlertTypes } from "../../shared/alert/shared/alert-types";
import { SelectInput } from "../../shared/dropdown/shared/select-input.class";
import { MatDialog } from "@angular/material/dialog";
import { SetImageModalComponent } from "./set-image-modal/set-image-modal.component";
import { ImageService } from "../shared/services/image.service";
import { SetImage } from "./shared/set-image.interface";
import { DataEndChannel } from "../shared/interfaces/data-end-channel.interface";
import { DataEndChannelTypeEnum } from "../shared/enums/data-end-channel-type.enum";
import { environment } from "../../../environments/environment";
import { Subscription } from 'rxjs';
import { AnimatedImageModalComponent } from '../project-settings/project-settings/animated-image-modal/animated-image-modal.component';
import { GroupService } from '../shared/services/group.service';
import { RouteStationService } from '../shared/services/route-station.service';
import { AnimatedImageErrorMessage } from './shared/animated-image-error-message';

@Component({
  selector: 'avs-project-images',
  templateUrl: './project-images.component.html',
  styleUrls: ['./project-images.component.css']
})
export class ProjectImagesComponent implements OnInit, OnDestroy {
  public routeStation: ProjectRouteStation | undefined;
  public unassignedImages: ProjectImageData[] = [];
  public stationImages: ProjectImageData[] = [];
  private activeProject: Project | undefined;
  protected readonly environment = environment;
  public subscriptionCollection: Subscription[] = [];
  public activeRouteStationSubscription?: Subscription;
  public projectSubscription?: Subscription;
  public unassignedImagesSubscription?: Subscription;
  public stationImagesSubscription?: Subscription;
  public openImageId: number = 0;
  public filteredImages: ProjectImageData[] = [];
  public assignedImages: ProjectImageData[] | undefined;

  constructor(private projectDataService: ProjectDataService,
    private imageDataService: ImageDataService,
    private modalService: ModalService,
    private projectService: ProjectService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private imageService: ImageService,
    private groupService: GroupService,
    private routeStationService: RouteStationService) {
    this.activeRouteStationSubscription = projectDataService.activeRouteStation.subscribe(station => {
      this.routeStation = station;
      this.getImagesForStation();
    });
    this.projectSubscription = projectDataService.activeProject.subscribe(project => {
      this.activeProject = project;
    });
    this.unassignedImagesSubscription = imageDataService.projectRouteStationUnassignedImages.subscribe(images => {
      this.unassignedImages = images;
    });
    this.stationImagesSubscription = imageDataService.projectRouteStationImages.subscribe(images => {
      this.stationImages = images;
      this.assignedImages = images;
      if (images.length > 0)
        this.rotateImagesPaths(images);/* TODO: kann vermutlich entfernt werden */
    });
  }

  public ngOnInit(): void {
    this.subscriptionCollection.push(this.activeRouteStationSubscription!);
    this.subscriptionCollection.push(this.projectSubscription!);
    this.subscriptionCollection.push(this.unassignedImagesSubscription!);
    this.subscriptionCollection.push(this.stationImagesSubscription!);
    if (this.routeStation !== undefined) {
      this.getImagesForStation();
      this.rotateImagesPaths(this.stationImages);
    }
  }
  public ngOnDestroy(): void {
    this.subscriptionCollection.forEach(sub => sub.unsubscribe());
  }



  public onGenerateAnimatedImageClicked(): void {
    this.filterNextOpenImageId();

    const dialogRef = this.dialog.open(AnimatedImageModalComponent, { data: { data: this.activeProject, openImageId: this.openImageId, notAssignedImages: this.filteredImages, projectImages: this.unassignedImages, assignedImages: this.assignedImages } });
    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined) return;
      this.routeStationService.generateAnimatedImageForRouteStation(this.routeStation?.id!, result.animatedImages, result.stellcode).subscribe((errorMessages: AnimatedImageErrorMessage[]) => {
        this.alertService.alert('Die Animation wurde erfolgreich erstellt', AlertTypes.success);
      },
        (error) => { this.alertService.alert('Die Animation konnte nicht erstellt werden', AlertTypes.error); }
      )
    }
    );
  }


  private filterNextOpenImageId() {
    this.filteredImages = [];
    this.openImageId = 0;
    if (this.unassignedImages) {
      this.unassignedImages.forEach(image => { if (image.imageId > 121) { this.filteredImages.push(image) } })
    }
    this.openImageId = Math.min.apply(Math, this.filteredImages.map(o => o.imageId));
  }
  public rotateImagesPaths(images: ProjectImageData[]): void {
    images.forEach(image => {
      if (image.isAnimated && image.animatedImageIds!.length > 0) { /* für jedes Bild, das für die RouteStation gesetzt wird, wird nun separat geprüft ob ein animatedImageId vorhanden ist */
        this.rotatePathForId(image)
      }
    });
  }

  public rotatePathForId(image: ProjectImageData): void {
    const myValueList = image.animatedImageIds!;

    let i = 0;
    const id = setInterval(() => {
      image.path = this.getPathByImageId(myValueList[i++]); /* iteration durchs animatedimage array, und zieht sich dasnn über die id, den path */
      if (i === myValueList.length) {
        i = 0;
      }
    }, 1000);
  }

  public getPathByImageId(imageId: number) {

    if (this.stationImages) {
      let imageObject = this.stationImages.find((x) => x.imageId == imageId)
      return imageObject?.path as string
    }
    else return
  }

  public onUnassignedProjectImageClicked(image: ProjectImageData): void {
    this.modalService.openDialog('Bild für Station setzen?', 'Abbrechen', 'Bestätigen', image).subscribe(
      () => {
        if (!this.activeProject || !this.routeStation) { return; }
        this.projectService.setImageForRouteStation(this.activeProject.id, this.routeStation.id, image.imageId).subscribe(() => {
          this.alertService.alert('Das Bild wurde zur Station hinzugefügt', AlertTypes.success);
          this.getImagesForStation();
        });
      }
    );
  }

  public newMethod(image: any): void {
    console.log(image);
  }

  public onProjectImageClicked(image: ProjectImageData): void {
    this.dialog.open(SetImageModalComponent, { data: image }).afterClosed().subscribe(result => {
      if (result) {
        this.getImagesForStation();
      }
    });
  }

  public genericMethod(name: string): void {
    if (name === 'onGenerateAnimatedImageClicked') {
      this.onGenerateAnimatedImageClicked();
    }
  }

  private getImagesForStation(): void {
    if (!this.routeStation || !this.activeProject) { return; }
    this.projectService.getAllRouteStationImagesWithStationId(this.activeProject.id, this.routeStation.id).subscribe(images => {
      this.imageDataService.updateProjectRouteStationImages(images);
    });
    this.projectService.getAllUnassignedRouteStationImagesWithStationId(this.activeProject.id, this.routeStation.id).subscribe(images => {
      this.imageDataService.updateUnassignedProjectRouteStationImages(images);
    });
  }
}
