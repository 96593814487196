import { ProjectGroup } from '../../shared/interfaces/project-group.interface';
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { MapPosition } from "./map-position.interface";
import { MapPoint } from "./map-point.interface";
import { Project } from "../../shared/interfaces/project.interface";
import { ProjectSessionStorageData } from '../../shared/interfaces/project-session-storage-data.interface';
import { ProjectDataSessionStorageService } from '../../shared/services/project-data-session-storage.service';
import { ProjectRouteStation } from '../../shared/interfaces/project-route-station.interface';
@Injectable({
  providedIn: 'root'
})
export class MapPositionService {
  private mapPositionSubject$ = new BehaviorSubject<MapPosition>({ latitude: 52, longitude: 10, zoom: 5 });
  public mapPosition = this.mapPositionSubject$.asObservable();
  private mapProjectPointSubject$ = new BehaviorSubject<MapPoint[]>([])
  public mapProjectPoints = this.mapProjectPointSubject$.asObservable();
  private mapGroupPointSubject$ = new BehaviorSubject<MapPoint[]>([])
  public mapGroupPoints = this.mapGroupPointSubject$.asObservable();
  private removeProjectLayerSubject$ = new BehaviorSubject<boolean>(false)
  public removeProjectLayer = this.removeProjectLayerSubject$.asObservable();
  private removeGroupLayerSubject$ = new BehaviorSubject<boolean>(false)
  public removeGroupLayer = this.removeGroupLayerSubject$.asObservable();
  private mapRouteStationPointSubject$ = new BehaviorSubject<MapPoint[]>([])
  public mapRouteStationPoints = this.mapRouteStationPointSubject$.asObservable();




  public updateMapPosition(mapPosition: MapPosition): void {
    this.mapPositionSubject$.next(mapPosition);
  }

  private updateMapProjectPoints(matPoints: MapPoint[]): void {
    this.mapProjectPointSubject$.next(matPoints);
  }

  private updateMapGroupPoints(matPoints: MapPoint[]): void {
    this.mapGroupPointSubject$.next(matPoints);
  }

  private updateRouteStationPoints(matPoints: MapPoint[]): void {
    this.mapRouteStationPointSubject$.next(matPoints);
  }
  
  public initRemoveLayer(): void {
    this.removeProjectLayerSubject$.next(true);
  }

  public initGroupRemoveLayer(): void {
    this.removeGroupLayerSubject$.next(true);
  }

  public setProjectMapPoints(projects: Project[]): void {
    let mapPoints: MapPoint[] = [];
    for (let project of projects) {
      if (project.isActive) {mapPoints.push(this.getMapPoint(project.latitude, project.longitude)); }
    }
    this.updateMapProjectPoints(mapPoints);
  }

  public setGroupMapPoints(groups: ProjectGroup[], projectId: any): void {
    let mapPoints: MapPoint[] = [];
    for (let group of groups) {   
     
     /*  if(projectId == group.projectId)  */mapPoints.push(this.getMapPoint(group.latitude, group.longitude));/* Nur die Gruppen des aktiven projektes werden angezeigt */
    } 
    this.updateMapGroupPoints(mapPoints);
  }

  public setRouteStationMapPoints(routeStations: ProjectRouteStation[], groupId: any): void {
    let mapPoints: MapPoint[] = [];
    for (let routeStation of routeStations) {
      if(groupId == routeStation.groupId) mapPoints.push(this.getMapPoint(routeStation.latitude, routeStation.longitude));
    }
    this.updateRouteStationPoints(mapPoints);
  }

  private getMapPoint(latitude: number, longitude: number): MapPoint {
    return {
      type: "Feature", /* wofür muss dieser definiert sein? */
      properties: {},
      geometry: {
        type: "Point", /* type point? mal in erfahrung bringen was das beinflusst */
        coordinates: [longitude, latitude]
      }
    } as MapPoint
  }
}
