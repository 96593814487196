import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DataEndChannelTypeEnum, getDataEndChannelTypeSelect, getDataEndChannelTypeString} from "../../shared/enums/data-end-channel-type.enum";
import {SelectInput} from "../../../shared/dropdown/shared/select-input.class";
import {AlertService} from "../../../shared/alert/shared/alert.service";
import {AlertTypes} from "../../../shared/alert/shared/alert-types";
import {DataEndChannel} from "../../shared/interfaces/data-end-channel.interface";
import {ProjectRouteStation} from "../../shared/interfaces/project-route-station.interface";
import {DataEndChannelService} from "../../shared/services/data-end-channel.service";
import {DisplayService} from "../../../device/shared/services/display.service";
import {RadarService} from "../../../device/shared/services/radar.service";

@Component({
  selector: 'avs-data-end-channel-add-modal',
  templateUrl: './data-end-channel-add-modal.component.html',
  styleUrls: ['./data-end-channel-add-modal.component.css']
})
export class DataEndChannelAddModalComponent implements OnInit {
  public isDataEndChannelTypeOpen: boolean = false;
  public isDeviceTypeOpen: boolean = false;
  public deviceType?: SelectInput;
  public dataEndChannelTypeSelect: SelectInput[] = getDataEndChannelTypeSelect();
  public displaySelect: SelectInput[] = [];
  public radarSelect: SelectInput[] = [];
  public deviceTypeSelect: SelectInput[] = [];
  public isExpandedSettingsOpen: boolean = false;
  public dataEndChannel: DataEndChannel = {
    dataEndChannelType: DataEndChannelTypeEnum.Unknown, fg: DataEndChannelTypeEnum.Unknown, de: 0,
    localbusAddress: 0, eakanalnr: 0} as DataEndChannel;
  protected readonly getDataEndChannelTypeString = getDataEndChannelTypeString;
  protected readonly DataEndChannelTypeEnum = DataEndChannelTypeEnum;

  constructor(private dialogRef: MatDialogRef<DataEndChannelAddModalComponent>,
              private alertService: AlertService,
              @Inject(MAT_DIALOG_DATA) public data: ProjectRouteStation,
              private dataEndChannelService: DataEndChannelService,
              private displayService: DisplayService,
              private radarService: RadarService) {}

  public ngOnInit(): void {
    this.getDeviceTypesForSelect();
  }

  public onDataEndChannelTypeSelectClicked(): void {
    this.isDataEndChannelTypeOpen = !this.isDataEndChannelTypeOpen;
    this.isDeviceTypeOpen = false;
  }

  public onDeviceTypeSelectClicked(): void {
    this.isDeviceTypeOpen = !this.isDeviceTypeOpen;
  }

  public onDataEndChannelTypeSelectItemClicked(item: SelectInput): void {
    if (item.id !== undefined) {
      this.dataEndChannel.dataEndChannelType = +(item.id);
      this.isDataEndChannelTypeOpen = false;
      this.deviceType = undefined;
      switch (item.id) {
        case 0:
          this.dataEndChannel.fg = 6;
          break;
        case 1:
          this.dataEndChannel.fg = 4;
          break;
        case 2:
          this.dataEndChannel.fg = 4;
          break;
        case 3:
          this.dataEndChannel.fg = 4;
          break;
        case 4:
          this.dataEndChannel.fg = 1;
          break;
        case 5:
          this.dataEndChannel.fg = 4;
          break;
      }
    }
  }

  public onDeviceItemClicked(item: SelectInput): void {
    this.deviceType = item;
    this.isDeviceTypeOpen = false;
  }

  public onExpandedSettingsClicked(): void {
    this.isExpandedSettingsOpen = !this.isExpandedSettingsOpen;
  }

  public onCancelBtnClicked(): void {
    this.dialogRef.close();
  }

  public onSaveBtnClicked(): void {
    if (this.dataEndChannel.dataEndChannelType === DataEndChannelTypeEnum.RADAR || this.dataEndChannel.dataEndChannelType === DataEndChannelTypeEnum.LED ||
          this.dataEndChannel.dataEndChannelType === DataEndChannelTypeEnum.PW) {
      if (!this.deviceType) {
        this.alertService.alert('Kein Gerät ausgewählt', AlertTypes.warning);
        return;
      }
    }
    this.dataEndChannel.routeStationId = this.data.id;
    this.dataEndChannelService.createNewDataEndChannel(this.dataEndChannel).subscribe(endChannel => {
      this.alertService.alert('neuen Datenendkanal hinzugefügt', AlertTypes.success);
      if (this.dataEndChannel.dataEndChannelType === DataEndChannelTypeEnum.RADAR && this.deviceType?.id !== undefined) {
        this.dataEndChannelService.setRadarType(endChannel.id, this.deviceType.id).subscribe();
      } else if ((this.dataEndChannel.dataEndChannelType === DataEndChannelTypeEnum.PW ||
          this.dataEndChannel.dataEndChannelType === DataEndChannelTypeEnum.LED) && this.deviceType?.id !== undefined) {
        this.dataEndChannelService.setDisplayType(endChannel.id, this.deviceType.id).subscribe();
      }
      this.dialogRef.close(true);
    });
  }

  private getDeviceTypesForSelect(): void {
    this.displayService.getDisplayTypes().subscribe(displays => {
      for (let display of displays) {
        let select = {
          name: display.name,
          id: display.id
        } as SelectInput;
        this.displaySelect.push(select);
      }
    });
    this.radarService.getRadarTypes().subscribe(radare => {
      for (let radar of radare) {
        let select = {
          name: radar.name,
          id: radar.id
        } as SelectInput;
        this.radarSelect.push(select);
      }
    });
  }
}
