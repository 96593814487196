import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardOverviewComponent } from './dashboard/dashboard-overview/dashboard-overview.component';
import { ProjectOverviewComponent } from './project/project-overview/project-overview.component';
import { UserDataSettingsComponent } from './user/user-data-settings/user-data-settings.component';
import { LoginOverviewComponent } from './user/login-overview/login-overview.component';
import { LoginGuard } from './user/shared/login.guard';

import { ProjectGooglemapsComponent } from './project/project-googlemaps/project-googlemaps.component';
import { ProjectSettingsOverviewComponent } from './project/project-settings/project-settings-overview.component';
import { ProjectSchemaComponent } from './project/project-schema/project-schema.component';
import { ProjectTestAreaComponent } from './project/project-test-area/project-test-area.component';
import { ProjectStationOverviewComponent } from './project/project-station-overview/project-station-overview.component';
import { ProjectDocumentsComponent } from './project/project-documents/project-documents.component';
import { ProjectNotesComponent } from './project/project-notes/project-notes.component';
import { LayoutComponent } from './core/layout/layout.component';
import { UserResolver } from './user/shared/user.resolver';
import { DeviceOverviewComponent } from "./device/device-overview/device-overview.component";
import { DeviceTestAreaComponent } from "./device/device-test-area/device-test-area.component";
import { DeviceSettingsComponent } from "./device/device-settings/device-settings.component";
import { ProjectTimeSwitchComponent } from './project/project-time-switch/project-time-switch.component';
import { CompanyOverviewComponent } from "./company/company/company-overview/company-overview.component";
import { CompanySettingsComponent } from "./company/company/company-settings/company-settings.component";
import { UserOverviewComponent } from "./user/user-overview/user-overview.component";
import { UserSettingsComponent } from "./user/user-settings/user-settings.component";
import { UserComponent } from "./user/user/user.component";
import { CompanyComponent } from "./company/company/company.component";
import { ProjectImagesComponent } from "./project/project-images/project-images.component";
import { RoleGuard } from "./user/shared/role.guard";
import { ProjectSwaConfigComponent } from './project/project-swa-config/project-swa-config.component';
import { DeviceRouteStationComponent } from "./device/device-route-station/device-route-station.component";
import { FaqComponent } from "./core/faq/faq.component";
import { FaqAccountComponent } from './core/faq/faq-account/faq-account.component';
import { FaqOverviewComponent } from './core/faq/faq-overview/faq-overview.component';
import { FaqDashboardComponent } from './core/faq/faq-dashboard/faq-dashboard.component';
import { FaqProjectComponent } from './core/faq/faq-project/faq-project.component';
import { FaqReportComponent } from './core/faq/faq-report/faq-report.component';
import { FaqBilderComponent } from './core/faq/faq-admin-overview/faq-bilder/faq-bilder.component';
import { FaqSwaComponent } from './core/faq/faq-admin-overview/faq-swa/faq-swa.component';
import { FaqTimerSwitchComponent } from './core/faq/faq-admin-overview/faq-timer-switch/faq-timer-switch.component';
import { ImpressumComponent } from './core/impressum/impressum.component';
import { ProjectScenarioComponent } from './project/project-scenario/project-scenario.component';
import { ReportOverviewNewComponent } from './report/report-overview-new/report-overview-new.component';
import { DeviceNotesComponent } from './device/device-notes/device-notes.component';
import { ProjectLisamessageComponent } from './project/project-lisamessage/project-lisamessage.component';
import { BatterieDiagrammComponent } from './report/shared/components/batterie-diagramm/batterie-diagramm.component';
import { DetailedReportingsComponent } from './report/detailed-reportings/detailed-reportings.component';
import { ProjectMapComponent } from './project/project-map/project-map.component';

const routes: Routes = [
  {
    path: '', component: LayoutComponent, canActivate: [LoginGuard], resolve: { userData: UserResolver },
    children: [
      { path: '', redirectTo: 'project', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardOverviewComponent, canActivate: [LoginGuard, RoleGuard] },
      {
        path: 'project', component: ProjectOverviewComponent, canActivate: [LoginGuard],
        children: [
          { path: '', redirectTo: 'map', pathMatch: 'full' },
         
          { path: 'map', component: ProjectGooglemapsComponent, canActivate: [RoleGuard] },
          { path: 'mapOld', component: ProjectMapComponent, canActivate: [RoleGuard] },
          { path: 'settings', component: ProjectSettingsOverviewComponent, canActivate: [RoleGuard] },
          { path: 'schema', component: ProjectSchemaComponent, canActivate: [RoleGuard] },
          { path: 'test-area', component: ProjectTestAreaComponent, canActivate: [RoleGuard] },
          { path: 'station-overview', component: ProjectStationOverviewComponent, canActivate: [RoleGuard] },
          { path: 'images', component: ProjectImagesComponent, canActivate: [RoleGuard] },
          { path: 'documents', component: ProjectDocumentsComponent, canActivate: [RoleGuard] },
          { path: 'notes', component: ProjectNotesComponent, canActivate: [RoleGuard] },
          { path: 'time-switch', component: ProjectTimeSwitchComponent, canActivate: [RoleGuard] },
          { path: 'swa-config', component: ProjectSwaConfigComponent, canActivate: [RoleGuard] },
          { path: 'scenario', component: ProjectScenarioComponent, canActivate: [RoleGuard] },
          { path: 'lisa', component: ProjectLisamessageComponent, canActivate: [RoleGuard] },
        ]
      },
      {
        path: 'device', component: DeviceOverviewComponent, canActivate: [LoginGuard],
        children: [
          { path: '', redirectTo: 'settings', pathMatch: 'full' },
          { path: 'settings', component: DeviceSettingsComponent, canActivate: [RoleGuard] },
          { path: 'test-area', component: DeviceTestAreaComponent, canActivate: [RoleGuard] },
          { path: 'route-station', component: DeviceRouteStationComponent, canActivate: [RoleGuard] },
          { path: 'notes', component: DeviceNotesComponent, canActivate: [RoleGuard] }
        ]
      },
      {
        path: 'report', component: ReportOverviewNewComponent, canActivate: [LoginGuard],  /* Wrapper bauen, umlagern */
        children: [
          { path: '', redirectTo: 'detailed', pathMatch: 'full' },
          { path: 'detailed', component: DetailedReportingsComponent, canActivate: [RoleGuard]},
          { path: 'diagram', component: BatterieDiagrammComponent, canActivate: [RoleGuard] },]
      },
      {
        path: 'user', component: UserComponent, canActivate: [LoginGuard],
        children: [
          { path: '', redirectTo: 'user-overview', pathMatch: 'full' },
          { path: 'user-data-settings', component: UserDataSettingsComponent, canActivate: [RoleGuard] },
          { path: 'user-overview', component: UserOverviewComponent, canActivate: [RoleGuard] },
          { path: 'edit/:id', component: UserSettingsComponent },
          { path: 'create', component: UserSettingsComponent, canActivate: [RoleGuard] },
        ]
      },
      {
        path: 'company', component: CompanyComponent, canActivate: [LoginGuard, RoleGuard],
        children: [
          { path: '', redirectTo: 'company-overview', pathMatch: 'full' },
          { path: 'company-overview', component: CompanyOverviewComponent },
          { path: 'edit/:id', component: CompanySettingsComponent },
          { path: 'create', component: CompanySettingsComponent },
        ]
      },
      {
        path: 'faq', component: FaqOverviewComponent, canActivate: [RoleGuard],
        children: [
          { path: '', redirectTo: 'faq', pathMatch: 'full' },
          { path: 'faq', component: FaqComponent },
          { path: 'account', component: FaqAccountComponent },
          { path: 'overview', component: FaqDashboardComponent },
          { path: 'project', component: FaqProjectComponent },
          { path: 'report', component: FaqReportComponent },
          { path: 'bilder', component: FaqBilderComponent, canActivate: [RoleGuard] },
          { path: 'swa', component: FaqSwaComponent, canActivate: [RoleGuard] },
          { path: 'timer-switch', component: FaqTimerSwitchComponent, canActivate: [RoleGuard] },
        ]
      }
    ]
  },
  { path: 'login', component: LoginOverviewComponent },
  { path: 'impressum', component: ImpressumComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
